import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';

function ActionAccess({ item, children }: { item: any; children: any }) {
  const { user }: any = useSelector((state: RootState) => state.userState);
  const isPartner = user?.role === 'fleetmax_partner';
  const canPerformAction = item?.vendor?.account_id !== item?.partner?.account_id; // vendor and partner is different

  // if (!isPartner || !canPerformAction)
  return null;

  // return <>{children}</>;
}

export default ActionAccess;

export const useActionAccess = ({ item, children }: { item: any; children: any }) => {
  const { user }: any = useSelector((state: RootState) => state.userState);

  const { partnerAsVendor }: any = useSelector((state: RootState) => state.mainState);

  const currentUser: string = user?.role;

  const partnerLoggedIn: boolean = currentUser === 'fleetmax_partner';
  const vendorLoggedIn: boolean = currentUser === 'fleetmax_vendor';
  const partnerAsVendorLoggedIn: boolean = currentUser === 'fleetmax_partner' && partnerAsVendor;
  const vendorAccountId: boolean = partnerAsVendor.vendor_acccount_id;

  return {
    partnerLoggedIn,
    vendorLoggedIn,
    partnerAsVendorLoggedIn,
    vendorAccountId,
    ActionAccess,
  };
};
