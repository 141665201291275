import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const useCustomParams = (
  filterProcessStatus: any,
  shorts?: any,
  pageType?: string,
  onlyShortCode: boolean = false
) => {
  const [selectedSort, setSelectedSort] = useState('All');
  const [selectedStatusSort, setSelectedStatusSort] = useState('All');
  const [params, setSearchParams] = useSearchParams();

  const navigateRequest = (type: string) => {
    params.set('request', type);
    params.set('offset', '1');
    setSearchParams(params);
  };

  const navigateShortCode = (type: string) => {
    params.set('shortcode', type);
    params.set('offset', '1');
    setSearchParams(params);
  };
  const navigateStationCode = (type: string) => {
    params.set('companies', type);
    params.set('offset', '1');
    setSearchParams(params);
  };

  const handleFilterProcess = (requestType: string, shortCode: string) => {
    if (onlyShortCode) {
      filterProcessStatus();
    } else {
      filterProcessStatus(requestType);
    }
  };

  useEffect(() => {
    let requestType: string = 'All';
    let shortCode: string = 'All';
    const tempRequestType = params.get('request');
    const tempShortCode = params.get('shortcode');
    if (tempRequestType) {
      requestType = tempRequestType;
      setSelectedStatusSort(tempRequestType);
    }
    if (tempShortCode) {
      shortCode = tempShortCode;
      setSelectedSort(tempShortCode);
    }

    const paramsWithSize = params as unknown as URLSearchParams & { size: number };
    if (paramsWithSize.size === 1) {
      if (selectedSort !== 'All') {
        setSelectedSort('All');
        handleFilterProcess('All', 'All');
      }
      if (selectedStatusSort !== 'All') {
        setSelectedStatusSort('All');
        handleFilterProcess('All', 'All');
      }
    } else {
      handleFilterProcess(requestType, 'All');
    }
  }, [params]);

  return {
    selectedSort,
    selectedStatusSort,
    navigateRequest,
    navigateShortCode,
    navigateStationCode,
  };
};

export default useCustomParams;
