import { Button, Tooltip } from '@mui/material';
import VendorAPI from '../../../../api/vendorApi';
import AcceptSvg from '../../../../assets/AccpetVendor';
import DeclineSvg from '../../../../assets/DeclinedVendor';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import { toast } from '../../../../components/Toast/CustomToast';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import PartnerApi from '../../../../api/partnerSpecificApi';
import useFetchSingleRequest from '../../../hooks/csr/useFetchSingleRequest';
import { useNavigate } from 'react-router';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import moment from 'moment';
export const getDateAndTime = (item: any) => {
  return {
    date: moment(`${item?.date} ${item?.time}`, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY'),
    time: moment(`${item?.date} ${item?.time}`, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A'),
  };
};
const VehicleCheckList = async (data: any) => {
  const response = await VendorAPI.getVehicleCheckList({
    preventive_request_id: data?.id || data?.preventive_request_id || '',
  });
  if (response.status === 200) {
    return {
      checklist_questions: {
        question_answers: response?.data?.data?.[0]?.checklist_questions?.question_answers,
      },
    };
  }
};
export const makeBulkScheduleData = async (dataArr: any) => {
  const isPartnerOverviewPage = window.location.href.includes('/partner/dashboard');

  const processedData = await Promise.all(
    dataArr.map(async (item: any) => {
      const isDSPSchedule = item?.status_id === '1' && item?.request_type === 'manual_dsp';
      const vehicleChecklist = !item?.checklist_questions ? await VehicleCheckList(item) : [];
      return {
        ...item,
        ...vehicleChecklist,
        dsp: item.dsp,
        services: item.checklists?.map((d: any) => d.name),
        timeSlots: isDSPSchedule
          ? [getDateAndTime(item?.selectedSlot)]
          : item.appointments?.map((appt: any) => getDateAndTime(appt)),
        summarys:
          item?.questions?.map((question: any) => Number(question.custom_question_id) || []) || [],
        preventive_request_id: item.preventive_request_id,
        preventive_maintenance_id: item.preventive_maintenance_id?.toString(),
        vehicle: item.vehicle,
        hasAdded: true,
        scheduleTo: 'Schedule to Vendor',
        ...(isDSPSchedule && { isDSPSchedule: true }),
        ...(isPartnerOverviewPage && { isClickedFromDashboard: true }),
      };
    })
  );
  return processedData;
};

function AcceptReject({ item, isModel }: { item: any; isModel?: boolean }) {
  const loader = HandleLoad();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBulkVehicles } = bindActionCreators(actionCreators, dispatch);
  const { getFullRequestInfo } = useFetchSingleRequest();
  const { user } = useSelector((state: RootState) => state.userState);
  const initialReqStage =
    user?.role === 'fleetmax_vendor' && +item?.status_id === 4 && +item?.sub_stage === 1; // pending for venor action
  const initialReqStagePartner =
    user?.role === 'fleetmax_partner' && +item?.status_id === -1 && +item?.sub_stage === 1; // pending for venor action
  const accepteedReqStagePartner =
    user?.role === 'fleetmax_partner' && +item?.status_id === -1 && +item?.sub_stage === 2; // pending for venor action

  const { setRejecting, handlePopClick } = item;
  const acceptRequest = () => {
    loader(true);
    VendorAPI.acceptCsrRequest({
      preventive_request_id: item?.id || item?.preventive_request_id,
    }).then((res) => {
      if (res.status === 200) {
        item.fetchRequestData();
        toast.success(`Request accepted successfully`);
        item?.handleClose?.();
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };

  const acceptRequestBulk = () => {
    loader(true);
    VendorAPI.acceptCsrRequestBulk({
      bulk_requests: item.bulkSchedules.map((d: any) => ({
        preventive_request_id: d.preventive_request_id,
      })),
    }).then((res) => {
      if (res.status === 200) {
        item.fetchRequestData();
        toast.success(`Request accepted successfully`);
        item?.handleClose?.();
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };

  const acceptRequestPartner = () => {
    loader(true);
    const pm_ids = item?.bulkSchedules
      ? item.bulkSchedules?.map((item: any) => ({
          preventive_request_id: item.id || item?.preventive_request_id,
        }))
      : [{ preventive_request_id: item.id || item?.preventive_request_id }];
    PartnerApi.acceptPartnerRequest({
      bulk_requests: pm_ids,
      // ...(item?.bulk_id && {bulk_id:item?.bulk_id})
    }).then((res) => {
      if (res.status === 200) {
        toast.success(`Request accepted successfully`);
        item.fetchRequestData();
        item?.handleClose?.();
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };

  const handleAccept = () => {
    if (user?.role === 'fleetmax_partner' && initialReqStagePartner) {
      acceptRequestPartner();
    } else {
      if (item?.bulk_id && item?.bulkSchedules) {
        acceptRequestBulk();
      } else {
        acceptRequest();
      }
    }
  };

  const handleAssignVednor = async () => {
    const query = item?.bulk_id
      ? `bulk_id=${item?.bulk_id}`
      : `preventive_request_id=${item?.preventive_request_id}`;
    const data = await getFullRequestInfo(query);
    const structuredData = await makeBulkScheduleData(data);
    if (structuredData?.length > 0) {
      setBulkVehicles({
        bulkVehicles: structuredData,
      });
      navigate('/scheduleRequest');
    }
  };

  if (accepteedReqStagePartner && !isModel) {
    return (
      <div className='text-blue-400 cursor-pointer group' onClick={() => handleAssignVednor()}>
        <i className='pi pi-plus border-dashed border-2 p-1 rounded-full group-hover:border-[#4378C8] transition duration-300 ease-out'></i>
        <p>Assign Vendor</p>
      </div>
    );
  }

  return initialReqStage || initialReqStagePartner ? (
    <div className={`flex ${isModel ? 'justify-around' : 'justify-center'} gap-5  w-full`}>
      {isModel ? (
        <>
          {/* asdasd */}
          <Button
            color='success'
            variant='outlined'
            className={`w-[7rem] px-2 h-fit hover:bg-green-200`}
            style={{
              borderRadius: '0.625rem',
              border: '0.09375rem solid rgba(120, 171, 103, 0.43)',
              background: '#FFF',
            }}
            onClick={handleAccept}
            startIcon={<AcceptSvg />}>
            <p className='capitalize font-bold'>ACCEPT</p>
          </Button>
          <Button
            color='error'
            variant='outlined'
            className={`w-[7rem] px-2 h-fit hover:bg-red-200`}
            style={{
              borderRadius: '0.625rem',
              border: '0.09375rem solid rgba(120, 171, 103, 0.43)',
              background: '#FFF',
            }}
            onClick={(e: any) => {
              setRejecting?.(true);
              handlePopClick?.(e);
            }}
            startIcon={<DeclineSvg />}>
            <p className='capitalize font-bold'>DECLINE</p>
          </Button>
        </>
      ) : (
        <>
          <Tooltip title='Accept'>
            <div
              className='bg-[#E7FFDF] rounded-full w-[3rem] h-[3rem] flex justify-center items-center cursor-pointer'
              onClick={handleAccept}>
              <i className='pi pi-check text-[1.1rem] text-[#5CBD3C]'></i>
            </div>
          </Tooltip>
          <Tooltip title='Decline'>
            <div
              className='bg-[#FFECEC] rounded-full w-[3rem] h-[3rem] flex justify-center items-center cursor-pointer'
              onClick={(e: any) => {
                setRejecting?.(true);
                handlePopClick?.(e);
              }}>
              <i className='pi pi-times text-[1.1rem] text-[#CC5555]'></i>
            </div>
          </Tooltip>
        </>
      )}
    </div>
  ) : null;
}

export default AcceptReject;
