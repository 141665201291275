import TitleTooltip from '../../../../../components/BarData/TitleTooltip';

export const formatStatus = (status: string) => {
  return status
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const renderStatus = (status: string) => {
  const statusMap: { [key: string]: string } = {
    schedule_by_dsp: 'Scheduled by DSP',
    vendor_approved: 'Vendor Approved',
    assigned_to_technician: 'Assigned To Technician',
    pending_from_dsp: 'Pending from DSP',
    rejected_by_you: 'Rejected by You',
    pending_from_vendor: 'Pending From Vendor',
    unknown: 'N/A',
    approved: 'Approved',
    invoice_sent: 'Invoice Sent',
    pending_from_partner: 'Pending from Partner',
    rejected_by_dsp: 'Rejected by DSP',
    payment_completed: 'Request Completed',
  };
  return statusMap[status] || formatStatus(status);
};

function InvoiceStatus({
  item,
  subStatus,
  totalDataLength,
}: {
  item: any;
  subStatus: any;
  totalDataLength: number;
}) {
  const sub_status = subStatus ? (
    <TitleTooltip
      title={`${'('}${renderStatus(String(subStatus))}${')'}`}
      palcement={'bottom'}
      width={totalDataLength < 5 ? '12rem' : '6rem'}
      style={'text-ellipsis overflow-hidden capitalize text-center text-gray-500 text-[0.8rem]'}
    />
  ) : null;

  const statusClassMap: { [key: string]: string } = {
    assigned: 'text-assignedColor',
    rejected: 'text-disputedColor',
    accepted: 'text-acceptedColor',
    requests: 'text-primary',
    dispute: 'text-disputedColor',
    scheduled: 'text-scheduled',
    in_progress: 'text-inprogress',
    disputed: 'text-disputedColor',
    payment_completed: 'text-paymentCompleted',
    payment_confirmed: 'text-paymentSuccesfulColor',
    rejected_by_csr: 'text-disputedColor',
    pending_vendor: 'text-primary',
    accepted_vendor: 'text-approvedColor',
    assigned_vendor: 'text-approvedColor',
    approved_vendor: 'text-approvedColor',
    rejected_by_vendor: 'text-disputedColor',
    payment_confirmed_vendor: 'text-paymentSuccesfulColor',
    rejected_partner: 'text-disputedColor',
    accepted_partner: 'text-approvedColor',
    assigned_partner: 'text-approvedColor',
    approved_partner: 'text-approvedColor',
    pending_partner: 'text-primary',
    payment_confirmed_partner: 'text-paymentSuccesfulColor',
    received: 'text-receivedColor',
    approved: 'text-approvedColor',
    payment_received: 'text-paymentSuccesfulColor',
    disputed_general: 'text-disputedColor',
    pending: 'text-primary',
    invoice_sent: 'text-invoiceSentColor',
    unknown: 'text-defaultColor',
  };

  const currentStatus = item?.status || item?.request_status;
  const isStatusPresent =
    currentStatus && statusClassMap.hasOwnProperty(currentStatus) && currentStatus !== 'unknown';

  return (
    <td className='px-[0.2rem] py-4'>
      <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem] h-[1.4rem]'>
        <div>
          {isStatusPresent ? (
            <div
              className={`${statusClassMap[currentStatus]} w-[${
                totalDataLength < 5 ? '12rem' : '6rem'
              }] text-ellipsis flex flex-col whitespace-nowrap capitalize`}>
              <p>
                <TitleTooltip
                  title={`${renderStatus(currentStatus)}`}
                  palcement={'bottom'}
                  width={totalDataLength < 5 ? '12rem' : '6rem'}
                  style={'text-ellipsis overflow-hidden capitalize text-center'}
                />
              </p>{' '}
              {sub_status}
            </div>
          ) : (
            <div className='w-[6rem] text-ellipsis flex flex-col whitespace-nowrap capitalize w-full flex justify-center items-center'>
              N/A
            </div>
          )}
        </div>
      </div>
    </td>
  );
}

export default InvoiceStatus;
