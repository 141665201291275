import { Button, Checkbox } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import { RootState } from '../../../../state/reducers';
import { summaryItem, useFetchSummary } from '../../../hooks/useFetchSummary';

function AddService({
  selectedVendor,
  data,
  handleClose,
  addServiceForVendor,
}: {
  selectedVendor: any;
  data: any;
  handleClose: any;
  addServiceForVendor?: boolean;
}) {
  const dispatch = useDispatch();
  const [checkedItems, setCheckedItems] = useState([] as string[]);
  const [checkedData, setCheckedData] = useState<any[]>([]);
  const { bulkVehicles } = useSelector((state: RootState) => state.mainState);
  const { bulkAddService } = useSelector((state: RootState) => state.mainState);
  const { setBulkVehicles, setBulkAddService } = bindActionCreators(actionCreators, dispatch);
  const { summary, getSummary } = useFetchSummary();

  useEffect(() => {
    if (data) {
      getSummary({
        vehicle_id: data?.info?.vehicle?.id?.toString(),
      });
    }
  }, [data]);

  useEffect(() => {
    if (data?.info) {
      if (data.info?.summarys) {
        setCheckedItems(data.info?.summarys);
      } else {
        setCheckedItems([]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (summary) {
      setCheckedData(summary);
    } else {
      setCheckedData([]);
    }
  }, [summary]);

  const serviceInfo: string = useMemo(() => {
    let info: string = '';
    if (data.info?.summarys) {
      if (data.info?.summarys.length > 0) {
        info = `${data.info?.summarys.length} Added`;
      }
    }
    return info;
  }, [data]);
  return (
    <div
      className='flex gap-2 flex-col p-4 pr-2 rounded-l-[2rem] h-screen relative'
      style={{
        boxShadow: '1px 0px 39px rgba(0, 0, 0, 0.11)',
      }}>
      <div className='flex flex-col text-center mt-4 mb-2'>
        <p className='font-bold text-lg'>{data.info.vehicle.name}</p>
        <p className='text-primary'>Select summary you want to be select</p>
        <div className='flex justify-between'>
          <p className='text-primary text-end pr-4 mt-2'>{serviceInfo}</p>
        </div>
      </div>
      {checkedData.length > 0 && (
        <div className='h-0 overflow-y-auto flex-1'>
          <div className='pr-4 grid grid-cols-1 gap-y-5 gap-x-60'>
            {checkedData.length > 0 &&
              checkedData.map((elem: any) => {
                const found = checkedItems.includes(elem?.id);
                return (
                  <div
                    className={`px-2 pt-1 border border-solid border-slate-200 flex items-center rounded-lg cursor-pointer gap-2`}
                    onClick={() => {
                      if (found) {
                        const checkedData = [...checkedItems];
                        checkedData.splice(checkedData.indexOf(elem?.id), 1);
                        setCheckedItems(checkedData);
                      } else {
                        setCheckedItems([...checkedItems, elem?.id]);
                      }
                    }}
                    key={elem?.id}>
                    <div className='flex gap-2 items-center justify-between w-full'>
                      <div className='flex gap-2 items-center'>
                        <Checkbox disableRipple checked={found} />
                        <p className='select-none capitalize'>{elem?.question}</p>
                      </div>
                      {getAnswer(elem)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {checkedData.length === 0 && (
        <div className='overflow-y-auto h-[34rem] flex'>
          <p className='m-auto'>No summary available</p>
        </div>
      )}
      <Button
        variant='contained'
        className='px-[7rem] w-fit mx-auto my-4 mt-auto font-bold'
        disabled={checkedItems.length === 0}
        onClick={() => {
          if (addServiceForVendor) {
            setBulkAddService(
              bulkAddService.map((obj: any) => {
                if (obj.id === data.info.id) {
                  const tempObj = {
                    ...obj,
                    summarys: Array.from(new Set([...checkedItems])),
                  };
                  return tempObj;
                }
                return obj;
              })
            );
          } else {
            setBulkVehicles({
              bulkVehicles: bulkVehicles.map((obj: any) => {
                if (obj.vehicle.id === data.info.vehicle.id) {
                  const tempObj = {
                    ...obj,
                  };
                  tempObj['summarys'] = Array.from(new Set([...checkedItems]));
                  return tempObj;
                }
                return obj;
              }),
            });
          }
          handleClose();
        }}>
        Save
      </Button>
    </div>
  );
}

export const getAnswer = (elem: summaryItem, isModel?: boolean) => {
  if (typeof elem.answer === 'string') {
    return <p className={`select-none ${!isModel && 'text-slate-300'} `}>{elem?.answer}</p>;
  }

  if (typeof elem.answer === 'boolean') {
    return (
      <p className={`select-none ${!isModel && 'text-slate-300'} `}>
        {elem?.answer ? 'Yes' : 'No'}
      </p>
    );
  }
  return <p>{elem?.answer}</p>;
};

export default AddService;
