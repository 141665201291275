import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useFetchSingleRequest from '../../hooks/vendor/useFetchSingleRequest';
import { Button, TextField, Tooltip } from '@mui/material';
import { toast } from '../../../components/Toast/CustomToast';
import HandleLoad from '../../../components/Loader/HandleLoad';
import PreventiveAPI from '../../../api/preventiveApi';
import { UpdatedAtTime } from '../../csr/primaryComponents/AppointmentTime';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/reducers';
import AssignTechModel from '../../csr/components/schedule/components/AssignTechModel';
import AssignVendorModal from '../../csr/components/schedule/AssignVendorModal';
import ImageModal from '../../csr/primaryComponents/SubmitProofModel';
import Invoice from '../../vender/PrimaryComponents/invoice/Invoice';
import BulkInfoModel from '../../csr/bulkInfoModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Request from '../../csr/primaryComponents/RequestModal';
import VendorAPI from '../../../api/vendorApi';
import { getDateAndTime } from '../../vender/PrimaryComponents/requestComponents/AcceptRejectBtn';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import LeasePlan from '../../csr/primaryComponents/LeasePlan';
import AssignPartnerModel from '../../csr/components/schedule/components/AssignPartnerModel';
import CancelRequestModel, { ModelContainerCancel } from '../requestDetail/CancelRequestModel';
import { vehicleInfo } from '../VehicleInfo';
import Logs from '../requestDetail/MaintainanceLogsModel';
import InvoiceModal from '../../csr/primaryComponents/Invoice';
import InvoicingExpandPopUp from '../requestDetail/InvoicingExpandPopUp';
import ViewInvoice from '../ViewInvoice';
import InvoiceSvg from '../../../assets/InvoiceSvg';
import CsrAPI from '../../../api/csrApi';
import MoveToCompleteIcon from '../../../assets/MoveToCompleteIcon';
import ServiceLocation from '../../csr/primaryComponents/ServiceLocation';
import ProceedWithoutProofs from '../../csr/primaryComponents/ProceedWithoutProofs';

const OpenCSRModel = ({ item, handleClose, refetch }: any) => {
  const [bulkInfoModal, setBulkInfoModal] = useState({ open: false, data: {} } as any);
  useEffect(() => {
    if (item?.bulk_id) {
      setBulkInfoModal({
        open: true,
        data: item,
      });
    }
  }, [item?.bulk_id]);
  if (!item?.bulk_id) {
    return <SingleModel item={item} handleClose={handleClose} refetch={refetch} />;
  } else
    return (
      <>
        {bulkInfoModal.open && (
          <BulkInfoModel
            handleClose={() => {
              setBulkInfoModal({ open: false, data: {} });
              handleClose?.();
            }}
            data={bulkInfoModal?.data}
            refetch={() => {
              refetch?.();
            }}
          />
        )}
      </>
    );
};
const SingleModel = ({ item, handleClose, refetch }: any) => {
  const handleLoad = HandleLoad();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const { fetchRequestData: fetchRequestDetails }: any = useFetchSingleRequest();

  const { user }: any = useSelector((state: RootState) => state.userState);
  const { setBulkVehicles, setCompleteData } = bindActionCreators(actionCreators, dispatch);
  const [requestModel, setRequestModal] = useState<any>({ open: false, data: {} });
  const [partnerModel, setPartnerModel] = useState({ open: false, data: {} });
  const [vendorModal, setVendorModal] = useState({ open: false, data: {} });
  const [leasePlanModal, setLeasePlanModal] = useState({ open: false, data: {} });
  const [showCancelModel, setShowCancelModel] = useState({ open: false, data: {} });
  const [showModel, setShowModel] = useState({ show: false, data: '' });
  const [technicianModel, setTechnicianModel] = useState({ open: false, data: {} });
  const [invoiceModalEdit, setInvoiceModalEdit] = useState({ open: false, data: {} });
  const [invoiceModal, setInvoiceModal] = useState({
    open: false,
    data: {},
  } as any);
  const isScheduledTab = (statusId: number, subStage: number) =>
    ([-1, 4].includes(statusId) && [1, 2].includes(subStage)) || [1, 2].includes(statusId);

  const isRejected = (statusId: number, subStage: number) =>
    [10, -1, 4, 3].includes(statusId) && subStage === 3;

  const isInProgress = (statusId: number, subStage: number, vendorInvoiceDispute: string) =>
    [5, 6, 7, 8, 9].includes(statusId) ||
    (statusId === 11 && !['raised', 'declined'].includes(vendorInvoiceDispute)) ||
    (statusId === 4 && subStage === 2);

  const isPaymentConfirmed = (statusId: number, subStage: number) =>
    statusId === 12 && subStage === 1;

  const isCompleted = (statusId: number, subStage: number) => statusId === 12 && subStage == null;

  const isDisputed = (statusId: number, vendorInvoiceDispute: string) =>
    statusId === 11 && ['raised', 'declined'].includes(vendorInvoiceDispute);

  const requestType = () => {
    const statusId = +requestModel?.data?.status_id;
    const subStage = +requestModel?.data?.sub_stage;
    const vendorInvoiceDispute = requestModel?.data?.vendor_invoice_dispute;

    if (isScheduledTab(statusId, subStage)) {
      return 'schedule';
    } else if (isRejected(statusId, subStage)) {
      return 'rejected';
    } else if (isDisputed(statusId, vendorInvoiceDispute)) {
      return 'dispute';
    } else if (isInProgress(statusId, subStage, vendorInvoiceDispute)) {
      return 'in-process';
    } else if (isPaymentConfirmed(statusId, subStage)) {
      return 'paymentConfirmed';
    } else if (isCompleted(statusId, subStage)) {
      return 'complete';
    } else {
      return '';
    }
  };

  const type: string = requestType();
  const refetchRequestData = () => {
    if (requestModel.data?.id || requestModel.data?.preventive_request_id) {
      fetchRequestDetails({
        id: requestModel.data?.id || requestModel.data?.preventive_request_id,
      }).then((res: any) => {
        if (res) {
          const data = {
            ...res,
            ...(!res?.preventive_request_id && {
              preventive_request_id: res?.id,
            }),
            ...(!res?.id && {
              id: res?.preventive_request_id,
            }),
          };
          setRequestModal({
            open: true,
            data: data,
          });
          if (invoiceModalEdit.open) {
            setInvoiceModalEdit({ open: true, data: data });
          }
          refetch?.();
        } else {
          setRequestModal({
            open: false,
            data: {},
          });
          refetch?.();
        }
      });
    }
  };
  useEffect(() => {
    if (!item?.bulk_id)
      fetchRequestDetails({
        id: item?.id || item?.preventive_request_id || item?.request_id,
      }).then((res: any) => {
        if (res) {
          setRequestModal({
            open: true,
            data: {
              ...res,
              ...(!res?.preventive_request_id && {
                preventive_request_id: res?.id,
              }),
              ...(!res?.id && {
                id: res?.preventive_request_id,
              }),
            },
          });
        } else {
          setRequestModal({
            open: false,
            data: {},
          });
        }
      });
  }, [item?.id]);
  //scheduled
  const getVehicleCheckList = async (data: any) => {
    const response = await VendorAPI.getVehicleCheckList({
      preventive_request_id: data?.id || data?.preventive_request_id || '',
    });
    if (response.status === 200) {
      return {
        checklist_questions: {
          question_answers: response?.data?.data?.[0]?.checklist_questions?.question_answers,
        },
      };
    }
  };
  const handleSchedule = async (item: any, reschedule?: boolean, slot?: any) => {
    await fetchRequestDetails(item).then(async (res: any) => {
      if (res) {
        const data = {
          ...res,
          id: res?.preventive_request_id || res?.id,
          preventive_request_id: res?.preventive_request_id || res?.id,
          title: `Request-${res?.preventive_request_id || res?.id}`,
        };
        const services = data?.checklists
          ?.filter((checklist: any) => checklist.name)
          ?.map((checklist: any) => checklist.name);
        const timeSlots = data?.appointments?.map((appointment: any) => {
          const timeArray = appointment?.time?.split(':');
          let hour = parseInt?.(timeArray?.[0]);
          let period = 'AM';
          if (hour >= 12) {
            period = 'PM';
            if (hour > 12) {
              hour -= 12;
            }
          }
          return getDateAndTime(appointment);
        });
        setBulkVehicles({
          bulkVehicles: [
            {
              ...(await getVehicleCheckList(data)),
              ...data,
              hasAdded: true,
              isSingleSchedule: true,
              services: services?.length > 0 ? services : [],
              customServices: services?.length > 0 ? services : [],
              timeSlots: timeSlots,
              ...(reschedule && { isReschedule: true, scheduleTo: 'Schedule to DSP' }),
              ...(!reschedule && { scheduleFromDSP: true, scheduleTo: 'Schedule to Vendor' }),
            },
          ],
        });
        navigate('/scheduleRequest');
      }
    });
  };
  const scheduleBody = useMemo(
    () => [
      (item: any) => (
        <div className='w-full flex justify-between bg-[#F3F3F3] p-2 rounded-2xl'>
          <div className='w-full flex justify-between'>
            <Button
              variant='text'
              className='py-1 px-6 hover:bg-stone-200 text-blue-600 border border-blue-600 rounded-lg font-bold'
              onClick={() => setLeasePlanModal({ open: true, data: item })}>
              {item?.lease_plan ? 'View Lease Plan' : 'Lease Plan Update'}
            </Button>
            <Logs item={item} type='single' />
          </div>
        </div>
      ),
    ],
    []
  );
  const requestDataScheduled = useMemo(
    () => ({
      ...requestModel.data,
      noModelRefetch: true,
      setPartnerModel,
      handleReschedule: handleSchedule,
      setRequestModel: setRequestModal,
      handleSchedule,
      cancelRequestbtn: (
        <CancelRequestModel
          item={requestModel.data}
          refetch={() => {
            setRequestModal({ open: false, data: {} });
            refetch();
          }}
        />
      ),
      refetch: refetchRequestData,
    }),
    [requestModel?.data]
  );
  //in progress
  const [rejectField, setRejectField] = useState<any>(false);
  const noteRef: any = useRef();
  const [imageModal, setImageModal] = useState({ open: false, data: {} });
  const getProofs = useCallback((checklistData: any[]) => {
    let tempProofs: any[] = [];

    checklistData?.forEach((item) => {
      if (item?.proofs) {
        tempProofs.push(...item.proofs.map((d: any) => ({ ...d, heading: item?.name })));
      }

      if (item?.titles?.length > 0) {
        item.titles.forEach((title: any) => {
          if (title.proofs?.length > 0) {
            tempProofs.push(...title.proofs.map((d: any) => ({ ...d, heading: title.title })));
          }
        });
      }
    });

    return tempProofs;
  }, []);
  const handleMoveState = (
    id: string,
    status: any | string,
    note?: string,
    isImageModel?: boolean
  ) => {
    handleLoad(true);
    let status_id, sub_stage;
    if (typeof status === 'string') {
      status_id = status;
    } else {
      status_id = status.status_id;
      sub_stage = status.sub_stage;
    }
    PreventiveAPI.statusUpdate({
      preventive_request_id: id,
      status_id,
      sub_stage,
      note: note?.trim(),
    })
      .then((res) => {
        if (res.status === 200) {
          if (!isImageModel) {
            setRequestModal({ open: false, data: {} });
          }
          setImageModal({ open: false, data: {} });
          refetchRequestData();
          setShowModel({ show: false, data: '' });
        } else {
          toast.error('someting went wrong');
        }
        handleLoad(false);
      })
      .catch((error) => console.log(error));
  };
  const checkIfReadyToMove = async (id_arr: string[]) => {
    if (!id_arr) return;
    handleLoad(true);
    const params: string = `request_ids=${JSON.stringify(id_arr)}`;
    const res = await CsrAPI.checkIfReadyToMove(params);
    handleLoad(false);
    if (res?.status === 200) {
      return true;
    }
    return false;
  };
  const inProgressBody = {
    body1: () => {
      return vehicleInfo;
    },
    fullbody2: () => {
      if (rejectField)
        return [
          (item: any) => (
            <div className='flex flex-col justify-between p-3  border-[0.3rem] border-primary-light rounded-xl divide-blue-300  rounded-lg gap-3'>
              <div className='flex justify-end'>
                <button
                  className='border-none py-1 px-6  text-grey rounded-lg'
                  onClick={() => setRejectField(false)}>
                  Cancel
                </button>
              </div>
              <TextField
                multiline
                inputRef={noteRef}
                sx={{
                  '& .MuiInputBase-root': {
                    padding: '0.7rem',
                  },
                  width: '100%',
                  '& >div': {
                    borderRadius: '0.8rem',
                  },
                }}
                rows={5}
                placeholder='Reason for Rejection'
              />
              <div className='w-full flex justify-center flex-col items-center gap-2'>
                <button
                  className='py-3 px-6 hover:bg-stone-200 text-blue-600 border border-blue-600 rounded-lg w-full'
                  onClick={() => {
                    const reason = noteRef?.current?.value?.trim() || '';
                    if (reason?.length === 0) {
                      toast.info('Please enter reason of rejection ');
                      return;
                    }
                    handleMoveState(requestModel.data.id, '10', reason);
                  }}>
                  <i className='pi pi-times-circle text-sm p-1'></i>
                  Reject
                </button>
              </div>
            </div>
          ),
        ];
    },
    body4: () => {
      if ([4, 5, 6, 7, 8].includes(+requestModel?.data?.status_id)) {
        return [
          (item: any) => (
            <div className='flex flex-col justify-between p-1 divide-y-2 divide-dashed divide-blue-300 bg-blue-100 rounded-lg'>
              {+item.status_id <= 7 && (
                <div>
                  <p className='text-sm text-stone-400 px-14 text-center py-1.5'>
                    You can add requested proofs once maintenance is done by
                    <b className='px-1'>vendor/technician</b>
                  </p>
                  <div className='flex justify-center py-2'></div>
                </div>
              )}
              {[7, 8].includes(+item.status_id) && (
                <div>
                  <p className='text-sm text-stone-400 px-14 text-center py-1 whitespace-nowrap'>
                    You can always add requested proofs from your side
                  </p>
                  <div className='flex justify-center'>
                    <button
                      className='text-blue-500 flex items-center border-none hover:bg-blue-50 py-2 px-4 rounded-lg flex items-center gap-2'
                      onClick={() => {
                        setImageModal({ open: true, data: item });
                      }}>
                      Add Proofs
                      <i className='pi pi-arrow-right'></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          ),
        ];
      }
    },
    body5: () => {
      switch (requestModel?.data?.status_id) {
        case '8':
          return [
            (item: any, checklistData: any[] = []) => {
              const AllProofs: any = getProofs(checklistData);
              return (
                !rejectField && (
                  <div className='w-full flex justify-between'>
                    <button
                      className='py-1 px-6 hover:bg-stone-200 text-blue-600 border border-blue-600 rounded-lg'
                      onClick={() => setRejectField(true)}>
                      <i className='pi pi-times-circle text-sm p-1'></i>
                      Reject
                    </button>
                    <button
                      className='border-none py-1 px-6 bg-blue-500 text-white rounded-lg'
                      onClick={() => {
                        if (AllProofs.length === 0) {
                          setShowModel({ show: true, data: '' });
                        } else {
                          handleMoveState(requestModel.data.id, {
                            status_id: 9,
                            sub_stage: null,
                          });
                        }
                      }}>
                      <i className='pi pi-check-circle text-sm p-1'></i>
                      Approve
                    </button>
                    {showModel?.show && (
                      <ProceedWithoutProofs
                        props={{
                          showModel,
                          setShowModel,
                          onClick: () => {
                            handleMoveState(
                              requestModel.data.id,
                              {
                                status_id: 9,
                                sub_stage: null,
                              },
                              showModel?.data,
                              true
                            );
                            setShowModel({ show: false, data: '' });
                          },
                        }}
                      />
                    )}
                  </div>
                )
              );
            },
          ];
        case '9':
          return [
            (item: any) => (
              <Tooltip title={!item?.lease_plan ? 'Lease Plan Data Missing' : ''} placement='top'>
                <span>
                  <InvoicingExpandPopUp
                    item={item}
                    handleClick={(onClicked: string) => {
                      setInvoiceModal({
                        open: true,
                        data: {
                          ...item,
                          onClicked,
                        },
                      });
                    }}
                  />
                </span>
              </Tooltip>
            ),
          ];
        case '11':
          return [
            (item: any) =>
              (!['sent', 'updated'].includes(item?.dsp_invoice_status) ||
                !['amount_submitted'].includes(item?.vendor_invoice_status)) && (
                <Tooltip title={!item?.lease_plan ? 'Lease Plan Data Missing' : ''} placement='top'>
                  <span>
                    <InvoicingExpandPopUp
                      item={item}
                      handleClick={(onClicked: string) => {
                        setInvoiceModal({
                          open: true,
                          data: {
                            ...item,
                            onClicked,
                          },
                        });
                      }}
                    />
                  </span>
                </Tooltip>
              ),
          ];
        // common contents
        default:
          return [];
      }
    },
    body6: () => {
      return [
        (item: any) => (
          <div className='w-full flex justify-between bg-[#F3F3F3] p-2 rounded-2xl'>
            <Button
              variant='text'
              className='py-1 px-6 hover:bg-stone-200 text-blue-600 border border-blue-600 rounded-lg text-[1rem] font-bold'
              onClick={() => setLeasePlanModal({ open: true, data: item })}>
              {item?.lease_plan ? 'View Lease Plan' : 'Lease Plan Update'}
            </Button>
            <div className='flex'>
              <ViewInvoice
                item={{ ...item, setInvoiceModal }}
                component={
                  <Button
                    variant='text'
                    startIcon={<InvoiceSvg />}
                    className='py-2 px-4  text-blue-600 border border-blue-600 rounded-xl font-bold bg-white mt-[0.1rem]'>
                    {'View Invoice'}
                  </Button>
                }
              />
              {[9, 11].includes(+item.status_id) && (
                <Button
                  className=' flex justify-between bg-white rounded-[0.8rem] py-2 px-3 mx-2 text-[1.05rem] font-bold text-primary flex justify-between border-transparent items-center'
                  onClick={async () => {
                    const is_ready = await checkIfReadyToMove([item.id]);
                    if (is_ready) {
                      await fetchRequestDetails(item).then((res: any) => {
                        if (res) {
                          const data = {
                            ...res,
                            id: res?.preventive_request_id || res?.id,
                            preventive_request_id: res?.preventive_request_id || res?.id,
                            title: `Request-${res?.preventive_request_id || res?.id}`,
                          };
                          setCompleteData({ selected: data });
                          navigate('/moveToComplete');
                        }
                      });
                    }
                  }}>
                  <MoveToCompleteIcon />
                  <p className='text-[0.9rem]'>Move to Complete</p>
                </Button>
              )}
              <div className={`${![9, 11].includes(+item.status_id) && 'ml-2'}`}>
                <Logs item={item} type='single' />
              </div>
            </div>
          </div>
        ),
      ];
    },
    status: () => {
      switch (requestModel?.data?.status_id) {
        case '4':
          return [
            (item: any) => (
              <div className={`px-3 py-2 bg-lime-200 rounded-lg flex justify-between items-center`}>
                <p>Assigned To Vendor</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '5':
          return [
            (item: any) => (
              <div
                className={`px-3 py-2 bg-amber-200 rounded-lg flex justify-between items-center`}>
                <p>Assigned To Technician</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '6':
          return [
            (item: any) => (
              <div className={`px-3 py-2 bg-sky-200 rounded-lg flex justify-between items-center`}>
                <p>Technician Started Working</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '7':
          return [
            (item: any) => (
              <div
                className={`px-3 py-2 bg-slate-200 rounded-lg flex justify-between items-center`}>
                <p>In Job Completed/ Upload Proof</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '8':
          return [
            (item: any) => (
              <div className={`px-3 py-2 bg-rose-200 rounded-lg flex justify-between items-center`}>
                <p>Approval Pending</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '9':
          return [
            (item: any) => (
              <div
                className={`px-3 py-2 bg-green-200 rounded-lg flex justify-between items-center`}>
                <p>Approved</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '10':
          return [
            (item: any) => (
              <div className={`px-3 py-2 bg-red-200 rounded-lg flex justify-between items-center`}>
                <p>Rejected</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '11':
          return [
            (item: any) =>
              ['3'].includes(item.sub_stage) && (
                <div
                  className={`px-3 py-2 bg-teal-200 rounded-lg flex justify-between items-center`}>
                  <p>Invoice Sent</p>
                  <p>
                    <UpdatedAtTime item={item} />
                  </p>
                </div>
              ),
          ];

        default:
          return [];
      }
    },
  };
  const requestData = useMemo(
    () => ({
      ...requestModel.data,
      noModelRefetch: true,
      refetch: refetchRequestData,
      setInvoiceModal: setInvoiceModal,
      setTechnicianModel,
      setShowCancelModel: (d: any) => setShowCancelModel(d),
    }),
    [requestModel?.data]
  );

  //dispute
  const disputeBody = useMemo(
    () => ({
      body1: vehicleInfo,
      body2: [
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>CSR</p>
            <p>{user?.name}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>DSP</p>
            <p>{item?.dsp?.name}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>Vendor</p>
            <p className='capitalize'>{item?.vendor?.name || '---'}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>Notes</p>
            {/* <CsrNotes tab={'complete'} item={item} /> */}
          </div>
        ),
        (item: any) => ServiceLocation(item),
      ],
      body3: [
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>Assigned Vendor</aside>
            <p className='text-lg capitalize'>{item?.vendor?.name || '---'}</p>
          </div>
        ),
        (item: any) => <div></div>,
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>DSP Cost</aside>
            <p className='text-lg'>$0</p>
          </div>
        ),
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>Vendor Cost</aside>
            <p className='text-lg'>{`$ ${item?.final_payment}` || '---'}</p>
          </div>
        ),
      ],
      body5: [(item: any) => <></>],
      body6: [
        (item: any) => (
          <div className='w-full flex justify-between bg-[#F3F3F3] p-2 rounded-2xl'>
            <div className='flex justify-between'>
              <ViewInvoice
                item={{ ...item, setInvoiceModal }}
                component={
                  <Button
                    variant='text'
                    startIcon={<InvoiceSvg />}
                    className='py-1 px-4  text-blue-600 border border-blue-600 rounded-xl font-bold bg-white h-full'>
                    {'View Invoice'}
                  </Button>
                }
              />
            </div>
            <Logs item={item} type='single' />
          </div>
        ),
      ],
      status: [(item: any) => <></>],
    }),
    []
  );
  const updatedData = useMemo(() => {
    return {
      ...requestModel.data,
      noModelRefetch: true,
      setInvoiceModal: setInvoiceModalEdit,
    };
  }, [requestModel.data]);

  // complete
  const completeBody = useMemo(
    () => ({
      body1: vehicleInfo,
      body2: [
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>CSR</p>
            <p>{user?.name}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>DSP</p>
            <p>{item?.dsp?.name}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>Vendor</p>
            <p className='capitalize'>{item?.vendor?.name || '---'}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>Notes</p>
            {/* <CsrNotes tab={'complete'} item={item} /> */}
          </div>
        ),
        (item: any) => ServiceLocation(item),
      ],
      body3: [
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>Assigned Vendor</aside>
            <p className='text-lg capitalize'>{item?.vendor?.name || '---'}</p>
          </div>
        ),
        (item: any) => <div></div>,
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>DSP Cost</aside>
            <p className='text-lg'>$0</p>
          </div>
        ),
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>Vendor Cost</aside>
            <p className='text-lg'>{`$ ${item?.final_payment}` || '---'}</p>
          </div>
        ),
      ],
      body5: [
        (item: any) => (
          <div className='w-full flex justify-center'>
            <button className='border-none py-1.5 px-6 bg-green-100 text-green-500 rounded-lg flex items-center justify-center gap-2'>
              <i className='pi pi-check-circle'></i>
              Payment Processed
            </button>
          </div>
        ),
      ],
      body6: [
        (item: any) => (
          <div className='w-full flex justify-between bg-[#F3F3F3] p-2 rounded-2xl'>
            <div className='flex justify-between'>
              <ViewInvoice
                item={{ ...item, setInvoiceModal }}
                component={
                  <Button
                    variant='text'
                    startIcon={<InvoiceSvg />}
                    className='py-1 px-4  text-blue-600 border border-blue-600 rounded-xl font-bold bg-white h-full'>
                    {'View Invoice'}
                  </Button>
                }
              />
            </div>
            <Logs item={item} type='single' />
          </div>
        ),
      ],
      status: [
        (item: any) => (
          <div className={`px-3 py-2 bg-green-200 rounded-lg flex justify-between items-center`}>
            <p>Completed</p>
            <p>
              <UpdatedAtTime item={item} />
            </p>
          </div>
        ),
      ],
    }),
    []
  );

  //payment confirmed
  const PaymentConfirmedBody = useMemo(
    () => ({
      body1: vehicleInfo,
      body2: [
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>CSR</p>
            <p>{user?.name}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>DSP</p>
            <p>{item?.dsp?.name}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>Vendor</p>
            <p className='capitalize'>{item?.vendor?.name || '---'}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>Notes</p>
            {/* <CsrNotes tab={'complete'} item={item} /> */}
          </div>
        ),
        (item: any) => ServiceLocation(item),
      ],
      body3: [
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>Assigned Vendor</aside>
            <p className='text-lg capitalize'>{item?.vendor?.name || '---'}</p>
          </div>
        ),
        (item: any) => <div></div>,
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>DSP Cost</aside>
            <p className='text-lg'>$0</p>
          </div>
        ),
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>Vendor Cost</aside>
            <p className='text-lg'>{`$ ${item?.final_payment}` || '---'}</p>
          </div>
        ),
      ],
      body5: [
        (item: any) => (
          <>
            {item?.final_payment && (
              <div className='w-full flex justify-center'>
                <button className='border-none py-1.5 px-6 bg-green-100 text-green-500 rounded-lg flex items-center justify-center gap-2'>
                  <i className='pi pi-check-circle'></i>
                  Payment Processed
                </button>
              </div>
            )}
          </>
        ),
      ],
      body6: [
        (item: any) => (
          <div className='w-full flex justify-between bg-[#F3F3F3] p-2 rounded-2xl'>
            <div className='flex justify-between'>
              <ViewInvoice
                item={{ ...item, setInvoiceModal }}
                component={
                  <Button
                    variant='text'
                    startIcon={<InvoiceSvg />}
                    className='py-1 px-4  text-blue-600 border border-blue-600 rounded-xl font-bold bg-white h-full'>
                    {'View Invoice'}
                  </Button>
                }
              />
            </div>
            <Logs item={item} type='single' />
          </div>
        ),
      ],
      status: [
        (item: any) => (
          <>
            {item?.final_payment && (
              <div
                className={`px-3 py-2 bg-green-200 rounded-lg flex justify-between items-center`}>
                <p>Completed</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            )}
          </>
        ),
      ],
    }),
    []
  );

  // rejected
  const handleRequstModalData = {
    body6: () => {
      return [
        (item: any) => (
          <div className='w-full flex justify-between bg-[#F3F3F3] p-2 rounded-2xl'>
            <div className='flex justify-between'>
              <Logs item={item} type='single' />
            </div>
          </div>
        ),
      ];
    },
    status: () => {
      return [
        (item: any) => (
          <div className={`px-3 py-2 bg-red-200 rounded-lg flex justify-between items-center`}>
            <p>
              Rejected {item?.status_id === '3' && 'by DSP'}{' '}
              {item?.status_id === '4' && 'by Vendor'}
            </p>
            <p>
              <UpdatedAtTime item={item} />
            </p>
          </div>
        ),
      ];
    },
  };
  const rejectedData = useMemo(
    () => ({
      ...requestModel.data,
      noModelRefetch: true,
      refetch: refetchRequestData,
      refetchPm: () => {},
      setPartnerModel,
    }),
    [requestModel?.data]
  );
  const handleCloseAll = () => {
    setRequestModal({ open: false, data: {} });
    handleClose?.();
  };
  return (
    <>
      {!item?.bulk_id && (
        <>
          {' '}
          {requestModel.open && type === 'schedule' && (
            <>
              <Request
                handleClose={handleCloseAll}
                item={requestDataScheduled}
                body1={vehicleInfo}
                body6={scheduleBody}
                type={'schedule'}
                refetch={refetchRequestData}
              />
            </>
          )}
          {requestModel.open && type === 'in-process' && (
            <Request
              handleClose={handleCloseAll}
              item={requestData}
              body1={inProgressBody.body1()}
              body4={inProgressBody.body4()}
              body5={inProgressBody.body5()}
              body6={inProgressBody.body6()}
              fullbody2={inProgressBody.fullbody2()}
              status={inProgressBody.status()}
              type={'schedule'}
              refetch={refetchRequestData}
            />
          )}
          {requestModel.open && type === 'dispute' && (
            <>
              <Request
                handleClose={handleCloseAll}
                item={updatedData}
                body1={disputeBody.body1}
                body2={disputeBody.body2}
                body3={disputeBody.body3}
                body5={disputeBody.body5}
                body6={disputeBody.body6}
                status={disputeBody.status}
                refetch={refetchRequestData}
                type={'complete'}
              />
            </>
          )}
          {requestModel.open && type === 'complete' && (
            <Request
              handleClose={handleCloseAll}
              item={updatedData}
              body1={completeBody.body1}
              body2={completeBody.body2}
              body3={completeBody.body3}
              body5={completeBody.body5}
              body6={completeBody.body6}
              status={completeBody.status}
              type={'complete'}
              refetch={refetchRequestData}
            />
          )}
          {requestModel.open && type === 'paymentConfirmed' && (
            <Request
              handleClose={handleCloseAll}
              item={updatedData}
              body1={PaymentConfirmedBody.body1}
              body2={PaymentConfirmedBody.body2}
              body3={PaymentConfirmedBody.body3}
              body5={PaymentConfirmedBody.body5}
              body6={PaymentConfirmedBody.body6}
              status={PaymentConfirmedBody.status}
              type={'complete'}
            />
          )}
          {requestModel.open && type === 'rejected' && (
            <Request
              handleClose={handleCloseAll}
              refetch={refetchRequestData}
              body1={vehicleInfo}
              item={rejectedData}
              type={'rejected'}
              status={handleRequstModalData.status()}
              body6={handleRequstModalData.body6()}
            />
          )}
          {leasePlanModal.open && (
            <LeasePlan
              handleClose={() => setLeasePlanModal({ open: false, data: {} })}
              item={leasePlanModal.data}
              refetch={refetchRequestData}
            />
          )}
          {imageModal.open && (
            <ImageModal
              handleClose={() => {
                setImageModal({ open: false, data: {} });
              }}
              item={imageModal.data}
              handleMoveStage={handleMoveState}
            />
          )}
          {invoiceModal.open && (
            <>
              {type === 'dispute' || type === 'complete' || type === 'paymentConfirmed' ? (
                <Invoice
                  handleClose={() => setInvoiceModal({ open: false, data: {} })}
                  item={invoiceModal.data}
                />
              ) : (
                <InvoiceModal
                  refetch={refetchRequestData}
                  handleClose={() => {
                    setInvoiceModal({ open: false, data: {} });
                  }}
                  invoiceData={invoiceModal?.data}
                  handleMoveStage={handleMoveState}
                />
              )}
            </>
          )}
          {invoiceModalEdit.open && (
            <InvoiceModal
              refetch={refetchRequestData}
              handleClose={() => {
                setInvoiceModalEdit({ open: false, data: {} });
              }}
              invoiceData={invoiceModalEdit.data}
            />
          )}
          {technicianModel?.open && (
            <AssignTechModel
              data={technicianModel?.data}
              handleClose={() => setTechnicianModel({ open: false, data: {} })}
              refetch={refetchRequestData}
            />
          )}
          {vendorModal.open && (
            <AssignVendorModal
              data={vendorModal.data}
              handleClose={() => setVendorModal({ open: false, data: {} })}
              refetch={refetchRequestData}
            />
          )}
          {partnerModel?.open && (
            <AssignPartnerModel
              data={partnerModel?.data}
              handleClose={() => setPartnerModel({ open: false, data: {} })}
              refetch={refetchRequestData}
            />
          )}
          {showCancelModel.open && (
            <ModelContainerCancel
              handleClose={() => setShowCancelModel({ open: false, data: {} })}
              item={showCancelModel.data}
              refetch={refetchRequestData}
            />
          )}
        </>
      )}
    </>
  );
};

export default OpenCSRModel;
