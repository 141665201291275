import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import * as React from 'react';

export default function LongMenu({ options, onClick, isPending }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='w-fit'>
      <Badge color='error' badgeContent=' ' overlap='circular' variant='dot' invisible={!isPending}>
        <button
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
          className='cursor-pointer border-none  hover:rounded-r-lg flex items-center justify-center h-[2.5rem] w-[2.7rem] bg-lightGray rounded-lg hover:bg-slate-200 '>
          <i className='pi pi-ellipsis-v font-bold text-[#4378C8]' />
        </button>
      </Badge>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            minWidth: '10rem',
          },
        }}
        open={open}
        onClose={handleClose}>
        {isPending && (
          <p className='text-[#C6A169] bg-[#FFF2DF] rounded-lg p-1 text-center'>Pending Action</p>
        )}
        {options?.map(
          (elem: { head: any; event: () => void; isPending?: boolean }, index: number) => (
            <MenuItem
              key={elem.head}
              onClick={() => {
                if (typeof elem.head === 'string') {
                  handleClose();
                }
                elem?.event && elem.event();
              }}
              className='flex gap-4'>
              {typeof elem.head !== 'string' && elem.head(handleClose)}
              {typeof elem.head === 'string' && elem.head !== 'Cancel Request' && elem.head}
              {elem.head === 'Cancel Request' ? (
                <p className='text-[#CC5555]'>{elem.head}</p>
              ) : (
                <></>
              )}
              {elem?.isPending && (
                <Badge color='error' badgeContent=' ' variant='dot' overlap='circular'></Badge>
              )}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
}
