import { useRef } from 'react';

export function Search({ inputRef, handleSearch }: any) {
  const searchRef = useRef<HTMLDivElement>(null);
  const isPartnerInvoice = window.location.href.includes('/partner/invoice');
  const isVendorInvoice = window.location.href.includes('/vendor/invoice');
  const isVendorRequestReport = window.location.href.includes('/vendorRequestReport');

  const isInvoicePage = isPartnerInvoice || isVendorInvoice || isVendorRequestReport;
  const isRevenuePage =
    window.location.href.includes('/vendor/dashboard/revenue') ||
    window.location.href.includes('/partner/dashboard/revenue');
  const isPendingPage = window.location.href.includes('/csr/dashboard?type=pending');

  const handleDivClick = () => {
    inputRef.current?.focus();
  };
  const getSearchPlaceholder = () => {
    if (isPendingPage) {
      return 'Search By VIN No';
    }

    if (!isInvoicePage) {
      return 'Search By VIN No, Request Id';
    }

    if (isVendorRequestReport) {
      return 'Search By VIN No, Station Code, Request Id....';
    }

    return 'Search By VIN No, Station Code, Invoice Id, Request Id....';
  };
  const placeholder: string = getSearchPlaceholder();
  return (
    <>
      {!isRevenuePage && (
        <div
          ref={searchRef}
          id='searchbar'
          className='relative bg-white flex items-center border-solid border-[0.13rem] p-2.5 rounded-full right-0 cursor-pointer hover:border-primary focus-within:border-primary'
          onClick={handleDivClick}>
          <i className='pi pi-search text-[1.2rem]  font-bold'></i>
          <input
            ref={inputRef}
            type='text'
            placeholder={placeholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
            className={`pl-2 focus:outline-none border-none transition ease-in duration-300 ${
              isInvoicePage ? (isVendorRequestReport ? 'w-[23rem]' : 'w-[26rem]') : 'w-[16rem]'
            }`}
          />
        </div>
      )}
    </>
  );
}
