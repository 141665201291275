import moment from 'moment';
import Tootltip from '../../../../../components/BarData/Tootltip';

function InvoiceTemplateVendorNew({
  invoiceDetails,
  item,
  height,
  invoices,
}: {
  invoiceDetails: any;
  item: any;
  height?: any;
  invoices?: any;
}) {
  const amountStyles: React.CSSProperties = {
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <div
      style={{
        height: `${height ? height : '25rem'}`,
        width: '100%',
        overflow: 'auto',
        paddingRight: 3,
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
          border: '0.1rem solid gray',
          borderRadius: '0.5rem',
        }}>
        {' '}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
            borderBottom: '0.1rem solid gray',
            borderStyle: 'dotted',
          }}>
          <div>
            <p className='flex' style={{ fontWeight: 'bold' }}>
              INVOICE
            </p>
            <p className='flex'>INVOICE NUMBER {invoiceDetails?.invoice?.number}</p>
          </div>
          <p style={{ fontWeight: 'bold' }}>
            INVOICE DATE {moment(invoiceDetails?.invoice_date).format('L')}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            padding: '1rem',
            borderBottom: '0.1rem solid gray',
            borderStyle: 'dotted',
          }}>
          <div>
            <p className='flex' style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
              {invoiceDetails?.vendor?.name}
            </p>
            <p className='flex'>{invoiceDetails?.vendor?.address}</p>
            <p className='flex'>{invoiceDetails?.vendor?.email}</p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}>
          <div style={{ maxWidth: '50%' }}>
            <p className='flex' style={{ fontWeight: 'bold' }}>
              BILL TO
            </p>
            <Tootltip
              title={
                invoiceDetails?.invoice?.address ||
                'Americas Repair Solutions 7 PATRIOT HILL DR - BASKING RIDGE, NJ 07920'
              }
              placement='bottom'>
              <p className=' text-ellipsis overflow-hidden max-w-[18rem] '>
                {' '}
                {invoiceDetails?.invoice?.address ||
                  'Americas Repair Solutions 7 PATRIOT HILL DR - BASKING RIDGE, NJ 07920'}
              </p>
            </Tootltip>
            <p className='flex'>Phone: {invoiceDetails?.invoice?.phone || '425-299-2247'}</p>
            <p className='flex'>
              Billing Email:{invoiceDetails?.invoice?.email || 'fleet@lmdmax.com'}
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
            width: '100%',
            gap: 5,
          }}>
          <div style={{ width: '50%' }}>
            <p className='flex' style={{ fontWeight: 'bold' }}>
              VEHICLE DETAILS
            </p>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Van Make
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.make}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Van Model
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.model}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Year
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.year}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  VIN
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.vin}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  License Plate
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.license_plate}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Van Name
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.van_name}
                </p>
              </div>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <p className='flex' style={{ fontWeight: 'bold' }}>
              STATION DETAILS
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Customer Name
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.dsp?.name}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  DSP Short Code
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.dsp?.short_code}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Station Address
                </p>
                <Tootltip title={invoiceDetails?.dsp?.station_address || ''} placement='bottom'>
                  <p
                    className=' text-ellipsis overflow-hidden max-w-[100%] flex justify-start'
                    style={{ width: '60%' }}>
                    {' '}
                    {invoiceDetails?.station_address?.locationInfo || ''}
                  </p>
                </Tootltip>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Station Zip Code
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.station_address?.zipCode}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Station Code
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.station_address?.stationCode}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: 2,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '2',
              backgroundColor: 'lightgray',
              marginLeft: 4,
              marginRight: 4,
              fontWeight: 'bold',
            }}>
            <p
              style={{
                width: '15%',
                ...amountStyles,
              }}>
              Service Date
            </p>
            {/* description */}
            <p
              style={{
                width: '30%',
                ...amountStyles,
              }}>
              Service/Part Description
            </p>
            {/* product code */}
            <p
              style={{
                width: '10%',
                ...amountStyles,
                whiteSpace: 'nowrap',
              }}>
              Product Code
            </p>
            {/* qty */}
            <p
              style={{
                width: '10%',
                ...amountStyles,
              }}>
              Qty
            </p>
            {/* rate*/}
            <p
              style={{
                width: '10%',
                whiteSpace: 'nowrap',
                ...amountStyles,
              }}>
              Rate
            </p>
            {/* Amount */}
            <p
              style={{
                width: '10%',
                whiteSpace: 'nowrap',
                ...amountStyles,
              }}>
              Amount
            </p>
          </div>
          {(
            invoiceDetails?.invoice?.additional_info?.services ||
            invoiceDetails?.invoice?.additional_info
          )?.length > 0 &&
            (
              invoiceDetails?.invoice?.additional_info?.services ||
              invoiceDetails?.invoice?.additional_info
            )?.map((row: any, index: any) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '2',
                  marginLeft: 4,
                  marginRight: 4,
                  borderBottom: '0.1rem solid gray',
                  borderStyle: 'dotted',
                }}>
                {/* service date */}
                <p
                  style={{
                    width: '15%',
                    ...amountStyles,
                  }}>
                  {moment
                    ?.utc(row?.service_date || item?.appointments?.[0]?.date)
                    .format('MM/DD/YYYY')}
                </p>
                {/* description */}
                <p
                  className='flex flex-col'
                  style={{
                    width: '30%',
                    ...amountStyles,
                  }}>
                  <p className='flex font-bold w-full capitalize'>{row?.service}</p>
                  <p className='flex w-full'>
                    <Tootltip title={row?.description} placement={'bottom'}>
                      <p className='max-w-[13rem] text-ellipsis overflow-hidden'>
                        {row?.description}
                      </p>
                    </Tootltip>
                  </p>
                </p>
                {/* product code */}
                <p
                  style={{
                    width: '10%',
                    ...amountStyles,
                    whiteSpace: 'nowrap',
                  }}>
                  {row?.product_code || 'N/A'}
                </p>
                {/* qty */}
                <p
                  style={{
                    width: '10%',
                    ...amountStyles,
                  }}>
                  {row?.quantity}
                </p>
                {/* rate*/}
                <p
                  style={{
                    width: '10%',
                    whiteSpace: 'nowrap',
                    ...amountStyles,
                  }}>
                  $ {row?.rate || row?.price || 0}
                </p>
                {/* Amount */}
                <p
                  style={{
                    width: '10%',
                    whiteSpace: 'nowrap',
                    ...amountStyles,
                  }}>
                  $ {Number(row?.rate || row?.price || 0) * Number(row?.quantity) || 0}
                </p>
              </div>
            ))}
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '2',
                marginLeft: 4,
                marginRight: 4,
                padding: 3,
                paddingLeft: 17,
                paddingRight: 20,
              }}>
              <p>Sub Total</p>
              <p>
                ${' '}
                {Number(
                  invoiceDetails?.invoice?.subtotal_amount ||
                    invoiceDetails?.invoice?.total_amount ||
                    0
                )?.toFixed(2) || ''}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '2',
                marginLeft: 4,
                marginRight: 4,
                padding: 3,
                paddingLeft: 17,
                paddingRight: 20,
              }}>
              <p>Tax (NYC {invoiceDetails?.invoice?.tax_percent || 0}%)</p>
              <p>$ {Number(invoiceDetails?.invoice?.total_tax_amount || 0)?.toFixed(2) || ''}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '2',
                marginLeft: 4,
                marginRight: 4,
                borderTop: '0.1rem solid gray',
                padding: 3,
                paddingLeft: 17,
                paddingRight: 20,
              }}>
              <p>Total Due</p>
              <p>$ {Number(invoiceDetails?.invoice?.total_amount || 0)?.toFixed(2) || ''}</p>
            </div>
          </>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
          <p className='flex' style={{ fontWeight: 'bold' }}>
            Note
          </p>
          <p className='flex'>{invoiceDetails?.invoice?.note || 'N/A'}</p>
        </div>
      </div>
    </div>
  );
}
export default InvoiceTemplateVendorNew;
