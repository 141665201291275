import { useState } from 'react';
import FleetMaxIcon from '../assets/FleetMaxIcon';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../state/reducers';
import { useSelector } from 'react-redux';
import DrawerComps from './comps/DrawerComps';
export default function Drawer() {
  const navaigate = useNavigate();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [isHovered, setHovered] = useState(false);
  const isVehiclesPage = window.location.href.includes('vendor/dashboard');
  const isCSR: boolean = user?.role === 'fleetmax_csr';

  return (
    <>
      <div className='w-[5%]'></div>
      <div
        className='border-r-[0.1rem] border-gray-300'
        style={{
          position: 'absolute',
          width: isHovered ? '17%' : '5%',
          transition: '0.1s ease',
          height: '100%',
          backgroundColor: 'white',
          zIndex: 15,
          boxShadow: '1.5px 0px 0px 0px rgba(0, 0, 0, 0.1)',
        }}
        onMouseEnter={() => setHovered(true)}>
        {!isHovered ? (
          <>
            <p className='w-full h-full flex flex-col justify-between items-center'>
              <p className='w-full h-[10%] flex flex-col justify-center items-center'>
                <FleetMaxIcon height='2rem' width='2rem' />
              </p>
              <div className='w-full h-full flex flex-col gap-7 justify-start items-center mt-3'>
                {DrawerComps('icons')}
              </div>
              {DrawerComps('userIcon')}
            </p>
          </>
        ) : (
          <>
            <p
              className='w-full h-full flex flex-col justify-between items-center'
              onMouseLeave={() => setHovered(false)}>
              <p
                className='w-full h-[10%] flex justify-center items-center gap-2 cursor-pointer'
                onClick={() => {
                  if (!isVehiclesPage) navaigate(`${isCSR ? 'csr' : 'vendor'}/dashboard`);
                }}>
                <FleetMaxIcon height='2rem' width='2rem' />
                <p className='text-[1.5rem] text-primary font-bold'>FLEETMax</p>
              </p>
              <div className='w-[95%] h-full flex flex-col gap-2  justify-start items-center mt-3'>
                {DrawerComps('content')}
              </div>
              {DrawerComps('userContent')}
            </p>
          </>
        )}
      </div>
    </>
  );
}
