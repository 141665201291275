import { Link } from 'react-router-dom';
import ServiceListPopUp from './components/ServiceListPopUp';
import { useEffect, useState } from 'react';
import CsrAPI from '../../../../api/csrApi';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import { toast } from '../../../../components/Toast/CustomToast';
const filterServices = (searchValue: string, data: any) => {
  if (!searchValue) {
    return data || [];
  } else {
    return data.filter((item: any) => {
      const isNameMatch = item?.name?.toLowerCase().includes(searchValue.toLowerCase());
      return isNameMatch;
    });
  }
};
function ServiceList() {
  const [loader, setLoader] = useState<boolean>(false);
  const loading = HandleLoad();
  const [data, setData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');

  const fetchData = () => {
    CsrAPI.getService().then((res) => {
      if (res.status === 200) {
        const finalData = res.data.data.map((item: any) => ({
          name: item.name,
          service: item.titles.map((item: any) => ({
            name: item.title,
            id: item.id,
          })),
        }));
        setData(finalData);
      }
    });
  };
  useEffect(() => {
    loading(true);
    fetchData();
    setTimeout(() => {
      loading(false);
    }, 2000);
  }, []);
  const [editData, setEditData] = useState({
    editIndex: null,
    data: null,
    newData: null,
    service_name: null,
  });
  const handleEdit = (service_name: any, editData: any) => {
    const payloadData = {
      titles: [
        {
          service_name: service_name,
          service_titles: [editData.data],
        },
      ],
    };
    CsrAPI.addServiceImageName(payloadData).then((res) => {
      if (res.status === 200) {
        setLoader(true);
        fetchData();
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    });
    setEditData({ editIndex: null, data: null, newData: null, service_name: null });
  };
  const handleDelete = (serviceIndex: any) => {
    CsrAPI.deleteServiceImageName({ id: serviceIndex || '' }).then((res: any) => {
      if (res.status === 200) {
        setLoader(true);
        fetchData();
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      } else {
        toast.warn('Something wrong');
      }
    });
  };
  const props = { editData, setEditData, handleEdit, handleDelete, loader };
  const filteredServices = filterServices(searchValue, data);
  return (
    <div className='flex flex-col w-full pt-3 mt-[1rem] mx-[2.5rem] flex-1 h-full'>
      <div className='flex justify-between mb-6 p-1'>
        <Link to='/csr/profile'>
          <button className='flex px-6 rounded-[3rem] w-[12rem] py-3 border-primary bg-white '>
            <i className='pi pi-angle-left mt-1 text-primary'></i>
            <p className='text-primary ml-3 font-bold'> Back to Profile</p>
          </button>
        </Link>
        <div className='text-[2rem] font-bold mr-[1rem]'>Service List</div>
        <div className='relative w-[20%]'>
          <i className='pi pi-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-[1.2rem] font-bold'></i>
          <input
            className='w-full h-[3rem] rounded-[1rem] pl-10 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
            type='text'
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder='Search'
          />
        </div>
      </div>
      <div className='w-full h-[87%] bg-white rounded-xl flex flex-col p-2'>
        <div className='h-[15%] w-full  border-b-[0.1rem] border-gray flex flex-col pl-5'>
          <p className='text-[2rem]'>Services</p>
          <p className='text-[1rem] text-gray-400'>
            You can view and delete the image titles given against each service
          </p>
        </div>
        {filteredServices?.length > 0 && (
          <div className='h-fit max-h-[85%] w-full overflow-auto grid grid-cols-2 gap-9 p-2 break-words'>
            {filteredServices?.map((item: any, index: any) => (
              <div
                key={index}
                className=' rounded-lg w-full h-fit flex justify-between gap-3 items-center'>
                <p className='text-[1rem] flex items-center min-h-[3rem] p-1 w-[50%] border-r-[0.2rem] border-gray-200 capitalize'>
                  {item.name}
                </p>
                <p className='text-[1rem]  w-[50%] ml-6'>
                  <ServiceListPopUp
                    item={item?.service || []}
                    variant='expandDown'
                    props={props}
                    service_name={item.name}
                  />
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
export default ServiceList;
