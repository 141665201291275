import { Menu, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function SortButton({ newSearchParams }: any) {
  const [_, setSearchParams]: any = useSearchParams();

  const sortItems = [
    'Price: High to Low',
    'Price: Low to High',
    'Request: Old to New',
    'Request: New to Old',
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedSort, setSelectedSort] = React.useState('Price: High to Low');
  const isVendorDashboard =
    window.location.href.includes('vendor/dashboard') ||
    window.location.href.includes('partner/dashboard');

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (selectedSort: string) => {
    setSelectedSort(selectedSort);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    let order;
    let type;
    switch (selectedSort) {
      case 'Price: High to Low':
        type = 'revenue_sort';
        order = 'desc';
        break;
      case 'Price: Low to High':
        type = 'revenue_sort';
        order = 'asc';
        break;
      case 'Request: Old to New':
        type = 'sort_type';
        order = 'asc';
        break;
      case 'Request: New to Old':
        type = 'sort_type';
        order = 'desc';
        break;
      default:
        type = 'sort_type';
        order = 'asc';
    }
    newSearchParams.delete('sort_type');
    newSearchParams.delete('revenue_sort');
    newSearchParams.set(type, order);
    setSearchParams(newSearchParams);
  }, [selectedSort]);

  return (
    <>
      <button
        className={`flex justify-between items-center rounded-full ${
          isVendorDashboard ? ' h-[2.5rem]' : ' h-[3rem]'
        } border-[0.15rem] border-gray px-[0.5rem] w-[8rem]  bg-primary-lighter ml-[0.5rem] `}
        onClick={handleClick}>
        <div
          className={`
     
                 'text-gray-600'
             font-bold-700 text-[1.1rem] pl-[0.4rem]`}>
          Sort By
        </div>
        <img
          src='../../svg/sort.svg'
          alt='sortArrow'
          className='inline-block h-[1.8rem] w-[1.8rem] mr-[0.3rem]  pb-[0.1rem]'
        />
      </button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: {
            width: '14rem',
            marginTop: '0.5rem',
          },
        }}>
        {sortItems.map((item) => (
          <MenuItem
            key={item}
            onClick={() => handleMenuItemClick(item)}
            selected={selectedSort === item}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export default SortButton;
