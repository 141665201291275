import React from 'react';
import { RootState } from '../../../../state/reducers';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';

function CustomiseInvoices() {
  const dispatch = useDispatch();
  const { customizeView }: any = useSelector((state: RootState) => state.userState);
  const { setCustomizeView } = bindActionCreators(actionCreators, dispatch);
  const isVendorRequestReport = window.location.href.includes('/vendorRequestReport');
  const heading: string = isVendorRequestReport ? 'Vendor Request Report' : 'Invoice Management';
  return (
    <p>
      {heading}
      {!customizeView ? (
        <Button
          variant='text'
          className='h-[2rem] w-[8rem] gap-[0.2rem] pt-[0.2rem] hover:bg-primary-light text-center rounded-full'
          onClick={() => {
            setCustomizeView(!customizeView);
          }}
          startIcon={
            <div>
              <img
                src='../../svg/dashboard_customize_btn.svg'
                alt='dashboard'
                className='inline-block h-[1.1rem] w-[1.1rem] ml-[0.4rem]  pb-[0.1rem]'
              />
            </div>
          }>
          <div className='text-center pl-[0.1rem] font-bold text-[1rem] mt-[0.3rem]'>Customize</div>
        </Button>
      ) : (
        <span className='font-bold text-tableHeading text-[1rem] pl-[1rem]'>
          Customize your view by adding or removing fields.
        </span>
      )}
    </p>
  );
}

export default CustomiseInvoices;
