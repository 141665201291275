import React, { useEffect, useRef, useState } from 'react';
import Tootltip from '../../../../../components/BarData/Tootltip';
import { ThreeDots } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';
import VendorAPI from '../../../../../api/vendorApi';
import { Tooltip } from '@mui/material';
import { RootState } from '../../../../../state/reducers';
import { useSelector } from 'react-redux';
interface ResponseDataItem {
  total_revenue: string | number;
  name?: string;
  service_name?: string;
}
interface DataType {
  name: string;
  value: number;
}
function RevenueSources() {
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [selectedFilter, setSelectedFilter] = useState<string>('Services');
  const [loader, setLoader] = useState<{ loader: boolean; offsetLoader: boolean }>({
    loader: false,
    offsetLoader: false,
  });
  const scrollableDivRef: any = useRef(null);
  const [offset, setOffset] = useState<number>(0);
  const [totalData, setTotalData] = useState<number>(0);
  const [urlParams]: any = useSearchParams();
  const weeks = urlParams.get('weeks');
  const months = urlParams.get('months');
  const vendor_ids = urlParams.get('vendor_ids');
  const options = !vendor_ids
    ? ['Services', 'Vendors', 'Technicians']
    : ['Services', 'Technicians'];
  const [data, setData] = useState<DataType[]>([]);

  const getBackgroundColor = (value: number) => {
    if (value >= 60) {
      return '#26618B';
    } else if (value >= 40) {
      return '#74A5D7';
    } else {
      return '#A3D7FD';
    }
  };

  const getClassNames = (option: string) => {
    const baseClasses =
      'w-full flex font-bold justify-center items-center p-2 text-[1.2rem] cursor-pointer';
    const selectedClasses = selectedFilter === option ? 'border-b-[0.15rem]' : '';
    const loaderClasses =
      loader?.loader || loader?.offsetLoader
        ? 'text-gray-400'
        : selectedFilter === option
        ? 'text-primary border-primary'
        : 'text-gray-600';
    return `${baseClasses} ${selectedClasses} ${loaderClasses}`;
  };
  const buildQueryParams = () => {
    const period = months ? `months=${months}` : `weeks=${weeks}`;
    const requestType = `request_type=${selectedFilter.toLowerCase()}`;

    const vendorParam = urlParams.get('vendor_ids');
    const vendorIds = vendorParam ? `&vendor_ids=${vendorParam}` : '';

    const vendorIdArray = vendorParam ? JSON.parse(vendorParam) : [];
    const partnerAsVendor = vendorIdArray[0] === user?.account_id ? '&partner_as_vendor=true' : '';
    const limitOffset = `&limit=${10 + offset}&offset=0`;

    return `${period}&${requestType}${vendorIds}${partnerAsVendor}${limitOffset}`;
  };

  const fetchRevenueSources = async () => {
    if (!loader?.loader && !loader?.offsetLoader && (offset === 0 || offset <= totalData)) {
      if (offset === 0) {
        setLoader({ ...loader, loader: true });
      } else {
        setLoader({ ...loader, offsetLoader: true });
      }
      try {
        const query = buildQueryParams();
        const res = await VendorAPI.getRevenueSources({ params: query });
        if (res.status === 200 && res.data.data) {
          const responseData = res.data.data?.result;
          const sortedData: DataType[] = responseData.map((item: ResponseDataItem) => ({
            name: item?.service_name || item?.name || '',
            value: parseFloat(item?.total_revenue.toString()),
          }));
          setData(sortedData);
          setTotalData(res.data.data?.total_records || 0);
          setLoader({ loader: false, offsetLoader: false });
        } else {
          setData([]);
          setLoader({ loader: false, offsetLoader: false });
        }
      } catch (error) {
        console.error('Error fetching revenue sources:', error);
        setData([]);
        setLoader({ loader: false, offsetLoader: false });
      } finally {
        setLoader({ loader: false, offsetLoader: false });
      }
    }
  };

  useEffect(() => {
    setSelectedFilter('Services');
    setOffset(0);
  }, [months, weeks, vendor_ids]);
  useEffect(() => {
    setOffset(0);
  }, [selectedFilter]);
  useEffect(() => {
    const timer = setTimeout(async () => {
      if (weeks?.length > 0 || months?.length > 0) {
        await fetchRevenueSources();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [selectedFilter, months, weeks, vendor_ids, offset]);
  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      const handleScroll = () => {
        if (
          scrollableDiv.scrollTop + scrollableDiv.clientHeight + 1 >=
          scrollableDiv.scrollHeight
        ) {
          setOffset((prevOffset) => prevOffset + 10);
        }
      };
      scrollableDiv.addEventListener('scroll', handleScroll);
      return () => {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      };
    } else {
      console.log('Ref is not attached');
    }
  }, [data]);
  return (
    <div className='w-full h-[46%] flex flex-col justify-between border-[0.2rem] border-gray-200 p-2 rounded-[0.5rem] gap-1 shadow-md'>
      <div className='w-full flex flex-col gap-1'>
        <p className='w-full flex justify-between items-center'>
          <p>Revenue Sources</p>
        </p>
        <div className='flex w-full justify-between'>
          {options.map((option) => (
            <div
              key={option}
              onClick={() => {
                if (!loader?.loader && !loader?.offsetLoader) {
                  setSelectedFilter(option);
                }
              }}
              className={getClassNames(option)}>
              {option}
            </div>
          ))}
        </div>
      </div>
      {loader?.loader ? (
        <div className='w-full h-full flex justify-center items-center'>
          <ThreeDots
            height='10'
            width='50'
            radius='9'
            color={'#4378C8'}
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            visible={true}
          />
        </div>
      ) : (
        <>
          {data.length > 0 ? (
            <div className='flex flex-col gap-2 h-full w-full overflow-auto' ref={scrollableDivRef}>
              {data.map((ele) => {
                const maxRevenue = Math.max(...data.map((item) => +item.value));
                const widthPercentage = (+ele.value / maxRevenue) * 100;
                return (
                  <div
                    key={ele.name}
                    className='w-full flex font-bold justify-between items-center p-2'>
                    <Tootltip placement='bottom' followCursor>
                      <p className='text-md whitespace-nowrap overflow-hidden text-ellipsis w-[8rem] text-left capitalize'>
                        {ele.name}
                      </p>
                    </Tootltip>
                    <Tooltip
                      key={ele?.value || ''}
                      title={`Total Revenue: $ ${ele?.value || 0}`}
                      followCursor>
                      <div className='w-[70%] h-[1.5rem]'>
                        <div
                          className='h-full'
                          style={{
                            width: `${widthPercentage}%`,
                            backgroundColor: getBackgroundColor(+widthPercentage),
                          }}></div>
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
              {loader?.offsetLoader && (
                <div className='w-full h-full flex justify-center items-center'>
                  <ThreeDots
                    height='10'
                    width='50'
                    radius='9'
                    color={'#4378C8'}
                    ariaLabel='three-dots-loading'
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className='flex justify-center items-center text-[1.1rem] text-gray h-full rounded-l-[0.5rem]'>
              <i className='pi pi-info-circle mr-2'></i>
              No Data Found
            </div>
          )}
          {data.length > 0 && (
            <div className='w-full flex font-bold justify-between items-center p-2  cursor-pointer'>
              <p className='w-[30%] h-[1rem] '></p>
              <div className='w-[70%] h-[1rem] flex justify-between'>
                <p>Min</p>
                <p>Max</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RevenueSources;
