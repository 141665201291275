import { useEffect } from 'react';
import moment from 'moment-timezone';
import OpenModel from '../../../openModel/OpenVendorModel';
import useFetchInvoicesData from './useFetchInvoicesData';
import { useSearchParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import useFetchRevenue from '../revenueHistory/useFetchRevenue';
import VendorAPI from '../../../../../api/vendorApi';
import { toast } from '../../../../../components/Toast/CustomToast';
import Tootltip from '../../../../../components/BarData/Tootltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import { generateTabs } from './tabValues';

function Invoices({ showModel, setShowModel }: any) {
  const [urlParams]: any = useSearchParams();
  const {
    loader,
    refetchInvoices,
    invoicesData,
    counts,
    selectedTab,
    setSelectedTab,
    offset,
    setOffset,
    offsetLoader,
    setInvoicesData,
    timeoutActive,
    setTimeoutActive,
    totalPages,
  } = useFetchInvoicesData();
  const { fetchAllRevenueData } = useFetchRevenue();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const isPartner: boolean = user?.role === 'fleetmax_partner';
  const getBorderColor = (status: string) => {
    if (status === 'pending') {
      return 'primary';
    } else if (status === 'received') {
      return '[#AC9059]';
    } else if (status === 'approved') {
      return '[#7B58DE]';
    } else if (status === 'disputed') {
      return '[#CC5555]';
    } else if (status === 'payment_completed') {
      return '[#51994F]';
    } else return 'primary';
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (urlParams?.get('weeks')?.length > 0 || urlParams?.get('months')?.length > 0) {
        setOffset(10);
        setInvoicesData(null);
        refetchInvoices(10, []);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [
    selectedTab,
    urlParams?.get('weeks'),
    urlParams?.get('months'),
    urlParams?.get('vendor_ids'),
  ]);
  useEffect(() => {
    if (offset > 10) {
      if (urlParams?.get('weeks')?.length > 0 || urlParams?.get('months')?.length > 0) {
        if (totalPages >= +offset / 10 && timeoutActive) {
          setTimeoutActive(true);
          setTimeout(() => {
            setTimeoutActive(false);
          }, 2000);
          refetchInvoices(offset, invoicesData);
        }
      }
    }
  }, [offset]);
  const remind = async (ele?: any) => {
    const response = await VendorAPI.remind({
      bulk_requests: [
        {
          preventive_request_id: ele?.preventive_request_id,
        },
      ],
    });

    if (response.status >= 200 && response.status <= 210) {
      setOffset(10);
      setInvoicesData(null);
      refetchInvoices(10, []);
      toast.info('Reminder sent to CSR!');
    } else {
      toast.error(response?.data?.message);
    }
  };
  const handleScroll = (e: any) => {
    const div = e.target;
    const atBottom = div.scrollHeight - div.scrollTop <= div.clientHeight + 1;
    if (atBottom && !loader && !timeoutActive) {
      setOffset(offset + 10);
      setTimeoutActive(true);
      setTimeout(() => {
        setTimeoutActive(false);
      }, 2000);
    }
  };
  const tab = generateTabs(counts);
  return (
    <div
      className={`w-full ${
        isPartner ? 'h-[51%] min-h-[51%]' : 'h-full'
      }  flex flex-col border-[0.2rem] border-gray-200 p-2 rounded-[0.5rem] gap-3 shadow-md`}>
      <p>Invoices</p>
      {(urlParams?.get('weeks') || urlParams?.get('months')) && (
        <>
          <div className='grid grid-cols-4 gap-2'>
            {tab.map(({ key, label, bgColor, textColor, borderColor, count }) => (
              <p
                key={key}
                className={`p-2 rounded-full px-4 flex justify-center font-bold cursor-pointer whitespace-nowrap items-center ${bgColor} ${textColor} ${
                  selectedTab === key ? `border-[0.1rem] ${borderColor}` : ''
                } ${key === 'payment_completed' ? ' w-[13rem]' : ''}`}
                onClick={() => setSelectedTab(key)}>
                {label} ({count})
              </p>
            ))}
          </div>
          <>
            {loader && (
              <div className='h-full w-full flex justify-center items-center'>
                <ThreeDots
                  height='10'
                  width='50'
                  radius='9'
                  color={'#4378C8'}
                  ariaLabel='three-dots-loading'
                  wrapperStyle={{}}
                  visible={true}
                />
              </div>
            )}
            <div className='flex flex-col gap-3 overflow-auto h-full' onScroll={handleScroll}>
              {!loader &&
                invoicesData &&
                invoicesData.map((ele: any) => {
                  return (
                    <p
                      // key={ele.preventive_request_id}
                      className='group  p-2 py-3 flex justify-between border-[0.1rem] border-gray-200 rounded-[0.5rem] mr-1 '>
                      <div className='flex items-center gap-3'>
                        <p
                          className={`text-primary text-[0.9rem] font-bold px-1 flex gap-2 items-center border-l-[0.2rem] border-${getBorderColor(
                            ele?.invoice_status
                          )}`}>
                          <span
                            className='cursor-pointer hover:underline '
                            onClick={() => {
                              if (ele?.preventive_request_id)
                                setShowModel({
                                  show: true,
                                  data: {
                                    ...ele,
                                    id: ele?.preventive_request_id,
                                  },
                                  isIndex: false,
                                });
                            }}>
                            Request {ele.preventive_request_id}
                          </span>
                        </p>
                        <i className='pi pi-circle-fill text-[0.4rem] text-gray-500'></i>
                        <Tootltip title={`Van ${ele.vehicle_name}`} placement={'bottom'}>
                          <p
                            className='font-bold text-black text-[0.9rem] max-w-[12rem] text-ellipsis overflow-hidden'
                            style={{ whiteSpace: 'nowrap' }}>
                            Van {ele.vehicle_name}
                          </p>
                        </Tootltip>
                      </div>
                      <div className='flex  gap-2'>
                        {ele?.invoice_status === 'pending' &&
                          ele?.is_remind &&
                          ele?.is_remind !== 'false' && (
                            <p
                              className='text-[#BFBFBF] hidden group-hover:block rounded-[0.5rem] px-2 font-bold bg-primary-light text-primary cursor-pointer w-fit mx-auto text-[0.9rem]'
                              onClick={() => {
                                remind(ele);
                              }}>
                              Remind
                            </p>
                          )}
                        <p className='text-gray-400'>{moment(ele.log_date).format('ll')}</p>
                      </div>
                    </p>
                  );
                })}
              {!loader && offsetLoader && (
                <div className='h-[8rem] w-full flex justify-center items-center'>
                  <ThreeDots
                    height='10'
                    width='50'
                    radius='9'
                    color={'#4378C8'}
                    ariaLabel='three-dots-loading'
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
              {!loader && invoicesData?.length === 0 && (
                <div className='w-full  h-full flex items-cnter justify-center items-center '>
                  No Invoices
                </div>
              )}
            </div>
          </>
          <OpenModel
            item={showModel.data}
            handleClose={() => {
              setShowModel({ show: false, data: {}, isIndex: false });
            }}
            refetch={() => {
              if (showModel.isIndex) {
                fetchAllRevenueData();
              } else {
                setOffset(10);
                setInvoicesData(null);
                refetchInvoices(10, []);
              }
            }}
          />
        </>
      )}
      {!urlParams?.get('weeks') && !urlParams?.get('months') && (
        <div className='flex justify-center items-center h-full'>
          {' '}
          <img
            src='../../svg/info.svg'
            alt='infoIcon'
            className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
          />
          Please select a time range
        </div>
      )}
    </div>
  );
}

export default Invoices;
