import { useMemo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import { VoiceNotes } from '../../../../csr/bulkInfoModal/components/WorkProofData';
import { Button, Popover } from '@mui/material';
import { useFetchNotesRemarks } from '../../../../hooks/useFetchNotesRemarks';
import { ThreeDots } from 'react-loader-spinner';

const getRole = (source: any, currentUser: any) => {
  const addedBy = source?.added_by;
  const rejectionReason = source?.remark_source?.includes?.('reject_request_by_vendor_attempt_');
  const isMoveToComplete = source?.remark_source?.includes?.('move_to_complete');
  const roles: any = {
    fleetmax_csr: 'CSR',
    fleetmax_vendor: 'Vendor',
    fleetmax_partner: 'Partner',
    fleetmax_supervisor: 'Supervisor',
    fleetmax_technician: 'Technician',
    owner: 'DSP',
  };
  if (rejectionReason) {
    return 'Reason For Rejection';
  } else if (isMoveToComplete) {
    return 'Reason For Moving ';
  } else if (currentUser in roles) {
    if (addedBy === currentUser) {
      return 'You';
    } else if (addedBy in roles) {
      return roles[addedBy];
    }
  } else return 'Note';
};
function condition(note: any) {
  if (
    (note?.remark?.length === 0 || note?.remark?.length === undefined) &&
    (note?.voice_notes?.length === 0 || note?.voice_notes?.length === undefined)
  ) {
    return false;
  } else
    return (
      typeof note?.remark === 'string' ||
      note?.remark_source === 'moved_to_approval_by_technician' ||
      note?.voice_notes?.length > 0
    );
}
export default function NotesPopup({
  item,
  variant,
  size,
  noPopUp,
  notesRemarks,
  notesValue,
  type,
}: {
  item: any;
  variant?: string;
  size?: string;
  noPopUp?: boolean;
  notesRemarks?: any;
  notesValue?: JSX.Element;
  type?: string;
}) {
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [anchorEl, setAnchorEl] = useState(null);
  const { getRemakrs, remakrs, sendLoader }: any = useFetchNotesRemarks();
  useEffect(() => {
    if (anchorEl) {
      getRemakrs(item);
    }
  }, [anchorEl, item]);
  const sortedNotes = useMemo(() => {
    if (!notesRemarks) return remakrs;
    else return remakrs.filter((note: any) => notesRemarks?.includes(note?.remark_source) ?? false);
  }, [remakrs, notesRemarks]);

  if (!item) return null;
  const isCustomNote = (remark?: any) => {
    if (['proof_accepted']?.includes(remark)) {
      return true;
    } else return false;
  };
  return (
    <div className='w-full'>
      <ExpandPopUp
        noPopUp={noPopUp}
        showOnExpand={
          <div
            className={`container overflow-auto  mb-2 p-2  ${
              size === 'large' ? 'h-[27rem]' : 'h-[12rem]'
            }`}
            style={{
              backgroundColor: 'white',
              borderRadius: '0.4rem',
            }}>
            {sendLoader && (
              <div className='w-full  h-full flex items-center justify-center '>
                <ThreeDots
                  height='10'
                  width='50'
                  radius='9'
                  color={'#4378C8'}
                  ariaLabel='three-dots-loading'
                  wrapperStyle={{}}
                  visible={true}
                />
              </div>
            )}
            {!sendLoader && (
              <>
                {notesValue ? (
                  notesValue
                ) : (
                  <>
                    {sortedNotes?.length == 0 && <p>No notes available</p>}
                    {sortedNotes?.length > 0 &&
                      sortedNotes?.map((note: any) => {
                        return (
                          <>
                            {condition(note) && (
                              <div className='flex gap-2 mb-2 flex-col'>
                                {isCustomNote(note?.remark_source) ? (
                                  <p className='mb-[-0.3rem] text-[0.9rem]'>
                                    CSR accepted this service without work proofs
                                  </p>
                                ) : (
                                  <div
                                    className='container text-primary w-fit text-[0.7rem] p-0.5 px-2 bg-primary-light'
                                    style={{
                                      borderRadius: '5px',
                                      fontWeight: 'bold',
                                    }}>
                                    <h1 className='capitalize'>{getRole(note, user.role)}</h1>
                                  </div>
                                )}

                                <div
                                  className='container text-[0.9rem] break-words'
                                  style={{
                                    backgroundColor: 'white',
                                    fontSize: '0.9rem',
                                  }}>
                                  <p>
                                    {isCustomNote(note?.remark_source) && (
                                      <span className='text-primary'>Reason: </span>
                                    )}
                                    {typeof note?.remark === 'string' &&
                                      note?.remark?.length > 0 &&
                                      note?.remark}
                                  </p>
                                </div>
                                {note?.voice_notes?.length > 0 && (
                                  <VoiceNotes voice_notes={note.voice_notes} />
                                )}
                              </div>
                            )}
                          </>
                        );
                      })}
                  </>
                )}
              </>
            )}
          </div>
        }
        variant={variant}
        title='Notes'
        item={item}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </div>
  );
}

let parentWidth = 0;
let parentHeight = 0;

function ExpandPopUp({
  item,
  icon,
  showOnExpand,
  title,
  variant,
  noPopUp,
  sx,
  onOpen,
  defaultOpen,
  anchorEl,
  setAnchorEl,
}: {
  item: any;
  noPopUp?: boolean;
  variant?: string | 'expandDown';
  icon?: JSX.Element;
  title: string;
  showOnExpand: JSX.Element;
  sx?: string;
  onOpen?: () => void;
  defaultOpen?: boolean;
  anchorEl: any;
  setAnchorEl: any;
}) {
  const parentRef: any = useRef(null);
  useEffect(() => {
    if (parentRef.current) {
      parentWidth = parentRef.current.clientWidth;
      parentHeight = parentRef.current.clientHeight;
    }
  }, [parentRef]);

  const handleOpenPopup = (event: any) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      onOpen?.();
    }
  };

  useEffect(() => {
    if (defaultOpen && parentRef.current) {
      setAnchorEl(parentRef.current);
    }
  }, [defaultOpen, parentRef.current]);

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const expandDown = variant === 'expandDown';

  const upDirection = expandDown ? !anchorEl : anchorEl;

  if (!item) return null;

  return (
    <div className='w-full'>
      <Button
        className={`bg-primary-light text-primary relative w-full  flex justify-between px-4 font-bold p-1.5  ${
          anchorEl && (upDirection ? 'rounded-t-none' : 'rounded-b-none')
        } h-[2.5rem] ${sx}`}
        onClick={handleOpenPopup}
        ref={parentRef}>
        <div className='flex gap-2 items-center'>
          {icon}
          {title}
        </div>
        <i
          className={`pi pi-angle-down ${
            upDirection ? 'rotate-0' : 'rotate-180'
          } ease-in-out duration-200 text-grey text-primary`}></i>
      </Button>
      {noPopUp ? (
        anchorEl && (
          <div
            style={{
              width: parentRef.current?.clientWidth,
              boxShadow: 'none',
              borderRadius: 0,
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : {
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                  }),
            }}>
            <div
              className='relative  w-full bg-primary-light p-1'
              style={{
                ...(expandDown
                  ? {
                      borderBottomLeftRadius: '0.5rem',
                      borderBottomRightRadius: '0.5rem',
                    }
                  : { borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }),
              }}>
              {showOnExpand}
            </div>
          </div>
        )
      ) : (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          PaperProps={{
            sx: {
              width: parentRef.current?.clientWidth,
              boxShadow: 'none',
              borderRadius: 0,
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : {
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                  }),
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'bottom',
              horizontal: 'center',
            }),
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            ...(expandDown && {
              vertical: 'top',
              horizontal: 'center',
            }),
          }}>
          <div
            className='relative  w-full bg-primary-light p-1'
            style={{
              ...(expandDown
                ? {
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }
                : { borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }),
            }}>
            {showOnExpand}
          </div>
        </Popover>
      )}
    </div>
  );
}
