import { Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import ApprovedSvg from '../../../assets/Approved';
import { RootState } from '../../../state/reducers';
import { SortUpdated } from '../../common/SortData';
import Table from '../../common/Table';
import TillMiles from '../../csr/primaryComponents/TillMiles';
import useFetchSingleRequest from '../../hooks/vendor/useFetchSingleRequest';
import Invoice from '../PrimaryComponents/invoice/Invoice';
import Tootltip from '../../../components/BarData/Tootltip';
import { paymentCompletedCheck, proofAccepetedCheck } from './Approved';
import PriorityIcons from '../../csr/scheduleRequest/components/PriorityIcons';
import { CustomTooltip } from '../../csr/components/Complete';
import { toast } from '../../../components/Toast/CustomToast';
import useTooltipData from '../../csr/components/useTooltipData';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import { TwoLinesTooltip } from '../../../components/BarData/TwoLinesTooltip';

export const initialView = {
  viewChecklist: false,
  submitProof: false,
};

export const invoiceReceivedCheck = (pmRequest: any) =>
  pmRequest?.status_id !== '12' && pmRequest?.invoice?.invoice_status === 'sent';

export const viewInvoiceCheck = (pmRequest: any) =>
  ['sent', 'amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
    pmRequest?.invoice?.invoice_status
  );

const PaymentConfirmed = ({ approvedData, fetchRequestData }: any) => {
  const dispatch = useDispatch();
  const { setShowModel } = bindActionCreators(actionCreators, dispatch);

  const [data, setData] = useState<any[]>([]);
  const [invoiceModal, setInvoiceModal] = useState({ open: false, data: {} });
  const { fetchRequestData: fetchRequestDetails }: any = useFetchSingleRequest();
  const { showModel } = useSelector((state: RootState) => state.userState);
  const { tooltipData, fetchTooltipData } = useTooltipData();

  useEffect(() => {
    filterProcessStatus();
  }, [approvedData]);

  const requestFilterHead = ['Invoice Received', 'Proofs Accepted', 'Payment Completed'];

  const handleSort = (data: any) => {
    setData(SortUpdated(data));
  };

  const filterProcessStatus = () => {
    handleSort(
      approvedData.filter((elem: { status_id: string; dsp: { dsp_short_code: string } }) => true)
    );
  };

  const menuHeads = (
    item: { status_id: string; preventive_request_id: string; sub_stage: string },
    number: number
  ) => {
    if (['9'].includes(item.status_id)) {
      return [{ head: '', event: () => {} }];
    } else {
      if (viewInvoiceCheck(item)) {
        return [
          {
            head: 'View Invoice',
            event: () => {
              fetchRequestDetails(item).then((res: any) => {
                if (res) {
                  setInvoiceModal({
                    open: true,
                    data: { ...res, title: `Request-${item?.preventive_request_id}` },
                  });
                }
              });
            },
          },
        ];
      } else {
        return [{ head: '', event: () => {} }];
      }
    }
  };

  const tableData = useMemo(
    () => ({
      header: [
        (item: any) => <p className='w-[6rem]'></p>,
        (item: any) => 'showDspShortCode',
        (item: any) => <p className='w-[6rem]'>Van Name</p>,
        (item: any) => <p className='w-[12rem]'>VIN</p>,
        (item: any) => <p className='w-[6rem]'>Last PM</p>,
        (item: any) => <p className='w-[5.3rem]'>Miles Till Inspection</p>,
        (item: any) => <p className='w-[5rem]'>Current Mileage</p>,
        (item: any) => <p className='w-[7rem]'>Assigned Technician</p>,
      ],
      rowData: [
        (item: any, number: number) => (
          <div className='flex items-center gap-2  w-[15rem]'>
            <div className='w-[2rem] h-[2rem]'>
              <PriorityIcons iconType={item?.priority || '2'} />
            </div>
            {proofAccepetedCheck(item) && (
              <Tooltip title='Proofs Accepted'>
                <div className='w-[3.5rem] bg-teal-200 flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
                  <ApprovedSvg />
                </div>
              </Tooltip>
            )}
            {invoiceReceivedCheck(item) && (
              <Tooltip title='Invoice Received'>
                <div className='w-[3.5rem] bg-teal-200 flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
                  <i className='pi pi-money-bill' style={{ fontSize: '1rem' }}></i>
                </div>
              </Tooltip>
            )}
            {paymentCompletedCheck(item) && (
              <Tooltip title='Invoice Approved'>
                <div className='w-[3.5rem] bg-green-200 flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
                  <i className='pi pi-dollar' style={{ fontSize: '1rem' }}></i>
                </div>
              </Tooltip>
            )}
            {item?.bulkSchedules?.length > 0 ? (
              <CustomTooltip
                title={
                  <div className='capitalize'>
                    {tooltipData?.[item?.bulk_id]?.length > 0
                      ? tooltipData[item?.bulk_id]?.map((dataItem: any) => (
                          <div key={dataItem.vehicle_name}>{dataItem.vehicle_name}</div>
                        ))
                      : 'loading...'}
                  </div>
                }>
                <button
                  onMouseEnter={async () => {
                    try {
                      if (!tooltipData[item?.bulk_id]) {
                        if (item?.bulk_id) {
                          await fetchTooltipData(item?.bulk_id);
                        }
                      }
                    } catch (error) {
                      toast.error('Failed to fetch tooltip data');
                    }
                  }}
                  className='text-blue-500 font-semibold text-sm cursor-pointer border-none py-1.5 px-3 hover:bg-slate-100 rounded-full whitespace-nowrap '
                  onClick={() => {
                    setShowModel({
                      show: true,
                      data: item,
                    });
                  }}>
                  {item?.title}
                </button>
              </CustomTooltip>
            ) : (
              <button
                className='text-blue-500 font-semibold text-sm cursor-pointer border-none py-1.5 px-3 hover:bg-slate-100 rounded-full whitespace-nowrap '
                onClick={() => {
                  setShowModel({
                    show: true,
                    data: item,
                  });
                }}>
                {item.title}
              </button>
            )}
          </div>
        ),
        (item: any) => (
          <p className=' flex items-center justify-center'>
            <TwoLinesTooltip text={item?.dsp?.station_code || ''} />
          </p>
        ),
        (item: any) => (
          <p className='w-[6rem]'>
            <Tootltip title={item?.vehicle?.name} placement={'bottom'}>
              <p
                className='max-w-[6rem] text-ellipsis overflow-hidden'
                style={{ whiteSpace: 'nowrap' }}>
                {item?.vehicle?.name}
              </p>
            </Tootltip>
          </p>
        ),
        (item: any) => <p className='w-[12rem]'>{item?.vehicle?.vin}</p>,
        (item: any) => <p className='w-[6rem]'>{item?.miles || '---'}</p>,
        TillMiles,
        (item: any) => <p className='w-[5rem]'>{item?.curr_miles || '---'}</p>,
        (item: any) => <p className='w-[7rem] capitalize'>{item?.technician?.name || 'N/A'}</p>,
        (item: any, number: any) =>
          menuHeads(item, number).map((elem: { head: string; event: () => void }) => (
            <button
              className='text-blue-500 font-semibold text-sm cursor-pointer border-none py-1.5 px-3 hover:bg-slate-100 rounded-full w-[6rem] whitespace-nowrap'
              onClick={elem.event}
              key={v4()}>
              {elem.head}
            </button>
          )),
      ],
    }),
    [requestFilterHead, tooltipData]
  );
  useEffect(() => {
    if (invoiceModal.open) {
      setInvoiceModal({
        ...invoiceModal,
        data: {
          ...invoiceModal?.data,
          ...showModel?.data,
        },
      });
    }
  }, [showModel?.data]);
  return (
    <>
      <Table
        havebulkrow
        data={data}
        header={tableData.header}
        dataVisibleContent={tableData.rowData}
        bulkrowformat={tableData.rowData}
      />
      {invoiceModal.open && (
        <Invoice
          handleClose={() => setInvoiceModal({ open: false, data: {} })}
          item={invoiceModal.data}
          fetchRequestData={() => {
            fetchRequestData();
            setShowModel({ open: false, data: {} });
          }}
        />
      )}
    </>
  );
};

export default PaymentConfirmed;
