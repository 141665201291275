import { useState } from 'react';
import OverlayContainer from '../../../../components/OverlayContainer';
import ModalForm from '../../../../components/ModalForm';
import { ImagePDFPreview } from '../../../common/ImageModal';
import moment from 'moment';
import Tootltip from '../../../../components/BarData/Tootltip';

function VehicleQuestionnaire(item?: any) {
  const [model, setModel] = useState({ show: false, data: {} });
  const checklist = item?.item?.checklist_questions?.question_answers || [];
  const props = {
    model,
    setModel,
    item: item?.item,
  };
  const answeredCount = checklist?.filter(
    (obj: any) =>
      obj?.answer_id !== undefined ||
      (['YES/NO', 'TEXT'].includes(obj?.type) && obj?.answer?.value?.length > 0) ||
      (['IMAGE'].includes(obj?.type) &&
        (obj?.answer?.value?.length > 0 || obj?.answer?.images?.length > 0))
  )?.length;
  if (checklist?.length < 1) return null;
  else
    return (
      <>
        <div
          className='flex text-primary text-[1.1rem] gap-1 items-center cursor-pointer'
          onClick={() => {
            setModel({ show: true, data: checklist });
          }}>
          <p className='hover:underline'>Vehicle Checklist Questionnaire </p>
          {checklist && (
            <p className='text-gray-500 '>
              ({answeredCount || 0}/{checklist?.length || ''} Answered)
            </p>
          )}
        </div>
        {model?.show && <Model props={{ ...props }} />}
      </>
    );
}

export default VehicleQuestionnaire;
function renderContentByType(item: any, itemType: string, setViewImage: any) {
  return (
    <div className='w-full flex flex-col gap-2'>
      {item?.answer?.value && (
        <p
          className={`w-full break-words  ${
            item?.answer?.value?.length > 0 ? 'text-black' : 'text-gray-400'
          }`}>
          {item?.answer?.value || 'Not Answered (NA)'}
        </p>
      )}
      <div className='grid grid-cols-10 gap-4'>
        {item?.answer?.images?.length > 0 &&
          [...item?.answer?.images]?.map((image: any, index: any) => (
            <img
              className='cursor-pointer'
              onClick={() => {
                setViewImage({
                  view: true,
                  url: item?.answer?.images,
                });
              }}
              key={index}
              src={image.url}
              alt={`Vehicle Checklist ${index}`}
              style={{ maxWidth: '100%', maxHeight: '150px' }}
            />
          ))}
      </div>
      {(!item?.answer?.images || item?.answer?.images?.length === 0) && !item?.answer?.value && (
        <p className={`w-full break-words text-gray-400 whitespace-nowrap`}>
          {'Not Answered (NA)'}
        </p>
      )}
    </div>
  );
}
function Model({ props }: { props: any }) {
  const { model, setModel, item }: any = props;
  const answered_at = item?.checklist_questions?.answered_at;
  const [viewImage, setViewImage] = useState<any>({ view: false, url: [{ url: '' }] });
  const handleClose = () => {
    setModel({ show: false, data: {} });
  };
  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[60vw]'} noLabel>
        <div className='flex flex-col gap-4 h-[40rem] z-60' style={{ zIndex: 60 }}>
          <div className='flex flex-col h-[10%] gap-1'>
            <p className='text-[1.5rem] flex gap-4 items-center '>
              Vehicle Checklist
              <p className='text-primary text-[1.2rem]'>
                (Request {item?.id || item?.preventive_request_id})
              </p>
            </p>
            {answered_at && (
              <p className='text-[0.8rem] flex text-gray-400 items-center '>
                Answered - {moment(answered_at).format('L')} - {moment(answered_at).format('LT')}
              </p>
            )}
          </div>
          <div className='flex flex-col h-[90%] overflow-auto gap-5 pr-2'>
            {model?.data?.map((item: any) => (
              <>
                {item?.question && (
                  <div className='w-full h-fit flex flex-col border-[0.1rem] border-gray-300 rounded-xl p-2 gap-2'>
                    <Tootltip placement='bottom' title={item?.question}>
                      <p className='text-[1.2rem] whitespace-nowrap overflow-hidden text-ellipsis w-[95%] text-left capitalize'>
                        {item?.question}
                      </p>
                    </Tootltip>
                    <div>{renderContentByType(item, item?.type, setViewImage)}</div>
                  </div>
                )}
              </>
            ))}
          </div>
          {viewImage.view && (
            <ImagePDFPreview
              imgArr={viewImage?.url?.map((ele: any) => ele?.url)}
              handleClose={() => setViewImage({ view: false, url: [{ url: '' }] })}
            />
          )}
        </div>
      </ModalForm>
    </OverlayContainer>
  );
}
