import axios from 'axios';
import { useEffect, useState } from 'react';
import EditIcon from '../../../../assets/EditIcon';
import { toast } from '../../../../components/Toast/CustomToast';
import LocationPicker from './GeoCode';
import { Tooltip } from '@mui/material';
import { apiKey } from '../../../location/ApiKey';

const extractAddress = (context: any[]) => {
  let address = '';
  if (Array.isArray(context)) {
    for (const item of context) {
      switch (item.id.split('.')[0]) {
        case 'place':
        case 'poi':
        case 'neighborhood':
        case 'locality':
        case 'region':
          address = `${item.text} ${address}`;
          break;
        default:
          break;
      }
    }
  }
  return address.trim();
};

const extractZipCode = (data: any) => {
  let zipCode = null;
  for (const component of data.address_components) {
    if (component.types.includes('postal_code')) {
      zipCode = component.short_name;
      break;
    }
  }

  return zipCode;
};

const extractStationCode = (context: any[]) => {
  if (Array.isArray(context)) {
    for (const item of context) {
      if (item.id.startsWith('station')) {
        return item.text;
      }
    }
  }
  return '';
};

function LocationInfo({
  item,
  notesSection,
  saveLocationData,
  serviceLocationData,
}: {
  serviceLocationData: any;
  item?: any;
  notesSection?: any;
  saveLocationData?: any;
}) {
  const [showMaps, setShowMaps] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<any>(null);
  const [serviceLocation, setServiceLocation] = useState({
    locationInfo: '',
    zipCode: '',
    stationCode: '',
    coordinates: null,
  });
  const [edit, setEdit] = useState(false);

  const handleSearch = async (locationDetails: any) => {
    try {
      const query =
        locationDetails?.locationInfo +
        ' ' +
        locationDetails?.zipCode +
        ' ' +
        locationDetails?.stationCode;

      const response: any = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${apiKey}`
      );

      const setData = () => {
        setCoordinates(null);
        setServiceLocation({
          ...locationDetails,
          coordinates: null,
        });
        saveLocationData({
          ...locationDetails,
          coordinates: null,
        });
      };

      if (response.data?.results) {
        const data = response.data;

        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          const lat = location.lat;
          const lng = location.lng;
          const coordinates = { lat, lng };
          setCoordinates({ lat, lng });
          setServiceLocation((prev: any) => ({
            ...prev,
            ...locationDetails,
            coordinates,
          }));
          saveLocationData((prev: any) => ({
            ...prev,
            ...locationDetails,
            coordinates,
          }));
        } else {
          setData();
        }
      } else {
        setData();
      }
    } catch (error) {
      console.error('Error geocoding:', error);
    }
  };

  const handelSave = () => {
    if (serviceLocation?.locationInfo) {
      if (!serviceLocation?.coordinates) {
        handleSearch(serviceLocation);
      } else if (JSON.stringify(serviceLocation) !== JSON.stringify(serviceLocationData)) {
        handleSearch(serviceLocation);
      } else {
        saveLocationData(serviceLocation);
      }
      setEdit(false);
    } else {
      toast.warn('Please fill address details!!');
    }
  };

  const handleMapsClick = () => {
    setShowMaps(false);
  };
  const getSavedDataDetails = (item: any) => {
    if (item?.service_location?.locationInfo) {
      return {
        locationInfo: item.service_location.locationInfo || '',
        zipCode: item.service_location.zipCode || '',
        stationCode: item.service_location.stationCode || '',
        ...(item?.service_location?.coordinates && {
          coordinates: item.service_location.coordinates,
        }),
      };
    }

    return null;
  };

  const handleSavedDataDetails = (savedDataDetails: any, item: any) => {
    if (typeof savedDataDetails === 'object' && savedDataDetails) {
      // saved in db as a json object
      saveLocationData(savedDataDetails);
      setCoordinates(savedDataDetails?.coordinates);
      setServiceLocation(savedDataDetails);
    } else if (typeof savedDataDetails === 'string' && savedDataDetails) {
      // saved in db as a string
      handleSearch({
        locationInfo: savedDataDetails,
        zipCode: '',
        stationCode: '',
      });
    } else {
      // not saved
      handleSearch({
        locationInfo:
          item?.dsp?.address || item?.address || item?.service_location?.locationInfo || '',
        zipCode: item?.dsp?.zipcode || item?.zipcode || item?.service_location?.zipCode || '',
        stationCode:
          item?.dsp?.station_code ||
          item?.station_code ||
          item?.service_location?.stationCode ||
          '',
      });
    }
  };

  const fetchData = async () => {
    const savedDataDetails = getSavedDataDetails(item);
    handleSavedDataDetails(savedDataDetails, item);
  };

  useEffect(() => {
    if (!serviceLocationData) {
      fetchData();
    } else {
      setServiceLocation(serviceLocationData);
      if (serviceLocationData?.coordinates?.lat) {
        setCoordinates(serviceLocationData.coordinates);
      }
    }
  }, [item, serviceLocationData]);

  return (
    <>
      <div
        className={` w-full h-full ${
          !showMaps && 'border-[#c2c2c2] border-[0.1rem]'
        } rounded-[1rem] `}>
        {!showMaps ? (
          <div>
            {!edit ? (
              <>
                <div className='p-3 flex flex-col gap-1'>
                  <div className='flex justify-between'>
                    <p className='flex justify-center items-center text-primary font-bold'>
                      <i className='pi pi-map-marker'></i>
                      <p className='ml-1'>Station Address</p>
                    </p>
                    <Tooltip title={'Edit Station Address'} placement='top'>
                      <button
                        className='text-primary font-bold border-transparent'
                        onClick={() => setEdit(!edit)}>
                        <EditIcon height='1rem' width='1rem' />
                      </button>
                    </Tooltip>
                  </div>
                  {serviceLocationData?.coordinates === null && (
                    <div className='flex justify-center w-full p-1 rounded-xl hover:bg-rose-100'>
                      <p className='flex  w-full text-[0.8rem] text-rose-300 items-center'>
                        {' '}
                        <i className='pi pi-info-circle mr-2'></i>
                        <p>Enter a Valid Address</p>
                      </p>
                    </div>
                  )}
                  <div className='ml-2 max-h-[3rem] overflow-auto'>
                    {serviceLocation?.locationInfo || 'N/A'}
                  </div>

                  <div className='flex justify-between mt-1'>
                    <div>
                      <p className='text-[1rem] text-[#A8A8A8]'>ZIP Code</p>
                      <p>{serviceLocation?.zipCode || 'N/A'}</p>
                    </div>
                    <div>
                      <p className='text-[1rem] text-[#A8A8A8]'>Station Code</p>
                      <p>{serviceLocation?.stationCode || 'N/A'}</p>
                    </div>
                    <div></div>
                  </div>
                  <div
                    className='flex justify-center w-full text-primary text-[0.9rem] cursor-pointer hover:bg-primary-light rounded-lg w-fit px-2 py-1'
                    onClick={() => {
                      setShowMaps(true);
                    }}>
                    Show on Maps
                  </div>
                </div>
              </>
            ) : (
              <div className='grid grid-cols-1 mb-7 p-3 gap-2'>
                <div className='flex flex-col gap-2'>
                  <p className='text-[0.9rem] text-[#A8A8A8]'>Service Location*</p>
                  <textarea
                    className='w-full  rounded-[0.6rem] p-1  duration-300 outline-blue-500 border-grey-400 border-1 border-[#A0A0A0]'
                    rows={4}
                    style={{
                      border: '1px solid #e5e7eb',
                      resize: 'none',
                    }}
                    defaultValue={serviceLocation?.locationInfo}
                    onChange={(e) => {
                      setServiceLocation({
                        ...serviceLocation,
                        locationInfo: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className='grid grid-cols-2 gap-2 mt-2'>
                  <div className='flex flex-col gap-2'>
                    <p className='text-[0.9rem] text-[#A8A8A8]'>ZIP Code</p>
                    <input
                      className='w-full h-[2.5rem] rounded-[0.6rem] p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1 border-[#A0A0A0]'
                      type='text'
                      style={{
                        border: '1px solid #e5e7eb',
                      }}
                      defaultValue={serviceLocation.zipCode}
                      onChange={(e) =>
                        setServiceLocation({ ...serviceLocation, zipCode: e.target.value })
                      }
                    />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <p className='text-[0.9rem] text-[#A8A8A8]'>Station Code</p>
                    <input
                      className='w-full h-[2.5rem] rounded-[0.6rem] p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1 border-[#A0A0A0]'
                      type='text'
                      style={{
                        border: '1px solid #e5e7eb',
                      }}
                      defaultValue={serviceLocation.stationCode}
                      onChange={(e) => {
                        setServiceLocation({
                          ...serviceLocation,
                          stationCode: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='flex justify-between px-2 my-2 mt-6'>
                  <button
                    className='text-[#CC5555] font-bold border-transparent'
                    onClick={() => {
                      setEdit(false);
                      setServiceLocation(serviceLocationData);
                    }}>
                    Cancel
                  </button>
                  <button
                    className='text-primary font-bold border-transparent'
                    onClick={handelSave}>
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div
              className='cursor-pointer flex items-center gap-3 px-2 py-3 rounded-t-[1rem] z-10 text-[#FFFFFF] '
              style={{ backgroundColor: 'grey' }}
              onClick={handleMapsClick}>
              <i className='pi pi-times' style={{ fontSize: '1.2rem' }}></i>
              Close Map View
            </div>
            <LocationPicker
              defaultCoordinates={coordinates}
              saveLocationData={(placeDetails: any) => {
                const { formatted_address, coordinates } = placeDetails;
                const details = {
                  locationInfo: formatted_address,
                  zipCode: extractZipCode(placeDetails),
                  stationCode: extractStationCode(placeDetails),
                  coordinates: coordinates,
                };
                setCoordinates(coordinates);
                saveLocationData(details);
                setServiceLocation(details);
              }}
            />
          </div>
        )}
      </div>
      {!edit && !showMaps && notesSection}
    </>
  );
}

export default LocationInfo;
