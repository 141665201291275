import { useEffect, useRef, useState } from 'react';
import Tootltip from '../../../../../components/BarData/Tootltip';
import TickButton from '../../../../../assets/TickButton';
import CrossButtons from '../../../../../assets/CrossButton';
import { ThreeDots } from 'react-loader-spinner';
import ExpandPopUpService from './ExpandPopUpService';
import { toast } from '../../../../../components/Toast/CustomToast';
import CustomConfirm from '../../../../../components/PopOver/CustomDelete';
export default function ServiceListPopUp({
  item,
  variant,
  props,
  service_name,
}: {
  item: any;
  variant?: string;
  props?: any;
  service_name: any;
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleScrollToBottomClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  const { editData, setEditData, handleEdit, handleDelete, loader } = props;
  useEffect(() => {
    setEditData({ editIndex: null, data: null, newData: null, service_name: null });
  }, [anchorEl]);
  const editFeild = (item?: any) => {
    return (
      <>
        <input
          className='w-[80%] h-[2rem] rounded-[0.5rem]  p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
          type='text'
          maxLength={35}
          value={editData?.data}
          onChange={(e) => {
            if (e.target.value?.length === 35) {
              toast.info('Maximum 35 charecters allowed!');
            }
            setEditData({ ...editData, data: e.target.value, service_name: service_name });
          }}
          placeholder='Enter Image Title'
        />
        <div className='flex gap-2'>
          <button
            className='text-[#4378C8] border-transparent ml-3 rounded-full'
            onClick={() => handleEdit(service_name, editData)}>
            <TickButton height='1.5rem' width='1.5rem' />
          </button>
          <button
            className='text-[#4378C8] border-transparent rounded-full'
            onClick={() =>
              setEditData({ editIndex: null, data: null, newData: null, service_name: null })
            }>
            <CrossButtons height='1.5rem' width='1.5rem' />
          </button>
        </div>
      </>
    );
  };
  if (!item) return null;
  return (
    <div className='w-full'>
      <ExpandPopUpService
        showOnExpand={
          <div
            className={`container overflow-auto h-[10rem]'
            `}
            style={{
              backgroundColor: 'white',
              borderRadius: '0.4rem',
            }}>
            <>
              {loader && (
                <div className={`w-full h-[10rem] flex justify-center items-center `}>
                  <ThreeDots
                    height='60'
                    width='60'
                    radius='9'
                    color={'#4378C8'}
                    ariaLabel='three-dots-loading'
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
              {!loader && (
                <div className='flex flex-col max-h-[10rem] overflow-auto' ref={containerRef}>
                  {item?.length > 0 &&
                    item?.map((serviceItem: any, serviceIndex: number) => {
                      return (
                        <div
                          key={serviceIndex}
                          className='text-[1rem] text-gray-600 p-2 border-b-[0.1rem] border-gray-200 flex justify-between'>
                          {editData?.editIndex === serviceIndex ? (
                            <>{editFeild(serviceItem)}</>
                          ) : (
                            <>
                              <Tootltip title={serviceItem?.name} placement={'bottom'}>
                                <p
                                  className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden capitalize`}>
                                  {serviceItem?.name}
                                </p>
                              </Tootltip>
                              <div className='flex items-center gap-2'>
                                <CustomConfirm
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  comp={
                                    <i className='pi pi-trash text-gray-400 cursor-pointer'></i>
                                  }
                                  title={`Delete Service Image Title`}
                                  info={`Are you sure you want to delete this Service Image Title for this service ? `}
                                  accept={() => {
                                    handleDelete(serviceItem?.id);
                                  }}
                                  acceptText={'Yes, Delete'}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  {editData.newData ? (
                    <div className='flex justify-between items-center p-2'>{editFeild()}</div>
                  ) : (
                    <div
                      className='text-[1rem] text-gray-600 p-2 flex justify-between text-primary cursor-pointer'
                      onClick={() => {
                        setEditData({
                          data: '',
                          editIndex: null,
                          newData: true,
                          service_name: service_name,
                        });
                        setTimeout(() => {
                          handleScrollToBottomClick();
                        }, 100);
                      }}>
                      {item?.length > 0 ? '+ Add More' : '+ Add '}
                    </div>
                  )}
                </div>
              )}
            </>
          </div>
        }
        variant={variant}
        title={`Service Image Titles (${item?.length})`}
        item={item}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </div>
  );
}
