import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import DownloadInvoiceButton from '../../../../../../assets/DownloadInvoiceButton';
import { Environment } from '../../../../../../constants/environment';
import { RootState } from '../../../../../../state/reducers';
import { toast } from '../../../../../../components/Toast/CustomToast';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../../state';
import VendorAPI from '../../../../../../api/vendorApi';
import axios from 'axios';
let pdfMake = require('pdfmake/build/pdfmake.js');
let pdfFonts = require('pdfmake/build/vfs_fonts.js');

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export const fetchInvoiceDetailsArray = async (data: any) => {
  if (!data) return null;

  try {
    const res = await VendorAPI.getInvoiceDetailsArray({ id: data });
    if (res.status === 200 || res.status === true) {
      return res?.data?.data || [];
    } else {
      toast.error('Failed to fetch invoice details');
      return null;
    }
  } catch (error) {
    console.error('Error fetching invoice details:', error);
    return null;
  }
};

export default function DownloadDSPInvoice({
  selectedInvoice,
  invoices,
}: {
  selectedInvoice: any;
  invoices?: any;
}) {
  const dispatch = useDispatch();
  const { setInvoicesLoader } = bindActionCreators(actionCreators, dispatch);
  const { user, invoiceLoader }: any = useSelector((state: RootState) => state.userState);
  const baseUrl = Environment.baseUrl;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function downloadReport(isMultiple?: boolean) {
    // const ids = isMultiple
    //   ? JSON.stringify(invoices.map((invoice: any) => invoice.id))
    //   : JSON.stringify([selectedInvoice?.id]);
    // if (isMultiple) {
    //   if (!invoiceLoader) {
    //     try {
    //       toast.info('Downloading PDF!');
    //       setInvoicesLoader(true);
    //       const pdfData = await fetchInvoiceDetailsArray(ids);
    //       const docDef = await GetDSPInvoicePDFMultiple(pdfData);
    //       const pdfDoc = pdfMake.createPdf(docDef);
    //       pdfDoc.getBuffer((buffer: Uint8Array) => {
    //         const blob = new Blob([buffer], { type: 'application/pdf' });
    //         const url = URL.createObjectURL(blob);
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = 'invoice.pdf';
    //         link.click();
    //         URL.revokeObjectURL(url);
    //         setInvoicesLoader(false);
    //       });
    //     } catch (error) {
    //       console.error('Error generating PDF:', error);
    //       toast.error('Unable to download PDF!');
    //       setInvoicesLoader(false);
    //     }
    //   } else {
    //     toast.error('Please Wait until the previous is downloading');
    //   }
    // } else {
    if (!invoiceLoader) {
      setInvoicesLoader(true);
      const ids = isMultiple
        ? JSON.stringify(invoices.map((invoice: any) => invoice.id))
        : JSON.stringify([selectedInvoice?.id]);
      toast.info('Downloading PDF!');
      axios
        .get(`${baseUrl}csr/v1/download_invoice?ids_arr=${ids}`, {
          responseType: 'arraybuffer',
          headers: {
            'x-access-token': user?.token || '',
            'x-access-user': user?.account_id || '',
            'app-type': '9',
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          var link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Invoice.pdf`);
          document.body.appendChild(link);
          link.click();
          setInvoicesLoader(false);
        });
    } else {
      toast.error('Please Wait until the previous is downloading');
    }
    // }
  }

  return (
    <div>
      <Button
        className='h-[3.2rem] w-[15rem] font-bold flex items-center gap-2 border-[0.07rem] border-primary'
        disabled={invoiceLoader}
        variant='outlined'
        id='demo-positioned-button'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <DownloadInvoiceButton active={!invoiceLoader} />
        Download Invoice
      </Button>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <MenuItem
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => {
            handleClose();
            downloadReport();
          }}>
          Single PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            downloadReport(true);
          }}>
          Combine all in one PDF
        </MenuItem>
      </Menu>
    </div>
  );
}
