import { Button } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import EditRequestIcon from '../../../../assets/EditRequestIcon';
import RedoSvg from '../../../../assets/Redo';
import ModalForm from '../../../../components/ModalForm';
import OverlayContainer from '../../../../components/OverlayContainer';
import { RootState } from '../../../../state/reducers';
import BtnPopUp from '../../bulkInfoModal/components/BtnPopUp';
import { ScheduleTime } from '../../components/schedule/Schedule';
import ServiceLocation from '../ServiceLocation';
import TrackLocation from '../TrackLocation';
import { MismatchPaymentInformation, VendorPaymentInfo } from './components/ButtonsAndComps';
import { vendorInvoiceAmountNotSame } from '../../bulkInfoModal/csr/Vehicle';
import AssignUnAssignPopUp from '../../../vender/PrimaryComponents/AssignUnAssignPopUp';
import EditRequestModelBulk from '../../bulkInfoModal/components/EditModel';
export const VendorButtonPopUp = ({ item }: any) => (
  <BtnPopUp
    title={'Vendor'}
    readOnly={!['4'].includes(item.status_id)}
    value={item?.vendor?.name}
    options={[
      {
        head: (
          <div className='flex gap-2 items-center'>
            <RedoSvg />
            <p className='text-sm'>Re-Assign</p>
          </div>
        ),
        event: () => {
          item?.setVendorModal({
            open: true,
            data: {
              ...item,
              suggested_vendor: null,
            },
          });
        },
      },
      {
        head: (
          <div className='flex gap-2 items-center text-sm'>
            <i className='pi pi-times-circle text-rose-300 text-sm'></i>
            <p className=''>Un-Assign</p>
          </div>
        ),
        event: () => {
          item?.setRejecting(true);
        },
      },
    ]}
  />
);
export const TechnicianButtonPopUp = ({ item }: any) => (
  <BtnPopUp
    title={'Technician'}
    readOnly={!['5', '10'].includes(item.status_id)}
    value={item?.technician?.name}
    options={[
      {
        head: (
          <div className='flex gap-2 items-center'>
            <RedoSvg />
            <p className='text-sm'>Re-Assign</p>
          </div>
        ),
        event: () => {
          item?.setTechnicianModel({
            open: true,
            data: {
              ...item,
              suggested_technician: null,
              previous_technician_account_id: item?.technician?.account_id || '',
              previous_vendor_account_id: item?.vendor?.account_id || '',
            },
          });
        },
      },
      {
        head: (
          <div className='flex gap-2 items-center text-sm'>
            <i className='pi pi-times-circle text-rose-300 text-sm'></i>
            <p className=''>Un-Assign</p>
          </div>
        ),
        event: () => {
          item?.setRejecting(true);
        },
      },
    ]}
  />
);
export const PartnerButtonPopUp = ({ item }: any) => (
  <BtnPopUp
    title={'Partner'}
    readOnly={!['-1'].includes(item.status_id)}
    value={item?.partner?.name}
    options={[
      {
        head: (
          <div className='flex gap-2 items-center'>
            <RedoSvg />
            <p className='text-sm'>Re-Assign</p>
          </div>
        ),
        event: () => {
          item?.setPartnerModel({
            open: true,
            data: {
              ...item,
              suggested_partner: null,
            },
          });
        },
      },
      {
        head: (
          <div className='flex gap-2 items-center text-sm'>
            <i className='pi pi-times-circle text-rose-300 text-sm'></i>
            <p className=''>Un-Assign</p>
          </div>
        ),
        event: () => {
          item?.setRejecting(true);
        },
      },
    ]}
  />
);
const Scheduled = ({
  item,
  selectedRequest,
  refetch,
}: {
  item: any;
  selectedRequest?: any;
  refetch?: any;
}) => {
  const [showEditModel, setShoweditModel] = useState(false);
  const { user } = useSelector((state: RootState) => state.userState);
  const isDisputedPage = window.location.href.includes('dispute');
  return (
    item && (
      <div style={{ justifyContent: 'center' }}>
        <div
          className={` flex flex-col gap-2 border-[0.1rem] mt-[0.8rem] p-[0.4rem] pl-[0.8rem] gap-[0.5rem] ${
            +[-1, 4].includes(+item?.status_id) && +item.sub_stage !== 3 && !item?.bulk_id
              ? 'rounded-t-[0.5rem]'
              : 'rounded-xl'
          } border-[#B6B6B6] p-2`}>
          {vendorInvoiceAmountNotSame(selectedRequest || item) ? null : (
            <>
              {ScheduleTime && ScheduleTime.length > 0 && (
                <div style={{ marginBottom: '0.3rem', borderBottom: '1px solid #B6B6B6' }}>
                  <ScheduleTime item={item} selectedRequest={selectedRequest} />
                </div>
              )}
            </>
          )}
          <div
            className='row-container text-primary flex flex-col gap-1'
            style={{ marginBottom: '0.3rem', borderBottom: '1px solid #B6B6B6' }}>
            <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
              Service Location
            </div>
            <div
              className='date-time text-[#515151] max-h-[3rem] overflow-auto'
              style={{ paddingBottom: '10px' }}>
              {ServiceLocation(item)}
            </div>
          </div>
          {vendorInvoiceAmountNotSame(selectedRequest || item) ? (
            <MismatchPaymentInformation selectedRequest={selectedRequest} item={item} user={user} />
          ) : (
            <>
              {!vendorInvoiceAmountNotSame(selectedRequest || item) ? (
                <VendorPaymentInfo
                  selectedRequest={selectedRequest}
                  item={item}
                  user={user}
                  refetch={refetch}
                />
              ) : (
                <div className='grid grid-cols-2 content-center w-full h-full'>
                  <div className='row-container flex flex-col gap-1 justify-center'>
                    <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
                      CSR
                    </div>
                    <div className='location-name'>{item?.csr?.name || user?.name}</div>
                  </div>
                  {!item?.bulk_id &&
                    +item?.status_id >= 4 &&
                    !(+item?.status_id === 10 && +item?.sub_stage === 1) && (
                      <AssignUnAssignPopUp item={{ ...item }} type='Vendor' />
                    )}
                  {(item?.id || item?.preventive_request_id) &&
                    !selectedRequest &&
                    +item?.status_id === -1 &&
                    !(+item?.status_id === 10 && +item?.sub_stage === 1) && (
                      <AssignUnAssignPopUp item={{ ...item }} type='Partner' />
                    )}
                  {(item?.id || item?.preventive_request_id) &&
                    +item?.status_id === 5 &&
                    !item?.sub_stage && (
                      <AssignUnAssignPopUp item={{ ...item }} type='Technician' />
                    )}
                </div>
              )}
            </>
          )}

          {isDisputedPage && (
            <div className='h-[3rem] flex justify-center items-center border-t-[0.1rem] border-t-[#B6B6B6] pt-3 text-[1rem]'>
              <i className='pi pi-exclamation-circle mr-2 font-bold text-[#C55] text-[1rem]'></i>
              Payment Disputed From Vendor
            </div>
          )}

          {item.status_id === '4' && item?.sub_stage === '1' && (
            <p
              className='p-2  rounded-lg text-sm font-bold text-center w-full'
              style={{
                background: 'rgba(200, 122, 30, 0.09',
                color: '#AD741E',
              }}>
              Awaiting Acceptance From Vendor
            </p>
          )}
          {item?.bulk_id ? (
            selectedRequest && (
              <div>
                <TrackLocation item={selectedRequest} refetch={refetch} />
              </div>
            )
          ) : (
            <div>
              <TrackLocation item={item} refetch={refetch} />
            </div>
          )}
        </div>
        {[-1, 4].includes(+item?.status_id) && +item.sub_stage !== 3 && !item?.bulk_id && (
          <div
            className='w-full flex bg-primary-light items-center p-2 px-3 rounded-b-xl cursor-pointer'
            onClick={() => {
              setShoweditModel(true);
            }}>
            <EditRequestIcon /> <p className='ml-2 text-[1.1rem] text-primary'>Edit Request</p>
          </div>
        )}
        {showEditModel && (
          <EditRequestModelBulk
            handleClose={() => {
              setShoweditModel(false);
            }}
            item={{
              ...item,
              id: item?.preventive_request_id || '',
              refetch,
              bulkSchedules: [{ ...item, id: item?.preventive_request_id || '', refetch }],
            }}
          />
        )}
      </div>
    )
  );
};
export default Scheduled;

export const UpdateConfirmationModal = ({
  handleClose,
  item,
  selectedRequest,
}: {
  handleClose: any;
  item: any;
  selectedRequest: any;
}) => {
  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Update Request'} onClose={handleClose} width={'w-[25rem]'}>
        <div className='w-full flex flex-col p-3 justify-center items-center gap-3'>
          <p className='w-full flex justify-center items-center text-[1.2rem]'>
            Do you want to update the payment of this request ?{' '}
          </p>
          <div className='flex justify-between gap-6'>
            <Button
              className='text-white bg-primary text-[1.1rem]'
              onClick={() =>
                item?.setInvoiceModal?.({
                  open: true,
                  data: {
                    ...(selectedRequest || item),
                    title: `Request-${
                      selectedRequest?.preventive_request_id || item.preventive_request_id
                    }`,
                    updateVendorInvoice: true,
                  },
                })
              }>
              Yes
            </Button>
            <Button className='bg-[#CC555514] text-[#C55] text-[1.1rem]' onClick={handleClose}>
              No
            </Button>
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
};
