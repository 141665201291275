import React from 'react';

function Heading({ title }: { title: string }) {
  return (
    <th scope='col' className='text-center  py-[0.5rem] text-[1.1rem]'>
      {title}
    </th>
  );
}

export default Heading;
