import { Tooltip } from '@mui/material';
import { useRef, useState } from 'react';

function Tootltip({ children, title, placement }: any) {
  const [style, setStyle] = useState({} as any);
  let firstChildRef: any = useRef(null);

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            ...style,
            bgcolor: 'white',
            color: 'black',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
            maxHeight: '80vh',
            maxWidth: '20vw',
            overflow: 'auto',
            fontFamily: 'inherit',
          },
        },
        arrow: {
          sx: {
            color: 'white',
          },
        },
      }}
      title={firstChildRef?.current?.innerText || title || 'N/A'}
      placement={placement || 'right'}
      onMouseOver={() => {
        setStyle(
          firstChildRef.current &&
            firstChildRef.current?.scrollWidth > firstChildRef.current?.clientWidth
            ? { display: 'block' }
            : { display: 'none' }
        );
      }}
      ref={(el: any) => (firstChildRef.current = el)}>
      {children}
    </Tooltip>
  );
}

export default Tootltip;
