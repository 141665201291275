import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/reducers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import useFetchInvoiceDetails from './Invoice/components/useFetchInvoiceDetails';
import moment from 'moment';

export function DisputeLists({ item }: { item: any }) {
  const dispatch = useDispatch();
  const { disputeIdHistory, fetchDisputeIdHistory } = useFetchInvoiceDetails();
  const { dispute }: any = useSelector((state: RootState) => state.userState);
  const { setDispute } = bindActionCreators(actionCreators, dispatch);
  const [selectedDispute, setSelectedDispute] = useState<any>([]);
  const handleBackClick = () => {
    setDispute({
      ...dispute,
      open: false,
    });
  };
  useEffect(() => {
    setSelectedDispute(dispute?.allData?.[dispute?.clickedIndex]);
  }, [dispute]);
  const previousButton = () => {
    return (
      <i
        className='pi pi-angle-left text-[1.5rem] cursor-pointer'
        onClick={() => {
          if (dispute?.clickedIndex !== 0) {
            setDispute({ ...dispute, clickedIndex: dispute?.clickedIndex - 1 });
          }
        }}></i>
    );
  };
  const nextButton = () => {
    return (
      <i
        className='pi pi-angle-right text-[1.5rem] cursor-pointer'
        onClick={() => {
          if (dispute?.clickedIndex !== dispute?.allData.length - 1)
            setDispute({ ...dispute, clickedIndex: dispute?.clickedIndex + 1 });
        }}></i>
    );
  };
  useEffect(() => {
    if (selectedDispute?.dispute_id) {
      fetchDisputeIdHistory(selectedDispute?.dispute_id);
    }
  }, [selectedDispute]);
  return (
    <div className='w-full h-full flex flex-col gap-3'>
      <Button className='w-fit font-bold hover:bg-primary-light' onClick={handleBackClick}>
        Back
      </Button>
      <div className='bg-primary-light flex justify-between text-primary font-bold items-center rounded-[0.5rem] p-2'>
        {previousButton()}
        <p className='w-fit flex items-center justify-center gap-2 text-[1.1rem]'>
          Dispute
          <p className='text-primary text-[1.1rem] font-bold'>
            # {dispute?.allData.length - dispute?.clickedIndex}
          </p>
        </p>
        {nextButton()}
      </div>
      <div className='w-[95%]  h-[30rem] pr-1 overflow-y-auto flex items-center justify-center ml-4'>
        <div className='flex flex-col w-full h-full overflow-y-auto border-[0.1rem] border-gray-300 rounded-[0.5rem] gap-3 p-3'>
          <div className='flex items-center flex-col'>
            <p className='font-bold flex w-full justify-between'>
              <p>DISPUTE BY</p>{' '}
              <p className='flex gap-2 items-center  text-[0.8rem] font-bold '>
                <p>{moment(selectedDispute?.created_at).format('l')}</p>
                <p>{moment(selectedDispute?.created_at).format('LT')}</p>
              </p>{' '}
            </p>
            <p className='w-full'>
              {selectedDispute?.notes?.[0]?.user_type === 'fleetmax_vendor' ? (
                <>{item?.vendor?.name}</>
              ) : (
                'You'
              )}
            </p>
          </div>
          <p className='bg-gray-200 py-4 px-2 font-bold rounded-[0.3rem]'> Description</p>
          <div className='flex flex-col w-full'>
            {disputeIdHistory?.length > 0 &&
              disputeIdHistory?.map((ele: any, index: number) => {
                return (
                  <>
                    <div
                      key={index}
                      className='p-1 py-3 border-b-[0.15rem] border-gray-400 border-dotted'>
                      <p className='font-bold'>{ele?.name}</p>
                      <div className='flex justify-between '>
                        <div className='flex flex-col w-[30%]'>
                          <p className=' text-gray-400'>Rate</p>
                          <p className='font-bold'>$ {ele?.csr_rate || ele?.csr_amount}</p>
                        </div>
                        <div className='flex flex-col w-[30%]'>
                          <p className=' text-gray-400'>Qty</p>
                          <p className='font-bold'> {ele?.csr_quantity}</p>
                        </div>
                        <div className='flex flex-col w-[30%]'>
                          <p className='text-gray-400'>Amount</p>
                          <p className='font-bold'>
                            $ {Number(ele?.csr_rate || ele?.csr_amount) * Number(ele?.csr_quantity)}
                          </p>
                        </div>
                      </div>
                      {ele?.disputed_amount ||
                        (ele?.disputed_rate && (
                          <div className='flex justify-between '>
                            <div className='flex flex-col w-[60%]'>
                              <p className='font-bold text-[#C55]'>DISPUTED RATE</p>
                            </div>
                            <div className='flex flex-col w-[30%]'>
                              <p className='font-bold text-[#C55]'>
                                $ {ele?.disputed_amount || ele?.disputed_rate}
                              </p>
                            </div>
                          </div>
                        ))}
                      {ele?.disputed_quantity && (
                        <div className='flex justify-between '>
                          <div className='flex flex-col w-[60%]'>
                            <p className='font-bold text-[#C55]'>DISPUTED QUANTITY</p>
                          </div>
                          <div className='flex flex-col w-[30%]'>
                            <p className='font-bold text-[#C55]'> {ele?.disputed_quantity}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            {disputeIdHistory?.length <= 0 && (
              <div className='flex justify-center items-center h-[4rem]'>
                <p className='w-fit h-fit'>No disputed services available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
