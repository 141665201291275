import { XIcon } from '@heroicons/react/solid';
import { useEffect, useMemo, useState } from 'react';
import OverlayContainer from '../../components/OverlayContainer';
import { ModalFormModel } from '../../models/ModalFormModel';
import DocumentIcon from '../../assets/DocumentIcon';
import PDFIcon from '../../assets/PDFIcon';
import ZoomInIcon from '../../assets/ZoomInIcon';
import ZoomOutIcon from '../../assets/ZoomOutIcon';
import EditIcon from '../../assets/EditIcon';
import { DeleteModel } from './DeleteWorkProofModel';
import CsrAPI from '../../api/csrApi';
import { toast } from 'react-toastify';
import HandleLoad from '../../components/Loader/HandleLoad';
import useFetchCheckList from '../hooks/useFetchCheckList';
import { Button, IconButton } from '@mui/material';
import { Environment } from '../../constants/environment';
import moment from 'moment';
import TimezoneExtractor from './getTimeZone';
const ImagePreview = ({ imgArr, handleClose, imagesDataObj, requestInfo, notEditable }: any) => {
  const data = imagesDataObj?.data;
  const selected_id = imagesDataObj?.selected_id;
  const loader = HandleLoad();
  const { getCheckList } = useFetchCheckList();
  const showBottomBar = imagesDataObj || false;
  const [currentImgIndex, setCurrentImgIndex] = useState<number>(0);
  const [openDeleteModel, setOpenDeleteModel] = useState<any>({ open: false, data: [] });
  const [editName, setEditName] = useState<any>({ show: false });
  const [editedName, setEditedName] = useState('');
  const [selectedImageData, setSelectedImageData] = useState<any>(null);
  const [img, setImg] = useState<string>('');
  const [zoomLevel, setZoomLevel] = useState<number>(1);

  useEffect(() => {
    const imgArr = imagesDataObj?.data;
    if (selected_id && imgArr && imgArr.length > 0) {
      const index = imgArr.findIndex((d: any) => +selected_id === +d?.proof_id);
      handleButtonClick(index);
    }
  }, [selected_id]);

  useEffect(() => {
    const imgArr = imagesDataObj?.data;
    if (imgArr && imgArr.length > 0 && selectedImageData) {
      const index = imgArr.findIndex((d: any) => +selectedImageData?.proof_id === +d?.proof_id);
      if (index >= 0) {
        handleButtonClick(index);
      } else {
        if (currentImgIndex > 0) {
          handleButtonClick(currentImgIndex - 1);
        } else {
          handleButtonClick(0);
        }
      }
    }
  }, [imagesDataObj]);

  // select image
  const handleButtonClick = (index: number) => {
    const imgArr = imagesDataObj?.data;
    if (imgArr && imgArr?.length > 0) {
      setCurrentImgIndex(index);
      setSelectedImageData(imgArr[index]);
    }
  };

  useEffect(() => {
    if (imgArr) {
      setImg(imgArr[0]);
    }
  }, [imgArr]);

  const defaultName = useMemo(() => {
    const match = selectedImageData?.file_url?.match(/\/checklist_proof\/(.+)$/);
    const urlName = match ? String(match[1]) : '';
    setEditedName(selectedImageData?.name || selectedImageData?.proof_name || urlName);
    return selectedImageData?.name || selectedImageData?.proof_name || urlName;
  }, [selectedImageData]);

  const handlePrevious = () => {
    const newIndex = (currentImgIndex - 1 + data.length) % data.length;
    setCurrentImgIndex(newIndex);
    handleButtonClick(newIndex);
  };
  const handleNext = () => {
    const newIndex = (currentImgIndex + 1) % data.length;
    setCurrentImgIndex(newIndex);
    handleButtonClick(newIndex);
  };

  const getUrl = (url: any) => {
    if (!url) {
      return;
    }
    const x = url.split('/');
    const imagename = x[x.length - 1];
    const imageBuck = x[x.length - 2];
    return `${Environment.baseUrl}inspections/v1/download_image?name=${imageBuck}/${imagename}`;
  };
  function fetchImage(url: any) {
    loader(true);
    const nameExtension = defaultName.split('.').pop();
    const urlExtension = url.split('.').pop();
    const fileExtension = `.${urlExtension}`;
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${defaultName}${nameExtension !== urlExtension ? fileExtension : ''}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        loader(false);
      });
  }

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };
  const handleZoomOut = () => {
    setZoomLevel(Math.max(0.1, zoomLevel - 0.1));
  };
  const handleSave = () => {
    loader(true);
    const proofId = selectedImageData?.proof_id || null;
    const formattedData = {
      id: proofId,
      payload: {
        name: editedName,
      },
    };
    CsrAPI.editProof(formattedData).then((res) => {
      if (res.status === 200) {
        toast.success(`Name Updated successfully`);
        getCheckList(requestInfo.item, true);
      } else {
        toast.error('something went wrong');
      }
      loader(false);
      setEditName({ open: false, editedname: '' });
    });
  };
  const zoomStyle = {
    transform: `scale(${zoomLevel})`,
  };
  const isVideo = (selectedImageData ? selectedImageData.file_url : img)?.match(
    /\.(mp4|webm|ogg|3gp|3gpp)$/
  );
  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-2/3 bg-neutral-200'} noLabel>
        <>
          <div className='flex flex-col items-center gap-3 justify-center h-full p-7'>
            {showBottomBar && (
              <div className='flex flex-col justify-start w-full'>
                <div className='flex w-full gap-3 items-center mt-[-1rem]'>
                  {editName.show ? (
                    <div className='flex items-center gap-4'>
                      <input
                        className='w-[39rem] h-[2rem] rounded-[0.5rem] p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                        value={editedName}
                        style={{ resize: 'none' }}
                        onChange={(e) => {
                          setEditedName(e.target.value);
                        }}
                      />
                      <Button
                        disabled={editedName.length < 1 || editedName.length > 35}
                        className='text-primary font-bold p-1 cursor-pointer rounded-xl text-white'
                        variant='contained'
                        onClick={handleSave}>
                        Save
                      </Button>
                      <Button
                        className='bg-[#C55] text-white text-rose-300  cursor-pointer p-1 rounded-xl font-bold'
                        onClick={(e) => {
                          setEditName({ open: false, editedname: '' });
                        }}>
                        Cancel
                      </Button>
                      {editedName.length > 35 && (
                        <p className='flex text-[0.8rem] text-rose-500 items-center border-[0.1rem] border-rose-500 p-1 rounded-xl'>
                          <i className='pi pi-info-circle text-[0.9rem] mr-1 text-rose-500'></i>
                          <p>35 charecters allowed</p>
                        </p>
                      )}
                    </div>
                  ) : (
                    <>
                      <p className='text-primary text-[1.2rem]'>{defaultName}</p>
                      {!notEditable && (
                        <div className='flex gap-1 items-center'>
                          <p
                            className='cursor-pointer'
                            onClick={() => {
                              setEditName({ show: true, editedname: '' });
                            }}>
                            <IconButton aria-label='delete' size='small'>
                              <EditIcon height='1.2rem' width='1.2rem' />
                            </IconButton>
                          </p>
                          <IconButton aria-label='delete' size='small'>
                            <i
                              className='pi pi-trash text-rose-300 text-[1.2rem] cursor-pointer'
                              onClick={() =>
                                setOpenDeleteModel({ open: true, data: selectedImageData })
                              }></i>
                          </IconButton>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {!editName.show && (
                  <p className='flex gap-2 whitespace-nowrap items-center font-bold'>
                    Uploaded {moment(selectedImageData?.created_at).format('MM/DD/YYYY')}{' '}
                    <i className='pi pi-circle-fill text-[0.3rem]'></i>{' '}
                    {moment(selectedImageData?.created_at).format('h:mm A')}
                    <TimezoneExtractor date={selectedImageData?.created_at} />
                  </p>
                )}
              </div>
            )}
            <div className='w-full h-[32rem] overflow-hidden flex items-center justify-center'>
              {isVideo ? (
                <video controls className='w-full h-full'>
                  <source src={selectedImageData?.file_url} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={selectedImageData ? selectedImageData?.file_url : img}
                  alt={img}
                  className='h-full object-contain w-full '
                  style={zoomStyle}
                />
              )}
            </div>
            {showBottomBar ? (
              <>
                <div className='w-full bg-[#010101B8] rounded-xl h-[3rem] flex justify-between items-center p-3'>
                  <IconButton aria-label='delete' size='small'>
                    <div
                      className='text-white h-full flex items-center cursor-pointer'
                      onClick={() => fetchImage(getUrl(selectedImageData?.file_url))}>
                      <i className='pi pi-download text-[1.5rem]'></i>
                    </div>
                  </IconButton>
                  {data?.length > 1 && (
                    <div className='flex gap-20 h-full items-center text-white'>
                      <Button
                        className='cursor-pointer text-white  p-1 px-3 text-[1.5rem] disabled:text-gray-400'
                        disabled={currentImgIndex + 1 === 1}
                        onClick={handlePrevious}>
                        {'<'}
                      </Button>
                      <p className='text-[0.9rem]'>
                        {currentImgIndex + 1} of {data?.length}
                      </p>
                      <Button
                        className='cursor-pointer text-white p-1 px-3 text-[1.5rem] disabled:text-gray-400'
                        disabled={currentImgIndex + 1 === data?.length}
                        onClick={handleNext}>
                        {'>'}
                      </Button>
                    </div>
                  )}
                  <div className='flex text-white items-center z-20'>
                    <p className='cursor-pointer' onClick={handleZoomIn}>
                      <IconButton aria-label='delete' size='small'>
                        <ZoomInIcon height='1.5rem' width='1.5rem' />
                      </IconButton>
                    </p>
                    <p className='ml-2 cursor-pointer' onClick={handleZoomOut}>
                      <IconButton aria-label='delete' size='small'>
                        <ZoomOutIcon height='1.5rem' width='1.5rem' />
                      </IconButton>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='flex gap-2 overflow-x-auto border border-violet-400'>
                  {imgArr.length > 0 &&
                    imgArr.map((elem: any) => (
                      <img
                        key={elem}
                        src={elem}
                        alt={elem}
                        className='w-[3rem] h-[3rem] border border-slate-400'
                        onClick={() => setSelectedImageData(elem)}
                      />
                    ))}
                </div>
              </>
            )}
          </div>
          {openDeleteModel.open && (
            <DeleteModel
              handleClose={() => setOpenDeleteModel({ open: false, data: [] })}
              data={openDeleteModel.data}
              handleCloseImageModel={handleClose}
              item={requestInfo.item}
            />
          )}
        </>
      </ModalForm>
    </OverlayContainer>
  );
};
export const ImagePDFPreview = ({ imgArr, handleClose }: any) => {
  const [img, setImg] = useState<string>(imgArr[0]);
  const ifNotPDF = () => {
    if (img?.match(/\.(png|jpeg|jpg|svg)$/)) {
      return <img key={img} src={img} alt={img} className='h-full object-contain w-full' />;
    } else if (img?.match(/\.(mp4|webm|ogg)$/)) {
      return (
        <div className='w-full h-[37rem] flex items-center justify-center'>
          <video key={img} controls className='w-full h-full'>
            <source src={img} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else {
      return (
        <div>
          <DocumentIcon height='7rem' width='7rem' />
          <a href={img} target='_blank' rel='noreferrer'>
            <p className='truncate text-ellipsis text-primary font-bold mt-4 flex justify-center items-center'>
              Open File
            </p>
          </a>
        </div>
      );
    }
  };

  const ifNotPDFIcon = (elem?: any) => {
    const isMatch = elem === img;
    if (elem?.match(/\.(png|jpeg|jpg|svg)$/)) {
      return (
        <img
          key={elem}
          src={elem}
          alt={elem}
          className={`w-[3rem] h-[3rem] ${
            isMatch ? 'border-blue-500 border-[0.2rem]' : 'border-slate-400'
          }`}
        />
      );
    } else if (elem?.match(/\.(mp4|webm|ogg)$/)) {
      const isMatch = elem === img;
      return (
        <div
          className={`w-[3rem] h-[3rem] flex justify-center items-center border ${
            isMatch ? 'border-blue-500 border-[0.2rem]' : 'border-slate-400'
          }`}>
          <video key={elem} controls={false} className='w-8 h-8'>
            <source src={elem} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else {
      const isMatch = elem === img;
      return (
        <div
          className={`w-[3rem] h-[3rem] flex justify-center items-center border border-slate-400 ${
            isMatch ? 'border-blue-500 border-[0.2rem]' : 'border-slate-400'
          }`}>
          <p className='truncate text-ellipsis font-bold flex items-center text-primary'>
            <DocumentIcon height='2rem' width='2rem' />
          </p>
        </div>
      );
    }
  };

  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-2/3 bg-neutral-200'} noLabel>
        <div className='flex flex-col items-center gap-3 justify-center h-full p-7'>
          <div className='w-full h-[37rem] flex items-center justify-center'>
            {img?.endsWith('.pdf') ? (
              <iframe title='pdf' src={img} className='w-full h-full'></iframe>
            ) : (
              <>{ifNotPDF()}</>
            )}
          </div>
          <div className='flex gap-2 overflow-x-auto border border-violet-400'>
            {imgArr.length > 0 &&
              imgArr.map((elem: any) => (
                <div
                  key={elem}
                  onClick={() => {
                    setImg(elem);
                  }}>
                  {elem?.endsWith('.pdf') ? (
                    <div
                      className={`w-[3rem] h-[3rem] flex justify-center items-center  ${
                        elem === img ? 'border-blue-500 border-[0.2rem]' : 'border-slate-400'
                      }`}>
                      <p className='truncate text-ellipsis font-bold flex items-center text-primary'>
                        <PDFIcon height='2rem' width='2rem' />
                      </p>
                    </div>
                  ) : (
                    <>{ifNotPDFIcon(elem)}</>
                  )}
                </div>
              ))}
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
};
const ModalForm = ({ children, title, onClose, width, noLabel, nothing }: ModalFormModel) => {
  return (
    <div className='h-full w-full flex items-center justify-center overlayDiv'>
      <div className={`rounded-xl ${width ? width : 'w-[470px] overlayClass'} h-auto`}>
        <CrossComp {...{ children, title, onClose, width, noLabel, nothing }} />
      </div>
    </div>
  );
};

const CrossComp = ({
  nothing,
  noLabel,
  children,
  onClose,
  title,
  width,
}: {
  nothing?: boolean;
  noLabel?: boolean;
  children: JSX.Element;
  onClose: () => void;
  title?: string;
  width: any;
}) => {
  if (nothing) return <div className='px-6 py-5 h-auto'>{children}</div>;

  if (noLabel) {
    return (
      <>
        <div className='text-primary-2 text-md  flex items-center justify-between px-6 py-5 relative'>
          <div
            onClick={onClose}
            className='rounded-full p-2 cursor-pointer bg-gray-300 hover:bg-gray-200 absolute right-3 top-3'>
            <XIcon className='h-6 w-6 text-rose-500' />
          </div>
          <div className='w-full'>{children}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='text-primary-2 text-md  flex items-center justify-between px-6 py-5 relative'>
        <h1 className='text-center flex-1 text-xl'>{title}</h1>
        <div
          onClick={onClose}
          className='rounded-full p-2 cursor-pointer hover:bg-primary-light absolute right-3 top-3'>
          <XIcon className='h-6 w-6' />
        </div>
      </div>
      <div className='pt-0'>{children}</div>
    </>
  );
};

export default ImagePreview;
