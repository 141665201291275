import React, { useEffect } from 'react';
import { IFile } from '../../../../../models/UserModel';
import { IconButton, Link } from '@mui/material';
import Tootltip from '../../../../../components/BarData/Tootltip';

const FileIcon = ({ file_type }: { file_type: string }) => {
  switch (file_type) {
    case 'application/pdf':
      return <i className='pi pi-file-pdf text-red-700 text-lg'></i>;
    default:
      return <i className='pi pi-file text-blue-700 text-lg'></i>;
  }
};
const File = ({ file }: { file: IFile }) => {
  return (
    <Link href={file.url} target={'_blank'} className='no-underline'>
      <div className='flex gap-4 items-center'>
        <FileIcon file_type={file.file_type} />
        <Tootltip title={file.name} placement={'bottom'}>
          <p className='text-black w-[10rem] text-ellipsis overflow-hidden whitespace-nowrap text-[0.8rem]'>
            {file.name}
          </p>
        </Tootltip>
      </div>
    </Link>
  );
};
function FileList({ attachments }: { attachments: IFile[] }) {
  const [mapAttachments, setMapAttachments] = React.useState([] as IFile[]);
  useEffect(() => {
    if (attachments && attachments?.length > 2) {
      setMapAttachments(attachments?.slice(0, 2));
      return;
    }
    setMapAttachments(attachments);
  }, [attachments]);

  return (
    <div className='flex gap-2 items-center'>
      <div className='grid grid-cols-2 gap-2 w-full'>
        {mapAttachments?.map((d: IFile) => (
          <File key={d.url} file={d} />
        ))}
      </div>
      {attachments.length > 2 && mapAttachments.length !== attachments.length && (
        <IconButton
          size={'small'}
          className='w-[1.9rem] h-[1.9rem] bg-slate-200 text-[0.6rem] font-bold'
          onClick={() => {
            setMapAttachments(attachments);
          }}>
          <i className='pi pi-plus text-[0.6rem] font-bold'></i>
          <p className='text-[1rem]'>{attachments.length - 2}</p>
        </IconButton>
      )}
    </div>
  );
}

export default FileList;
