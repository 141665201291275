import { Tooltip } from '@mui/material';
import usePartnerAsVendor from '../../pages/hooks/usePartnerAsVendor';
const SVG = (props: { height?: string; width?: string; active?: boolean }) => {
  const height = props?.height || '1.8rem';
  const width = props?.width || '1.8rem';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <mask id='mask0_14970_19646' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'>
        <rect x='0.960938' y='0.980469' width='19' height='19' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_14970_19646)'>
        <path
          d='M8.20469 13.8451L10.4609 11.5888L12.7172 13.8451L13.8255 12.7367L11.5693 10.4805L13.8255 8.22422L12.7172 7.11589L10.4609 9.37214L8.20469 7.11589L7.09635 8.22422L9.3526 10.4805L7.09635 12.7367L8.20469 13.8451ZM4.91927 17.6055C4.48385 17.6055 4.11111 17.4504 3.80104 17.1404C3.49097 16.8303 3.33594 16.4576 3.33594 16.0221V4.9388C3.33594 4.50339 3.49097 4.13064 3.80104 3.82057C4.11111 3.5105 4.48385 3.35547 4.91927 3.35547H16.0026C16.438 3.35547 16.8108 3.5105 17.1208 3.82057C17.4309 4.13064 17.5859 4.50339 17.5859 4.9388V11.5492C17.3352 11.4569 17.078 11.3876 16.8141 11.3414C16.5502 11.2952 16.2797 11.2721 16.0026 11.2721V4.9388H4.91927V16.0221H11.2526C11.2526 16.2992 11.2757 16.5697 11.3219 16.8336C11.3681 17.0975 11.4373 17.3548 11.5297 17.6055H4.91927ZM16.0026 19.1888L14.8943 18.0805L16.1411 16.8138H12.8359V15.2305H16.1411L14.8943 13.9638L16.0026 12.8555L19.1693 16.0221L16.0026 19.1888Z'
          fill='#CC5555'
        />
      </g>
    </svg>
  );
};
function Logout() {
  const { handleLogOut, partnerAccessingVendorProfile } = usePartnerAsVendor();
  return (
    <>
      {partnerAccessingVendorProfile ? (
        <Tooltip title={'Exit Vendor'}>
          <div
            className=' p-[0.8rem] rounded-xl flex items-center cursor-pointer h-[inherit] '
            onClick={handleLogOut}>
            <SVG />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={'Logout'}>
          <div
            className='bg-primary-lighter p-[0.8rem] rounded-xl flex items-center cursor-pointer h-[inherit] '
            onClick={handleLogOut}>
            <i className='pi pi-sign-out text-primary font-bold'></i>
          </div>
        </Tooltip>
      )}
    </>
  );
}

export default Logout;
