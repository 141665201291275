import { useDispatch, useSelector } from 'react-redux';
import HandleLoad from '../../../../../components/Loader/HandleLoad';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { RootState } from '../../../../../state/reducers';
import { useEffect, useState } from 'react';
import { DummyDataInvoiceItem } from '../../../types';
import moment from 'moment';
import VendorAPI from '../../../../../api/vendorApi';
import { toast } from '../../../../../components/Toast/CustomToast';
import PreventiveAPI from '../../../../../api/preventiveApi';
import { EditFields } from './EditFields';
import { Button, Tooltip } from '@mui/material';
import FileAttach from './FileAttach';
import FileList from './FileList';
import CustomConfirm from '../../../../../components/PopOver/CustomDelete';

export function EditableServices({
  item,
  handleClose,
  fetchRequestData,
  invoiceDetails,
}: {
  item: any;
  handleClose: any;
  fetchRequestData: any;
  invoiceDetails: any;
}) {
  const handleLoad = HandleLoad();
  const dispatch = useDispatch();
  const { setDispute, setAttachments } = bindActionCreators(actionCreators, dispatch);
  const { attachments }: any = useSelector((state: RootState) => state.userState);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [services, setServices] = useState<DummyDataInvoiceItem[]>([]);
  const { disputeHistory } = invoiceDetails;
  const [editState, setEditState] = useState<{
    showEdit: boolean;
    vendorPrices: {
      service: string;
      rate: string | null;
      quantity?: string;
      tax?: boolean;
    }[];
  }>({
    showEdit: false,
    vendorPrices: [],
  });
  const invoiceDate = moment(invoiceDetails.invoice_date);
  const currentDate = moment();
  const isMorethan30Days = currentDate.diff(invoiceDate, 'days') > 30;
  const totalAmount = services?.reduce(
    (total: any, data: any) => total + parseFloat(data?.rate || data?.price || 0),
    0
  );
  const editedTotalAmount = editState.vendorPrices.reduce(
    (total: number, item: any) => total + (parseFloat(item.rate) || 0) * (item.quantity || 0),
    0
  );
  const handleOnClick = (index: number) => {
    setDispute({ open: true, allData: [...disputeHistory], clickedIndex: index });
  };
  const setInitialPrice = () => {
    const vendorPrices = services?.map((item: any) => ({
      service: item.service,
      ...(item?.id ? { id: item.id } : { custom: true }),
      ...(item?.quantity && { quantity: item?.quantity }),
      ...(item?.is_taxable ? { tax: true } : { tax: false }),
      rate: item?.rate || item?.price || 0,
    }));
    setEditState({
      showEdit: false,
      vendorPrices,
    });
  };
  const handleEditClick = () => {
    const vendorPrices = services?.map((item: any) => ({
      service: item.service,
      ...(item?.id ? { id: item.id } : { custom: true }),
      ...(item?.quantity && { quantity: item?.quantity }),
      ...(item?.is_taxable ? { tax: true } : { tax: false }),
      rate: item?.rate || item?.price || 0,
    }));
    setEditState({
      showEdit: true,
      vendorPrices,
    });
  };
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: string
  ) => {
    const newValue = event.target.value;
    const isValidInput = /^(\d*\.?\d{0,2}|\.\d{1,2})$/.test(newValue);
    if (newValue === '' || isValidInput) {
      setEditState((prevState) => ({
        ...prevState,
        vendorPrices: prevState.vendorPrices.map((item, i) =>
          i === index ? { ...item, [field]: newValue !== '' ? newValue : null } : item
        ),
      }));
    }
  };

  useEffect(() => {
    if (invoiceDetails?.invoice?.additional_info?.length > 0) {
      setServices(
        invoiceDetails?.invoice?.additional_info?.map((d: any) => ({
          ...d,
          vendor_price: undefined,
          rate: d?.rate || d?.price || 0,
        }))
      );
    }
  }, [invoiceDetails, disputeHistory]);
  useEffect(() => {
    setInitialPrice();
  }, []);
  const createDispute = () => {
    handleLoad(true);
    const payloadforDispute = {
      invoice_id: Number(item?.invoice?.id) || 0,
      note: textFieldValue?.trim() || '',
      ...(attachments?.length > 0 && { attachments }),
    };
    let arr: any[] = [];

    services?.filter((service: any) => {
      const correspondingEditStateService: any = editState?.vendorPrices.find(
        (editService: any) => editService.id === service.id
      );
      let obj: any = {};
      const condition =
        (correspondingEditStateService &&
          Number(correspondingEditStateService?.rate) !== Number(service?.rate)) ||
        Number(correspondingEditStateService?.quantity) !== Number(service?.quantity);

      if (condition) {
        obj.id = String(service.id);

        if (Number(correspondingEditStateService?.rate) !== Number(service?.rate)) {
          obj.rate = Number(correspondingEditStateService?.rate || 0);
        }

        if (Number(correspondingEditStateService?.quantity) !== Number(service?.quantity)) {
          obj.quantity = Number(correspondingEditStateService?.quantity || 0);
        }
      }
      if (obj.id) {
        arr.push(obj);
      }
    });
    VendorAPI.createDispute({
      ...payloadforDispute,
      ...(arr?.length > 0 && { services: arr }),
    }).then((res) => {
      if (res.status === 200) {
        toast.success('Dispute Created');
        fetchRequestData();
        setEditState({ ...editState, showEdit: false });
        setTextFieldValue('');
        handleClose();
      } else {
      }
      handleLoad(false);
    });
  };
  const settleDispute = () => {
    handleLoad(true);
    const payload = {
      preventive_request_id: item?.preventive_request_id,
      final_amount: Number(invoiceDetails?.invoice?.total_amount),
    };
    VendorAPI.settleDispute(payload).then((res) => {
      if (res.status === 200) {
        toast.success('Dispute Settled');
        fetchRequestData();
        handleClose();
      } else {
      }
      handleLoad(false);
    });
  };

  const confirmPayment = () => {
    handleLoad(true);
    const payload = {
      preventive_request_id: item?.preventive_request_id,
      final_amount: Number(invoiceDetails?.invoice?.total_amount),
    };
    PreventiveAPI.addPaymentInfo(payload).then((res) => {
      if (res.status === 200) {
        toast.success('Payment Confirmed ');
        fetchRequestData();
        handleClose();
      } else {
      }
      handleLoad(false);
    });
  };
  const defaultHistory = (ele: any, parentIndex: any) => {
    return (
      <div className='py-3 px-2 flex flex-col gap-1 '>
        <p className='w-full flex justify-between'>
          <p className='flex gap-1 items-center font-bold text-primary text-[0.9rem]'>
            <p className='text-gray-400 text-[0.9rem] font-bold bg-gray-100 rounded-[0.5rem] px-1'>
              # {parentIndex + 1}
            </p>
            <p className='font-bold text-primary text-[0.9rem]'>Vendor</p>{' '}
          </p>
          <p className='text-gray-400 text-[0.8rem] font-bold '>
            {moment(ele?.created_at).format('l')} {moment(ele?.created_at).format('LT')}
          </p>
        </p>
        <p className='w-full flex justify-between'>
          <p className='font-bold font-bold text-gray-500'>N/A</p>{' '}
        </p>
      </div>
    );
  };
  const anchorOrigin = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  };
  const handleCancel = () => {
    setInitialPrice();
    setTextFieldValue('');
    setAttachments([]);
  };
  const history = () => {
    return (
      <>
        {disputeHistory?.map((ele: any, parentIndex: number) => {
          return (
            <div
              key={parentIndex}
              className='flex flex-col gap-2 border-gray-200 rounded-[0.8rem] border-[0.11rem] p-2 divide-y-2'>
              {ele?.dispute_id ? (
                <>
                  <div className='flex justify-between items-center'>
                    <p className='text-gray-400 text-[1.1rem] font-bold rounded-[0.5rem] px-1'>
                      # {disputeHistory?.length - parentIndex}
                    </p>
                    <p
                      className='text-[0.9rem] text-primary font-bold cursor-pointer'
                      onClick={() => {
                        handleOnClick(parentIndex);
                      }}>
                      Details
                    </p>
                  </div>
                  {ele?.notes?.length > 0 ? (
                    <>
                      {ele?.notes?.map((child: any, index: number) => {
                        return (
                          <div key={index} className='py-3 px-2 flex flex-col gap-1 '>
                            <p className='w-full flex justify-between'>
                              <p className='flex gap-1 items-center font-bold text-primary text-[0.9rem]'>
                                <p className='font-bold text-primary text-[0.9rem]'>
                                  {['fleetmax_vendor', 'fleetmax_partner'].includes(child.user_type)
                                    ? 'You'
                                    : 'CSR'}
                                </p>
                              </p>
                              <p className='flex gap-2 items-center text-gray-400 text-[0.8rem] font-bold '>
                                <p>{moment(child?.created_at).format('l')}</p>
                                <p>{moment(child?.created_at).format('LT')}</p>
                              </p>
                            </p>
                            <p className='w-full flex justify-between'>
                              <p className='font-bold font-bold text-gray-500'>{child.note}</p>
                            </p>
                            {child.attachments && <FileList attachments={child.attachments} />}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>{defaultHistory(ele, parentIndex)}</>
                  )}
                </>
              ) : (
                <p>No History Available</p>
              )}
            </div>
          );
        })}
      </>
    );
  };
  return (
    <div className='flex flex-col justify-between w-full h-full mb-2'>
      <div
        className={`w-full flex flex-col ${editState.showEdit ? 'max-h-[67%]' : 'h-full'} gap-3`}>
        <div className={`w-full ${editState.showEdit ? 'max-h-[67%]' : 'h-full'} flex flex-col`}>
          <div
            className={`w-full ${
              editState.showEdit ? 'h-[15rem]' : 'h-[15rem] '
            } overflow-auto  rounded-xl border-[0.15rem] border-gray-300`}>
            <EditFields
              editState={editState}
              services={services}
              handleInputChange={handleInputChange}
              editedTotalAmount={editedTotalAmount}
              totalAmount={totalAmount}
              invoiceDetails={invoiceDetails}
            />
          </div>
          {!editState.showEdit && (
            <div className='w-full flex justify-between mt-2'>
              <Button
                className={`p-2 rounded-lg border-none  hover:bg-primary-light disabled:bg-gray-200 font-bold ${
                  showHistory && 'bg-primary-light'
                }`}
                disabled={disputeHistory?.length === 0}
                onClick={() => {
                  setShowHistory(!showHistory);
                }}>
                Dispute History
              </Button>
              <Tooltip
                placement='bottom'
                title={
                  isMorethan30Days &&
                  'Disputes cannot be initiated after 30 days from the date of receiving the invoice'
                }>
                <div className='w-fit z-20'>
                  <Button
                    className='p-2  rounded-lg border-none disabeled:text-gray-500 disabled:bg-gray-200 font-bold'
                    disabled={isMorethan30Days || ['raised'].includes(item?.vendor_invoice_dispute)}
                    onClick={handleEditClick}>
                    Create Dispute
                  </Button>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        <div className={``}>
          {editState.showEdit ? (
            <>
              <div className='w-full border-[0.1rem]  border-gray-300 rounded-[0.5rem]'>
                <textarea
                  style={{ resize: 'none' }}
                  className='w-full h-[6rem] rounded-[1rem] p-3 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                  value={textFieldValue || ''}
                  onChange={(e: any) => {
                    setTextFieldValue(e.target.value);
                    e.preventDefault();
                  }}
                  placeholder='Add Notes'
                />
              </div>
            </>
          ) : (
            <>
              {!editState.showEdit && (
                <>
                  <div className='h-[17rem] w-full overflow-auto flex flex-col gap-2 overflow-auto pt-2'>
                    {showHistory && <>{history()}</>}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {editState.showEdit && (
        <div className='flex w-full flex-col justify-center h-[45%] gap-2 '>
          <div className='flex justify-between w-[93%]'>
            <p className='w-[10%]'></p>
            <FileAttach />
          </div>
          <div className='w-full flex justify-between items-center h-fit mt-2'>
            <p className='w-[10%]'></p>
            <CustomConfirm
              {...{ anchorOrigin }}
              comp={
                <Button
                  variant='contained'
                  className='w-[90%] font-bold h-[2.5rem]'
                  disabled={
                    !textFieldValue?.trim() || ['raised'].includes(item?.vendor_invoice_dispute)
                  }>
                  Create Dispute
                </Button>
              }
              title={`Create Dispute`}
              info={`Do you want to create dispute on this request ? `}
              accept={createDispute}
              acceptText={'Yes, Create'}
              disable={!textFieldValue?.trim() || ['raised'].includes(item?.vendor_invoice_dispute)}
            />
          </div>
          <div className='w-full flex justify-center'>
            <Button
              className='p-2  rounded-lg border-none disabeled:text-gray-500 disabled:bg-gray-200 text-[#C55] font-bold w-[83%] hover:bg-gray-100 h-[2.5rem]'
              onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {!editState?.showEdit && (
        <>
          {item?.vendor_invoice_dispute ? (
            <div className='flex justify-center'>
              <p className='w-[15%]'></p>
              <CustomConfirm
                {...{ anchorOrigin }}
                comp={
                  <Button variant='contained' className='w-[83%] h-[2.5rem] font-bold mt-2'>
                    Settle Dispute
                  </Button>
                }
                title={`Settle Dispute`}
                info={`Do you want to settle dispute on this request? `}
                accept={settleDispute}
                acceptText={'Yes, Settle'}
              />
            </div>
          ) : (
            <div className='flex justify-center'>
              <p className='w-[25%]'></p>
              <CustomConfirm
                {...{ anchorOrigin }}
                comp={
                  <Button variant='contained' className='w-[83%] h-[2.5rem] font-bold mt-2'>
                    Confirm Payment
                  </Button>
                }
                title={`Confirm Payment`}
                info={`Do you want to confirm payment on this request ? `}
                accept={confirmPayment}
                acceptText={'Yes, Confirm'}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
