import { Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import UserDetail from '../../../../components/BarData/UserDetail';
import ServiceExpandButton from '../../../common/ServiceListExpand';
import AcceptRejectBtn from '../../../vender/PrimaryComponents/requestComponents/AcceptRejectBtn';
import RejectBox from '../../../vender/PrimaryComponents/requestComponents/RejectBox';
import InfoUp from '../../../vender/PrimaryComponents/requestComponents/vehicle/Info';
import MilesBox from '../../../vender/PrimaryComponents/requestComponents/vehicle/LastPreventive';
import SchduledInfo from '../../../vender/PrimaryComponents/requestComponents/vehicle/Scheduled';
import StatusTrackerPopButton from '../../../vender/PrimaryComponents/requestComponents/vehicle/StatusTrackerPopUp';
import InfoDown from '../../../vender/PrimaryComponents/requestComponents/vehicle/VanType';
import BulkTechnician from '../../../vender/components/request/BulkAssignTechnician';
import GetVendorActions from './Actions';
import { getRequestType } from './GetRequestType';
import ServiceLocation from '../../primaryComponents/ServiceLocation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import BtnPopUp from '../../../../pages/csr/bulkInfoModal/components/BtnPopUp';
import RedoSvg from '../../../../assets/Redo';
import AssignTechModel from '../../components/schedule/components/AssignTechModel';
import AssignVendorModal from '../../components/schedule/AssignVendorModal';
import AssignUnAssignPopUp from '../../../vender/PrimaryComponents/AssignUnAssignPopUp';
import useFetchSingleRequest from '../../../hooks/vendor/useFetchSingleRequest';
import VehicleQuestionnaire from '../../inspection/vehicleQuestionnaire/Index';
import useFetchVehicleChecklist from '../../../hooks/useFetchVehicleChecklist';
import PriorityFeild from '../../primaryComponents/PriorityFeild';
import RequestPart from '../../primaryComponents/RequestPart';

const Part2 = ({ requestInfo, selectedRequest }: any) => {
  const { handleAssignVednor }: any = useFetchSingleRequest();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [technicianModel, setTechnicianModel] = useState({ open: false, data: {} });
  const [rejecting, setRejecting] = useState(false);
  const [assignBulkTechnicianModal, setBulkAssignTechnicianModal] = useState({
    open: false,
    data: {},
  });
  const [showgetAction, setShowGetAction] = useState(false);
  const { vehicleChecklist, getVehicleCheckList } = useFetchVehicleChecklist();
  const type: string | undefined = getRequestType(selectedRequest || requestInfo);
  useEffect(() => {
    if (selectedRequest) {
      getVehicleCheckList(selectedRequest);
    }
  }, [selectedRequest?.id]);
  const updatedItem = useMemo(
    () => ({
      ...requestInfo,
      rejecting,
      setRejecting,
      fetchRequestData: requestInfo.refetch,
      technicianModel,
      setTechnicianModel,
    }),
    [requestInfo]
  );
  const updatedSelectedRequest = useMemo(
    () => ({
      ...selectedRequest,
      ...vehicleChecklist,
      rejecting,
      setRejecting,
      technicianModel,
      setTechnicianModel,
    }),
    [selectedRequest, vehicleChecklist]
  );
  const showButtons: boolean =
    type === 'requests' &&
    ((+requestInfo.status_id === 4 && +requestInfo.sub_stage === 1) ||
      (updatedItem.status_id === '-1' && updatedItem.sub_stage === '1')) &&
    ['fleetmax_vendor', 'fleetmax_partner'].includes?.(user?.role) &&
    !rejecting;
  return (
    <div className='flex flex-col justify-start flex-1 overflow-auto mt-7 pr-2 gap-2'>
      <div className='flex justify-between w-full items-center '>
        <div className='w-fit flex gap-2 items-center'>
          {selectedRequest?.dsp?.name && (
            <UserDetail
              label={selectedRequest?.dsp?.name || 'N/A'}
              position='right'
              labelSx='whitespace-nowrap overflow-hidden text-ellipsis w-fit flex-1 text-[0.9rem] text-slate-400'
              AvatarSx={'w-[2rem] h-[2rem] text-[1rem]'}
              bgColor={'#A271D3'}
            />
          )}
          {selectedRequest?.request_type === 'repair' && (
            <div className='w-fit bg-primary-light text-primary px-2 rounded-full'>Repair</div>
          )}
        </div>
        <div className='flex gap-2'>
          <RequestPart
            item={{ ...updatedSelectedRequest, refetch: updatedSelectedRequest?.refetch }}
          />
          <PriorityFeild
            item={{ ...updatedSelectedRequest, refetch: requestInfo.refetch }}
            width={'7rem'}
          />
        </div>
      </div>
      {rejecting && !selectedRequest ? (
        <>
          <div className='bg-primary-light p-1 rounded-lg'>
            <RejectBox item={updatedItem} />
          </div>
        </>
      ) : (
        selectedRequest && (
          <div className='flex flex-col gap-4 h-full'>
            <InfoUp item={selectedRequest} />
            <InfoDown item={selectedRequest} />
            <div className='mt-[-1rem]'>
              <MilesBox item={selectedRequest} />
            </div>
            <SchduledInfo item={updatedSelectedRequest} setShowGetAction={setShowGetAction} />
            {updatedSelectedRequest?.checklist_questions?.question_answers?.length > 0 && (
              <div className='w-full flex justify-center items-center '>
                <VehicleQuestionnaire item={updatedSelectedRequest} />
              </div>
            )}
            <ServiceExpandButton item={requestInfo} />
            {!showgetAction && <GetVendorActions item={selectedRequest} />}
            <StatusTrackerPopButton item={selectedRequest} />
          </div>
        )
      )}

      {!selectedRequest && (
        <div className='flex flex-col gap-2'>
          {!rejecting && <ScheduledInfoWhole item={updatedItem} setRejecting={setRejecting} />}
          {showButtons && (
            <>
              <div className='py-2'>
                <AcceptRejectBtn item={updatedItem} isModel={true} />
              </div>
            </>
          )}
          {user.role === 'fleetmax_partner' &&
            requestInfo?.status_id === '-1' &&
            requestInfo?.sub_stage === '2' && (
              <Button
                variant='outlined'
                className='text-[1.05rem] flex gap-3 font-bold'
                onClick={() => {
                  handleAssignVednor(requestInfo);
                }}>
                <i className='pi pi-plus text-[1rem] font-bold'></i> Assign
              </Button>
            )}
          {type === 'accepted' && !rejecting && (
            <Button
              variant='contained'
              color={'primary'}
              className='px-4 font-bold'
              onClick={() => {
                if (user.role === 'fleetmax_partner') {
                  setTechnicianModel({
                    open: true,
                    data: {
                      ...requestInfo,
                      suggested_technician: null,
                      previous_technician_account_id: requestInfo?.technician?.account_id || '',
                      previous_vendor_account_id: requestInfo?.vendor?.account_id || '',
                    },
                  });
                } else {
                  setBulkAssignTechnicianModal({
                    open: true,
                    data: requestInfo,
                  });
                }
              }}>
              Assign Technician
            </Button>
          )}
          {assignBulkTechnicianModal.open && (
            <BulkTechnician
              handleClose={() => setBulkAssignTechnicianModal({ open: false, data: {} })}
              item={assignBulkTechnicianModal.data}
              fetchRequestData={updatedItem?.fetchRequestData}
            />
          )}
          {technicianModel?.open && (
            <AssignTechModel
              data={{ ...technicianModel?.data }}
              handleClose={() => {
                setTechnicianModel({ open: false, data: {} });
              }}
              refetch={updatedItem?.fetchRequestData}
            />
          )}
        </div>
      )}
    </div>
  );
};

const ScheduledInfoWhole = ({ item, setRejecting }: { item: any; setRejecting: any }) => {
  const { user } = useSelector((state: RootState) => state.userState);
  const [vendorModal, setVendorModal] = useState({ open: false, data: {} });
  const isPartner = user?.role === 'fleetmax_partner';
  const condition =
    (user?.role === 'fleetmax_vendor' &&
      item?.bulk_id &&
      +item?.status_id === 4 &&
      +item?.sub_stage === 2) ||
    (user?.role === 'fleetmax_partner' &&
      item?.bulk_id &&
      +item?.status_id === -1 &&
      +item?.sub_stage === 2);

  return (
    <>
      <div className='w-full'>
        <div>
          <div
            className={`w-full p-2 border-[0.1rem] border-[#B6B6B6] mt-3 ${
              condition ? 'rounded-t-[0.5rem]' : 'rounded-xl'
            }  pl-3`}>
            <div
              className='row-container text-primary flex flex-col gap-1'
              style={{ marginBottom: '0.3rem', borderBottom: '1px solid #B6B6B6' }}>
              <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
                Service Location
              </div>
              <div
                className='date-time text-[#515151] max-h-[3rem] overflow-auto'
                style={{ paddingBottom: '10px' }}>
                {ServiceLocation(item)}
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='row-container flex flex-col gap-1'>
                <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
                  CSR
                </div>
                <div className='location-name'>{item?.csr?.name}</div>
              </div>
              {user?.role === 'fleetmax_partner' && (
                <div className='row-container flex flex-col gap-1'>
                  {+item?.status_id === 4 && user?.role === 'fleetmax_partner' && (
                    <AssignUnAssignPopUp item={{ ...item, setVendorModal }} type='Vendor' />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {condition && (
          <div
            className='w-full text-[#CC5555] flex bg-[#F8E8E8] items-center p-3 rounded-b-xl cursor-pointer'
            onClick={() => {
              setRejecting(true);
            }}>
            <i className='pi pi-plus-circle' style={{ transform: 'rotate(45deg)' }}></i>{' '}
            <p className='ml-2 text-[1.05rem]'>Decline Request</p>
          </div>
        )}

        {vendorModal.open && (
          <AssignVendorModal
            data={vendorModal.data}
            handleClose={() => {
              setVendorModal({ open: false, data: {} });
            }}
            refetch={item?.fetchRequestData}
          />
        )}
      </div>
    </>
  );
};

export default Part2;
