import { useEffect, useState } from 'react';
import CrossButtons from '../../../../../assets/CrossButton';
import TickButton from '../../../../../assets/TickButton';
import EditButton from '../../../../../assets/EditButton';
import { toast } from '../../../../../components/Toast/CustomToast';
import Tootltip from '../../../../../components/BarData/Tootltip';
import Calender from '../../../../../assets/calender';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { serviceSchema } from '../../../../common/Schemas';
import { CreateInvoiceButtons } from './CreateInvoiceButtons';
import { ShowTotalTax } from './ShowTotalTax';
import {
  AddNewService,
  DecimalInput,
  invoiceHeadings,
  renderPriceWarning,
  showAmount,
} from './Comps';
import { PickerWithButtonField } from './InvoiceDatePicker';
import { Tooltip } from '@mui/material';

interface Service {
  name: string;
  price: string;
  code?: string;
  description?: string;
  qty?: string;
  amount?: string;
  tax?: boolean;
}

const EditIndexNew = ({
  item,
  services,
  setServices,
  predefinedServices,
  showVendor,
  updateVendorInvoice,
  props,
}: {
  item: any;
  services: any;
  setServices: any;
  predefinedServices: any;
  showVendor: any;
  updateVendorInvoice: any;
  props: any;
}) => {
  const {
    confirmNumber,
    note,
    setNote,
    handelNextButtonvendor,
    invoiceDetails,
    invoices,
    showEdit,
    setShowEdit,
  } = props;
  const { control, setValue, reset, getValues } = useForm<any>({
    resolver: yupResolver(serviceSchema),
    defaultValues: { services },
  });
  const isMoveToComplete = window.location.href.includes('moveToComplete');
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showServiceName, setShowServiceName] = useState<boolean>(false);
  const [editingServiceIndex, setEditingServiceIndex] = useState<number | null>(null);
  const [newService, setNewService] = useState<Service>({ name: '', price: '' });
  const [_, setTotal] = useState<number>(0);
  const [serviceValidation] = useState({
    invalidDescription: false,
  });
  const handleNewServiceChange = (field: keyof Service, value: string) => {
    setNewService((prevService) => ({ ...prevService, [field]: value }));
  };
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const addNewService = () => {
    const isServiceExists = services?.some(
      (service: any) =>
        (service?.name?.trim() || service?.service?.trim()) === newService?.name.trim()
    );
    if (isServiceExists) {
      toast.error('Name Already Exists');
      return;
    }
    if (newService?.name?.trim().length === 0) {
      toast.error('Enter Name First');
      return;
    }
    if (newService.name.trim() !== '') {
      const updatedFormData = {
        ...formData,
        services: [
          ...formData.services,
          {
            ...newService,
            name: newService?.name?.trim(),
            custom: true,
            date: '',
            rate: 0,
            qty: 1,
          },
        ],
      };
      // Update the formData state
      setValue('services', updatedFormData.services);

      setServices((prevServices: any) => [
        ...prevServices,
        { ...newService, name: newService?.name?.trim(), custom: true },
      ]);
      setNewService({ name: '', price: '' });
      setShowServiceName(false);
    }
  };
  const CrossButton = () => {
    setShowServiceName(!showServiceName);
  };

  const deleteService = (index: number) => {
    const updatedServices = [...services];
    const updatedFormDataServices = [...getValues().services];
    updatedServices.splice(index, 1);
    updatedFormDataServices.splice(index, 1);
    setServices(updatedServices);
    setValue('services', updatedFormDataServices);
  };

  const handleEditButtonClick = (index: number) => {
    setEditingServiceIndex(index);
    const serviceToEdit = services[index];
    setNewService({ ...serviceToEdit });
    setShowServiceName(true);
  };

  useEffect(() => {
    const totalPrice = services?.reduce((acc: number, service: { rate: string }) => {
      const rate = parseFloat(service?.rate);
      return isNaN(rate) ? acc : acc + rate;
    }, 0);
    setTotal(totalPrice);
  }, [services]);
  const taxRate = Number(showEdit?.defaultValue) / 100;
  const estimatedTotal = services
    ?.reduce((acc: number, service: any) => {
      const totalPriceWithoutTax = Number(service?.rate) * (service?.qty || 0);
      const taxAmount = service?.tax ? totalPriceWithoutTax * taxRate : 0;
      const totalPriceWithTax = totalPriceWithoutTax + taxAmount;
      return acc + totalPriceWithTax;
    }, 0)
    .toFixed(2);
  const disabledCondition = () => {
    if (item?.disputeUpdate) {
      return false;
    }
    if (!showVendor) {
      return false;
    }
    const totalMismatch = Number(estimatedTotal) !== Number(confirmNumber);
    // : Number(estimatedTotal) !== Number(item?.final_payment);

    return totalMismatch;
  };
  const formData = getValues();
  const handleFormSubmit = () => {
    const formData = getValues();
    const updatedServices = formData?.services?.map((service: any) => ({
      ...service,
      qty: service?.qty || 0,
    }));
    setServices(updatedServices);
    setShowServiceName(false);
  };

  const onChangeDescription = (index: number, value: string) => {
    const regex = /^.{0,49}$/;
    if (regex.test(value)) {
      setValue(`services[${index}].description`, value);
      handleFormSubmit();
    } else {
      toast.error('Description should have only 50 charecters');
    }
  };
  const onChange = (index: number, value: any) => {
    setValue(`services[${index}].date`, value?.date);
    setServices((prevServices: any) => {
      const updatedServices = [...prevServices];
      updatedServices[index].date = value?.date;
      return updatedServices;
    });
  };

  useEffect(() => {
    if (services) {
      reset({
        services: services.map((d: any) => ({
          ...d,
          description: d?.description || '',
          code: d?.code || '',
          tax: d?.tax || false,
        })),
      });
    }
  }, [services]);
  return (
    <>
      <div className='w-full h-full flex flex-col gap-2'>
        <p className=' text-[#777676]'>Services Opted</p>
        <div className='rows border-2 rounded-[0.5rem] mr-2'>
          {invoiceHeadings()}
          {services?.map(
            (
              service: {
                name: string;
                service?: string;
                vendor_price?: string;
                price: string | readonly string[] | undefined;
                checklist_id?: any;
                custom?: boolean;
                code?: any;
                date?: any;
                amount?: any;
                tax?: boolean;
                description?: any;
                qty?: any;
                checkbox?: boolean;
                disputed_rate?: any;
                disputed_quantity?: any;
                rate?: any;
              },
              index: number
            ) => (
              <div key={index} className='w-full p-3 border-b-[0.2rem] flex justify-between'>
                <div className='w-full flex justify-between'>
                  {editingServiceIndex !== index && (
                    <>
                      <div className='w-[10%] flex items-center '>
                        <div className='flex w-full items-center gap-2'>
                          {showCreate ? (
                            <Calender color='#D9D9D9' />
                          ) : (
                            <>
                              <Controller
                                name={`services[${index}].date`}
                                control={control}
                                defaultValue={formData?.services?.[index]?.date || service?.date}
                                render={({ field }: any) => {
                                  const dateValue =
                                    formData?.services?.[index]?.date || service?.date;
                                  if (!dateValue) {
                                    setNextDisabled(true);
                                  }
                                  return (
                                    <>
                                      <PickerWithButtonField
                                        index={index}
                                        value={moment
                                          ?.utc(formData?.services?.[index]?.date, 'MM/DD/YYYY')
                                          .toISOString()}
                                        onChange={(value: any) => {
                                          onChange(index, value);
                                        }}
                                      />
                                    </>
                                  );
                                }}
                              />
                            </>
                          )}

                          <div className='font-bold text-black text-[0.8rem] whitespace-nowrap h-fit'>
                            {formData?.services?.[index]?.date
                              ? moment
                                  ?.utc(String(formData?.services?.[index]?.date))
                                  ?.format('DD-MM-YYYY')
                              : 'DD/MM/YYY'}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className={`flex ${
                      editingServiceIndex === index ? 'w-full' : 'w-[15%]'
                    }  items-center  `}>
                    <div className=' flex justify-between gap-2'>
                      <div className='mt-[0.3rem]'>
                        {editingServiceIndex === index ? (
                          <input
                            className='w-[20rem] border bg-gray-100 px-2 py-1 rounded-[0.2rem] focus:outline-none focus:border-primary border-transparent'
                            value={newService.name}
                            onChange={(e) => handleNewServiceChange('name', e.target.value)}
                          />
                        ) : (
                          <>
                            <Controller
                              name={`services[${index}].name`}
                              control={control}
                              defaultValue={service.name || service?.service}
                              render={({ field }: any) => (
                                <Tootltip
                                  title={service.name || service?.service}
                                  placement={'bottom'}>
                                  <p
                                    className='max-w-[6rem] text-ellipsis overflow-hidden font-bold text-left pl-7 capitalize'
                                    style={{ whiteSpace: 'nowrap' }}>
                                    {service.name || service?.service}
                                  </p>
                                </Tootltip>
                              )}
                            />
                          </>
                        )}
                      </div>

                      {!predefinedServices[index] || !service?.checklist_id ? (
                        editingServiceIndex === index ? (
                          <div className='flex justify-between pt-1'>
                            <div className='flex gap-7'>
                              <button
                                className='text-[#4378C8] border-transparent ml-3 rounded-full'
                                onClick={() => {
                                  const updatedServices = [...services];
                                  const trimmedNewServiceName = newService.name.trim();
                                  const isServiceExists = services?.some(
                                    (service: any, i: number) => {
                                      return (
                                        i !== index &&
                                        (service?.name || service?.service) ===
                                          trimmedNewServiceName
                                      );
                                    }
                                  );

                                  if (isServiceExists) {
                                    toast.error('Name Already Exists');
                                    return;
                                  }
                                  if (trimmedNewServiceName.length === 0) {
                                    toast.error('Enter Name First');
                                    return;
                                  }
                                  const updatedFormDataServices = [...formData.services];
                                  updatedFormDataServices[index].name = trimmedNewServiceName;
                                  setValue(`services[${index}].name`, trimmedNewServiceName);
                                  updatedServices[index] = {
                                    ...newService,
                                    name: newService.name?.trim(),
                                  };
                                  setServices(updatedServices);
                                  setNewService({ name: '', price: '' });
                                  setEditingServiceIndex(null);
                                  setShowServiceName(false);
                                }}>
                                <TickButton />
                              </button>
                              <button
                                className='text-[#4378C8] border-transparent rounded-full'
                                onClick={() => {
                                  setShowServiceName(!showServiceName);
                                  setEditingServiceIndex(null);
                                  setNewService({ name: '', price: '' });
                                }}>
                                <CrossButtons />
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            {!updateVendorInvoice && service?.custom && (
                              <div className='flex justify-center gap-3 items-center'>
                                {!showCreate && (
                                  <div
                                    onClick={() => handleEditButtonClick(index)}
                                    className='cursor-pointer'>
                                    <EditButton />
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {editingServiceIndex !== index && (
                    <>
                      <div className='w-[25%] flex items-center justify-center '>
                        <div className='relative w-full'>
                          <Controller
                            name={`services[${index}].description`}
                            control={control}
                            defaultValue={service?.description}
                            render={({ field }: any) => {
                              return (
                                <input
                                  {...field}
                                  disabled={showCreate}
                                  className='w-full h-[2.5rem] rounded-[0.5rem] pl-3 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                                  placeholder='Description'
                                  onChange={(e) => onChangeDescription(index, e.target.value)}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className='w-[10%] flex items-center justify-center '>
                        <div className='relative w-full'>
                          <Controller
                            name={`services[${index}].code`}
                            control={control}
                            defaultValue={service?.code}
                            render={({ field }: any) => (
                              <input
                                {...field}
                                onChange={(e: any) => {
                                  field.onChange(e);
                                  handleFormSubmit();
                                }}
                                disabled={showCreate}
                                className='w-full h-[2.5rem] rounded-[0.5rem] pl-3 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                                placeholder='Product Code'
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className='w-[10%] flex items-center justify-center '>
                        <div className='flex justify-center items-center gap-2 w-fit'>
                          {renderPriceWarning(service, updateVendorInvoice)}
                          <div className='flex justify-between rounded-[0.5rem] border-2 border-[#DDDDDD]'>
                            <div className='w-7 h-[2.05rem] font-bold border-r-2 flex justify-center pt-1 text-[#858585] '>
                              $
                            </div>
                            <Controller
                              name={`services[${index}].rate`}
                              control={control}
                              defaultValue={service.rate}
                              render={({ field }) => (
                                <DecimalInput
                                  value={field.value}
                                  onChange={(e: any) => {
                                    field.onChange(e);
                                    handleFormSubmit();
                                  }}
                                  disabled={showCreate}
                                  placeholder='Rate'
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='w-[5%] flex items-center justify-center '>
                        {service?.disputed_quantity && (
                          <div className='flex justify-center items-center '>
                            <Tooltip
                              title={`The disputed quantity is $ ${
                                service?.disputed_quantity || ''
                              }`}
                              placement='bottom'>
                              <i className='pi pi-exclamation-circle mr-1 text-[#C55]'></i>
                            </Tooltip>
                          </div>
                        )}

                        <div className='relative w-full'>
                          <Controller
                            name={`services[${index}].qty`}
                            control={control}
                            defaultValue={service.qty || 0}
                            render={({ field }: any) => (
                              <input
                                {...field}
                                type='number'
                                disabled={showCreate}
                                onChange={(e: any) => {
                                  if (/^\d*$/.test(e.target.value)) {
                                    field.onChange(e.target.value);
                                    handleFormSubmit();
                                  }
                                }}
                                className='w-full h-[2.5rem] rounded-[0.5rem] pl-3 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                                placeholder='Qty'
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className='w-[10%] flex items-center justify-center '>
                        <Tootltip
                          title={showAmount(
                            service,
                            invoiceDetails,
                            invoices,
                            showEdit?.defaultValue
                          )}
                          placement={'bottom'}>
                          <p className='max-w-[6rem] text-ellipsis overflow-hidden'>
                            <span className='font-bold text-gray-400 mr-1'>$</span>
                            {showAmount(
                              service,
                              invoiceDetails,
                              invoices,
                              showEdit?.defaultValue
                            ) || 0}
                          </p>
                        </Tootltip>
                      </div>
                      <div className='w-[3%] flex items-center justify-center '>
                        <Controller
                          name={`services[${index}].tax`}
                          control={control}
                          defaultValue={formData?.services?.[index]?.tax}
                          render={({ field }: any) => (
                            <input
                              {...field}
                              checked={formData?.services?.[index]?.tax}
                              disabled={showCreate}
                              onChange={(e: any) => {
                                field.onChange(e);
                                handleFormSubmit();
                              }}
                              type='checkbox'
                              className='cursor-pointer'
                              placeholder='checkbox'
                            />
                          )}
                        />
                      </div>
                      <div className='w-[3%] flex items-center justify-center '>
                        <i
                          className={`pi pi-trash ${
                            showCreate ? 'text-gray-300' : 'text-gray-400'
                          }  cursor-pointer`}
                          onClick={() => {
                            if (!showCreate) {
                              deleteService(index);
                            }
                          }}></i>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
          )}

          {!editingServiceIndex && showServiceName && (
            <AddNewService
              onChange={(e: any) => handleNewServiceChange('name', e.target.value)}
              props={{ newService, addNewService, CrossButton }}
            />
          )}
          <div className='p-3 border-b-[0.2rem] '>
            <button
              className={`${
                !showCreate ? 'text-[#4378C8]' : 'text-gray-400'
              } border-transparent font-bold`}
              onClick={() => {
                if (!showCreate) {
                  setShowServiceName(!showServiceName);
                }
              }}>
              +Add New Services
            </button>
          </div>
        </div>
      </div>
      <div className={`w-full h-[40%] flex justify-between mt-8 ${isMoveToComplete && 'pt-3'}`}>
        <textarea
          style={{ resize: 'none' }}
          className={`border px-4 py-2 rounded-[0.5rem] focus:border-primary  focus:outline-none w-[30%] ${
            isMoveToComplete ? ' h-[8rem]' : ' h-[11rem]'
          } mt-5 border-[#E3E3E3]`}
          placeholder='Add Notes'
          value={note}
          disabled={showCreate}
          onChange={(e: any) => {
            if (note?.length > 99) {
              toast.error('Notes should be less than 100 charecters ');
            }
            setNote(e?.target?.value);
          }}
        />
        <div className='w-[30%] flex flex-col mt-5 mb-3'>
          {showCreate && (
            <ShowTotalTax props={{ services, showEdit, setShowEdit, invoiceDetails, invoices }} />
          )}
          <div className='w-full h-fit'>
            <CreateInvoiceButtons
              props={{
                disabledCondition,
                handelNextButtonvendor,
                handleFormSubmit,
                updateVendorInvoice,
                showCreate,
                setShowCreate,
                showEdit,
                formData,
                services,
                note,
                serviceValidation,
                showVendor,
                item,
                estimatedTotal,
                confirmNumber,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default EditIndexNew;
