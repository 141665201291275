import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };
  const { customizeView }: any = useSelector((state: RootState) => state.userState);
  return (
    <nav
      className={`flex items-center flex-column flex-wrap md:flex-row justify-between ${
        customizeView ? 'pt-[0.3rem]' : 'pt-3'
      }`}
      aria-label='Table navigation'>
      <span className='text-sm font-normal text-gray-500 mb-4 md:mb-0 block w-full md:inline md:w-auto'>
        Showing <span className='font-semibold text-gray-900 '>{currentPage}</span> page out of{' '}
        <span className='font-semibold text-gray-900 '>
          {''}
          {totalPages}
        </span>
      </span>
      <ul className='inline-flex -space-x-px rtl:space-x-reverse text-sm h-8'>
        <li>
          <button
            onClick={handlePrev}
            className='flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 '
            disabled={currentPage === 1}>
            Previous
          </button>
        </li>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <li key={page}>
            <button
              onClick={() => onPageChange(page)}
              className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 hover:bg-gray-100 hover:text-gray-700  ${
                currentPage === page
                  ? 'text-blue-600 border border-gray-300 bg-blue-300 hover:bg-blue-100 hover:text-blue-700  '
                  : ''
              }`}>
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
            onClick={handleNext}
            className='flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 '
            disabled={currentPage === totalPages}>
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
