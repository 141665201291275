import { Button, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditRequestIcon from '../../../../assets/EditRequestIcon';
import UserDetail from '../../../../components/BarData/UserDetail';
import CustomConfirm from '../../../../components/PopOver/CustomDelete';
import { RootState } from '../../../../state/reducers';
import ServiceExpandButton from '../../../common/ServiceListExpand';
import InfoUp from '../../../vender/PrimaryComponents/requestComponents/vehicle/Info';
import MilesBox from '../../../vender/PrimaryComponents/requestComponents/vehicle/LastPreventive';
import InfoDown from '../../../vender/PrimaryComponents/requestComponents/vehicle/VanType';
import ServiceLocation from '../../primaryComponents/ServiceLocation';
import RemoveVendorNote from '../../primaryComponents/requestComponents/RemoveVendorNote';
import SchduledInfo from '../../primaryComponents/requestComponents/ScheduledInfo';
import Actions from './Actions';
import { getRequestType } from './GetRequestType';
import Menu from '../../../../components/DropDowns/MultipleDropDown';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import SelectMoveToComplete from './components/SelectMoveToComplete';
import DisputeHistoryPopup from '../../../common/DisputeHistoryPopup';
import AssignPartnerModel from '../../components/schedule/components/AssignPartnerModel';
import AssignTechModel from '../../components/schedule/components/AssignTechModel';
import AssignUnAssignPopUp from '../../../vender/PrimaryComponents/AssignUnAssignPopUp';
import useFetchSingleRequest from '../../../hooks/csr/useFetchSingleRequest';
import VehicleQuestionnaire from '../../inspection/vehicleQuestionnaire/Index';
import useFetchVehicleChecklist from '../../../hooks/useFetchVehicleChecklist';
import EditRequestModelBulk from '../components/EditModel';
import PriorityFeild from '../../primaryComponents/PriorityFeild';
import VendorAPI from '../../../../api/vendorApi';
import { getDateAndTime } from '../../../vender/PrimaryComponents/requestComponents/AcceptRejectBtn';
import useFetchInvoiceDetails from '../../primaryComponents/Invoice/components/useFetchInvoiceDetails';
import RequestPart from '../../primaryComponents/RequestPart';

const DeclinedInfo = ({ type }: { type: string | undefined }) =>
  type === 'rejected' ? (
    <div className='flex bg-light-red items-center text-custom-red p-2 rounded-lg justify-center gap-3'>
      <i className='pi pi-times-circle text-sm'></i>
      Request Rejected
    </div>
  ) : null;

const Part2 = ({ requestInfo, selectedRequest, props }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bulkRequestDetails }: any = useSelector((state: RootState) => state.userState);
  const { setCompleteData, setBulkVehicles } = bindActionCreators(actionCreators, dispatch);
  const [technicianModel, setTechnicianModel] = useState({ open: false, data: {} });
  const [rejecting, setRejecting] = useState(false);
  const [partnerModel, setPartnerModel] = useState({ open: false, data: {} });
  const { checkIfReadyToMove } = useFetchSingleRequest();
  const isDisputedPage = window.location.href.includes('dispute');
  const { vehicleChecklist, getVehicleCheckList } = useFetchVehicleChecklist();
  const type: string | undefined = getRequestType(selectedRequest || requestInfo);
  const { invoices, fetchInvoices } = useFetchInvoiceDetails();
  useEffect(() => {
    if (selectedRequest) {
      getVehicleCheckList(selectedRequest);
    }
  }, [selectedRequest?.id]);
  const updatedItem = useMemo(
    () => ({
      ...requestInfo,
      setRejecting,
      setPartnerModel,
      setTechnicianModel,
      fetchRequestData: requestInfo.refetch,
      setVendorModal: requestInfo?.setVendorModal,
    }),
    [requestInfo, selectedRequest]
  );
  const updatedSelectedRequest = useMemo(
    () => ({
      ...selectedRequest,
      ...props,
      ...vehicleChecklist,
      ...(invoices?.[0]?.invoices?.filter((invoices: any) => invoices.recipient === 'vendor')
        ?.length > 0 && {
        vendor_invoice_amount: invoices?.[0]?.invoices?.filter(
          (invoices: any) => invoices.recipient === 'vendor'
        )?.[0]?.total_amount,
      }),
      invoices,
      fetchInvoices,
      setRejecting,
      setPartnerModel,
      setTechnicianModel,
      fetchRequestData: requestInfo.refetch,
      setVendorModal: requestInfo?.setVendorModal,
      handleClose: requestInfo?.handleCloseAll,
    }),
    [requestInfo, selectedRequest, vehicleChecklist, invoices]
  );
  // filter the IDs having substage as 2
  const rejectedIdsFilterBulk = requestInfo?.bulkSchedules?.filter((item: any) =>
    ['2'].includes(item.status_id)
  );

  const formatTimeslot = (isSelectedSlot: any) => {
    return isSelectedSlot?.map((appointment: any) => {
      return getDateAndTime(appointment);
    });
  };
  const VehicleCheckList = async (data: any) => {
    const response = await VendorAPI.getVehicleCheckList({
      preventive_request_id: data?.id || data?.preventive_request_id || '',
    });
    if (response.status === 200) {
      return {
        checklist_questions: {
          question_answers: response?.data?.data?.[0]?.checklist_questions?.question_answers,
        },
      };
    }
  };
  const handleSchedule = async (item?: any) => {
    const updateVehicle = async (vehicle: any) => {
      const services = item?.checklists
        ?.filter((checklist: any) => checklist?.name)
        ?.map((checklist: any) => checklist?.name);

      const vehicleChecklist = await VehicleCheckList(vehicle);

      return {
        ...vehicleChecklist,
        ...vehicle,
        hasAdded: true,
        isSingleSchedule: true,
        services: services,
        customServices: services,
        timeSlots: formatTimeslot(vehicle?.appointments) || [],
        scheduleFromDSP: true,
        scheduleTo: 'Schedule to Vendor',
      };
    };

    const schedule = item?.bulkSchedules ? item?.bulkSchedules : [item];
    const updatedVehicles = await Promise.all(schedule.map(updateVehicle));

    setBulkVehicles({
      bulkVehicles: updatedVehicles,
    });

    navigate('/scheduleRequest');
  };

  const menuHeads = (item: any) => {
    if ([9, 11].includes(+item?.status_id)) {
      return [
        {
          head: 'Move to Complete',
          type:
            bulkRequestDetails?.bulkSchedules?.filter((d: any) => [9, 11].includes(+d.status_id))
              ?.length > 1
              ? 'Multiple'
              : 'Single',
          event: async () => {
            const filteredData = bulkRequestDetails?.bulkSchedules?.filter((d: any) =>
              [9, 11].includes(+d.status_id)
            );
            const is_ready = await checkIfReadyToMove(filteredData.map((d: any) => d.id));
            if (is_ready) {
              navigate('/moveToComplete');
              setCompleteData({
                selected: filteredData[0],
                bulkSchedules: filteredData,
              });
            }
          },
          component: <SelectMoveToComplete />,
        },
      ];
    }
    return null;
  };
  useEffect(() => {
    if (selectedRequest) {
      fetchInvoices(selectedRequest);
    }
  }, [selectedRequest?.id]);
  return (
    <div className='flex flex-col justify-start flex-1 '>
      <div className='flex flex-col justify-start flex-1 overflow-auto mt-8 pr-2 gap-2'>
        <div className='flex justify-between'>
          <div className='w-fit flex gap-2 items-center'>
            {updatedSelectedRequest?.dsp?.name && (
              <UserDetail
                label={updatedSelectedRequest?.dsp?.name || 'N/A'}
                position='right'
                labelSx='whitespace-nowrap overflow-hidden text-ellipsis w-fit flex-1 text-[0.9rem] text-slate-400'
                AvatarSx={'w-[2rem] h-[2rem] text-[1rem]'}
                bgColor={'#A271D3'}
              />
            )}
            {selectedRequest?.request_type === 'repair' && (
              <div className='w-fit bg-primary-light text-primary px-2 rounded-full'>Repair</div>
            )}
          </div>
          <div className='flex justify-between gap-2 items-center '>
            <PriorityFeild
              item={{ ...updatedSelectedRequest, refetch: requestInfo.refetch, isSmall: true }}
              width={'7rem'}
            />
            <RequestPart
              item={{
                ...updatedSelectedRequest,
                refetch: updatedSelectedRequest?.refetch || requestInfo?.refetch,
              }}
            />
            {menuHeads(selectedRequest) && <Menu options={menuHeads(selectedRequest)} />}
          </div>
        </div>
        {rejecting ? (
          <div className='bg-primary-light p-1 rounded-lg'>
            <RemoveVendorNote item={updatedItem} />
          </div>
        ) : (
          selectedRequest && (
            <div className='flex flex-col gap-4  p-1'>
              <InfoUp item={selectedRequest} />
              <InfoDown item={selectedRequest} />
              <div className='mt-[-1rem]'>
                <MilesBox item={selectedRequest} />
              </div>
              <SchduledInfo
                item={{ ...updatedItem, ...selectedRequest }}
                selectedRequest={updatedSelectedRequest}
                refetch={requestInfo?.refetch}
              />
              {updatedSelectedRequest?.checklist_questions?.question_answers?.length > 0 && (
                <div className='w-full flex justify-center items-center '>
                  <VehicleQuestionnaire item={updatedSelectedRequest} />
                </div>
              )}
              <ServiceExpandButton item={requestInfo} />
              {isDisputedPage && (
                <DisputeHistoryPopup item={updatedSelectedRequest} refetch={requestInfo?.refetch} />
              )}
              <Actions item={updatedSelectedRequest} />
            </div>
          )
        )}

        {!selectedRequest && (
          <div className='flex flex-col gap-2'>
            <ScheduledInfoWhole item={updatedItem} />
            {+requestInfo.status_id === 1 && (
              <CustomConfirm
                comp={
                  <Button variant='contained' color={'primary'} className='px-4 font-bold w-full'>
                    Assign
                  </Button>
                }
                title={`Assign`}
                info={`This request is not approved by the DSP yet, do you wish to continue?`}
                acceptText='Yes'
                accept={() => {
                  handleSchedule(requestInfo);
                }}
                bottomBarStyle='px-20'
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              />
            )}
            {[2, 3].includes(+requestInfo.status_id) && (
              <Tooltip
                title={
                  rejectedIdsFilterBulk &&
                  rejectedIdsFilterBulk.length !== requestInfo?.bulkSchedules?.length
                    ? 'Please remove the vehicle rejected by DSP'
                    : ''
                }>
                <div className='w-full '>
                  <Button
                    variant='contained'
                    color={'primary'}
                    className='px-4 font-bold w-full'
                    disabled={
                      rejectedIdsFilterBulk &&
                      rejectedIdsFilterBulk.length !== requestInfo?.bulkSchedules?.length
                    }
                    onClick={() => {
                      handleSchedule(requestInfo);
                    }}>
                    Assign
                  </Button>
                </div>
              </Tooltip>
            )}

            <DeclinedInfo type={type} />
          </div>
        )}
        {partnerModel?.open && (
          <AssignPartnerModel
            data={partnerModel?.data}
            handleClose={() => setPartnerModel({ open: false, data: {} })}
            refetch={requestInfo?.refetch}
          />
        )}
        {technicianModel?.open && (
          <AssignTechModel
            data={technicianModel?.data}
            handleClose={() => setTechnicianModel({ open: false, data: {} })}
            refetch={requestInfo?.refetch}
          />
        )}
      </div>
    </div>
  );
};

const ScheduledInfoWhole = ({ item }: { item: any }) => {
  const { user } = useSelector((state: RootState) => state.userState);
  const [showEditModel, setShoweditModel] = useState(false);
  return (
    <div className='container' style={{ justifyContent: 'center' }}>
      <div
        className={`border-[0.1rem] ${
          [-1, 4].includes(+item?.status_id) && +item.sub_stage !== 3
            ? 'rounded-t-[0.5rem]'
            : 'rounded-xl'
        } border-[#B6B6B6] p-2`}>
        <div
          className='row-container text-primary flex flex-col gap-1'
          style={{ marginBottom: '0.3rem', borderBottom: '1px solid #B6B6B6' }}>
          <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
            Service Location
          </div>
          <div
            className='date-time text-[#515151] max-h-[3rem] overflow-auto'
            style={{ paddingBottom: '10px' }}>
            {ServiceLocation(item)}
          </div>
        </div>
        <div className='grid grid-cols-2 content-center'>
          <div className='row-container flex flex-col gap-1'>
            <div className='label text-primary text-sm' style={{ fontWeight: 'normal' }}>
              CSR
            </div>
            <div className='location-name'>{item?.csr?.name || user?.name}</div>
          </div>
          {(item?.id || item?.preventive_request_id) &&
          +item?.status_id === 5 &&
          !item?.sub_stage ? (
            <AssignUnAssignPopUp item={{ ...item }} type='Technician' />
          ) : (
            <>
              {+item?.status_id >= 4 && <AssignUnAssignPopUp item={{ ...item }} type='Vendor' />}
              {+item?.status_id === -1 && <AssignUnAssignPopUp item={{ ...item }} type='Partner' />}
            </>
          )}
        </div>
      </div>
      {[-1, 4].includes(+item?.status_id) && +item.sub_stage !== 3 && (
        <div
          className='w-full flex bg-primary-light items-center p-2 px-3 rounded-b-xl cursor-pointer'
          onClick={() => {
            setShoweditModel(true);
          }}>
          <EditRequestIcon /> <p className='ml-2 text-[1.1rem] text-primary'>Edit Request</p>
        </div>
      )}
      {showEditModel && (
        <EditRequestModelBulk
          handleClose={() => {
            setShoweditModel(false);
          }}
          item={item}
        />
      )}
    </div>
  );
};

export default Part2;
